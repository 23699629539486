import BubbleFromUser from "../components/chatbot/BubbleFromUser";
import BubbleToUserInChat from "../components/chatbot/BuddleToUserInChat";
import image_ok from '../assets/resources/custom/icons/ok.png'
import image_ko from '../assets/resources/custom/icons/ko.png'
import GetHtmlStrLocalized from "../datas/GetHtmlStrLocalized";
import { ChangeEvent } from "react";
import { Link } from "react-router-dom";

export interface ArticleTestProps {
  articleId: string;
  input: string;
  output: string;
  outputReferences: string;
  isOk: string;
  expected: string;
  expectedReferences: string;
}

export interface ArticleTestsProps {
  tests: ArticleTestProps[];
}

export class ArticleTests {
  value: ArticleTestsProps;
  private setTests: React.Dispatch<React.SetStateAction<ArticleTestProps[]>>;
  private setTestsStr: React.Dispatch<React.SetStateAction<string>>;

  constructor(
    value: ArticleTestsProps,
    setTests: React.Dispatch<React.SetStateAction<ArticleTestProps[]>>,
    setTestsStr: React.Dispatch<React.SetStateAction<string>>
  ) {
    this.value = value;
    this.setTests = setTests;
    this.setTestsStr = setTestsStr;
  }

  set(value: ArticleTestsProps) {
    this.setTestsFn([...value.tests])
  }

  setTestsFn(tests: ArticleTestProps[]) {
    this.setTests(tests);
    this.setTestsStr(JSON.stringify(tests))
  }
}


export interface DisplayArticleTestProps {
  test: ArticleTestProps;
  language: string;
  isChecked: boolean;
  setIsChecked: (value: boolean) => any;
}

export default function DisplayArticleTest(
  { test, language, isChecked, setIsChecked }: DisplayArticleTestProps
) {
  return (
    <div style={{ paddingTop: '7px', paddingBottom: '7px' }}>
      <BubbleFromUser>{test.input}</BubbleFromUser>
      <table width="100%">
        <tbody>
          <tr>
            <td width="33%">
              <div style={{ float: 'left' }}>
                <GetHtmlStrLocalized language={language} textId="actualResult" />
                <BubbleToUserInChat source={undefined}><span dangerouslySetInnerHTML={{ __html: test.output }}></span></BubbleToUserInChat>
              </div>
            </td>
            <td width="33%">
              <div style={{ float: 'left' }}>
                {
                  (test.output !== test.expected || test.outputReferences !== test.expectedReferences) &&
                  <GetHtmlStrLocalized language={language} textId="differencesWithPreviouslySavedResult" />
                }
                {
                  test.output !== test.expected &&
                  <BubbleToUserInChat source={undefined}><span dangerouslySetInnerHTML={{ __html: test.expected }}></span></BubbleToUserInChat>
                }
              </div>
            </td>
            <td width="33%">
              <br /><br />
              <Link to={"/" + language + "/" + test.articleId} target="_blank" rel="noreferrer" style={{ backgroundColor: 'white' }} >{test.articleId}</Link>
              <br /><br />
              {test.isOk === "true" ?
                <>
                  <img src={image_ok} alt="ok" />
                  <GetHtmlStrLocalized language={language} textId="theExpectedAnswerIsOk" />
                </>
                :
                <>
                  <img src={image_ko} alt="ko" />
                  <GetHtmlStrLocalized language={language} textId="theExpectedAnswerIsNotOk" />
                </>}
              <br /><br />
              <input type="checkbox" checked={isChecked} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setIsChecked(event.target.checked)
              }} onClick={(event) => {
                event.stopPropagation(); // Prevents further propagation of click event to parents
              }} />
              <GetHtmlStrLocalized language={language} textId="select" />

            </td>
          </tr>
        </tbody>
      </table>

      <table width="100%">
        <tbody>
          <tr>
            <td width="33%">
              <div style={{ float: 'left' }}>
                {test.outputReferences}
              </div>
            </td>
            <td width="33%">
              <div style={{ float: 'left' }}>
                {
                  test.outputReferences !== test.expectedReferences &&
                  <>{test.expectedReferences}</>
                }
              </div>
            </td>
            <td width="33%" />
          </tr>
        </tbody>
      </table>

    </div>
  )

}