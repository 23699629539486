import image_html from '../../assets/resources/custom/icons/html.png'
import image_information from '../../assets/resources/custom/icons/information.png'

interface HCFileTypeIconProps {
    fileType: string;
}


export function HCFileTypeIcon({fileType}: HCFileTypeIconProps) {
    return <img src={fileType === "html" ? image_html : image_information} alt="message icon" height="15px" width="15px" style={{ paddingTop: '6px' }} />
}