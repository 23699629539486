import image_wait from '../assets/resources/custom/icons/wait.gif'
import React, { useState } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import PageContent from '../components/util/PageContent'
import { HcContext } from '../types/hccontext'
import Centered from '../components/util/Centered'
import GetHtmlStrLocalized from "../datas/GetHtmlStrLocalized"
import GetStrLocalized from "../datas/GetStrLocalized"
import CategoriesBigButtons from "../components/bigButton/CategoriesBigButtons"
import BubblesFromUser from "../components/chatbot/BubblesFromUser"
import H2TitleId from "../components/title/H2TitleId"
import SubBannerCategory from "../components/banner/SubBannerCategory"
import ChatbotModifier from "../components/modifier/ChatbotModifier"
import MoveDestinationPathSelectorModal from '../components/modal/MoveDestinationPathSelectorModal'
import { moveItem } from '../util/moveItem'
import { getWaitPopupStyle } from "../components/util/GetWaitPopupStyle"
import TypeOfItemModal from '../components/modal/TypeOfItemModal'
import ArticleModifier from '../components/modifier/ArticleModifier'
import FolderModifier from '../components/modifier/FolderModifier'
import QuestionForQuizAdder from '../components/modifier/QuestionForQuizAdder'
import { createErrorModalContent } from '../props/ModalContentProps'


interface ChatbotConfigProps {
	language: string;
	hcContext: HcContext;
};

const ChatbotConfig: React.FC<ChatbotConfigProps> = ({ language, hcContext }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const [lastPath, setLastPath] = useState("")
	const [lastSearch, setLastSearch] = useState("")
	const [request, setRequest] = useState({
		name: "", parentFolders: [], logoImage: "", url: "", useDefaultContent: "",
		userBackgroundBubbleColor: "",
		fallbackEngine: "", bigButtontDatas: [], chatMsgs: [], arrows: [],
		bigButtontDatas_hidden: [], chatMsgs_hidden: []
	})
	const [addMode, setAddMode] = useState(false)
	const [editMode, setEditMode] = useState(false)
	const [chatbotId, setChatbotId] = useState("")
	const [showMoveModal, setShowMoveModal] = useState(false)
	const [processingModifications, setProcessingModifications] = useState(false)
	const [searchMode, setSearchMode] = useState(false)
	const [showTypeOfItemModal, setShowTypeOfItemModal] = useState(false)
	const [typeOfItemToAdd, setTypeOfItemToAdd] = useState("")
	let chatbotName = "";


	if (location.pathname !== lastPath || location.search !== lastSearch) {
		setLastPath(location.pathname);
		setLastSearch(location.search);
		const foldersArray = location.pathname.split('/');
		if (foldersArray.length > 3) {
			chatbotName = foldersArray[3]
			setChatbotId(chatbotName)
		}
		const urlParams = new URLSearchParams(location.search);
		const isAddMode = urlParams.has('add')
		setAddMode(isAddMode)
		setShowTypeOfItemModal(isAddMode)
		const isEditMode = urlParams.has('edit')
		setEditMode(isEditMode)
		if (!isAddMode && !isEditMode) {
			setTypeOfItemToAdd("")
			fetch(hcContext.backendUrl + "/chatbot_config_json?l=" + language + "&id=" + chatbotName)
				.then(res => res.json())
				.then(jsonData => {
					if (jsonData.errorMessage) {
						hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
						return
					}
					setRequest(jsonData)
					hcContext.setDocumentTitle(chatbotName + " - Holy Chatter");
				})
				.catch(rejected => {
					hcContext.setInfo(createErrorModalContent(language, rejected.message))
				});
		}
	}

	return (
		<PageContent>
			{
				(addMode || editMode) &&
				!hcContext.userAsRightsToModifyTheContent &&
				<Navigate to={lastPath} />
			}

			{
				processingModifications &&
				<Centered>
					<div style={getWaitPopupStyle(true, 50)}>
						<img src={image_wait} alt="wait" height="100%" width="100%" />
					</div>
				</Centered>
			}

			{
				editMode ?
					<ChatbotModifier language={language} hcContext={hcContext} parentFolders={request.parentFolders} currentFolderName={request.name} chatbotId={chatbotId} lastPath={lastPath} />
					:
					<>
						{
							typeOfItemToAdd === "article" &&
							<ArticleModifier language={language} hcContext={hcContext} parentFolders={request.parentFolders} currentFolderName={request.name} articleId="" lastPath={lastPath} />
						}

						{
							typeOfItemToAdd === "category" &&
							<FolderModifier language={language} hcContext={hcContext} parentFolders={request.parentFolders} currentFolderName={request.name} folderId="" lastPath={lastPath} />
						}

						{
							typeOfItemToAdd === "chatbot" &&
							<ChatbotModifier language={language} hcContext={hcContext} parentFolders={request.parentFolders} currentFolderName={request.name} chatbotId="" lastPath={lastPath} />
						}

						{
							typeOfItemToAdd === "questionForTheQuiz" &&
							<QuestionForQuizAdder language={language} hcContext={hcContext} parentFolders={request.parentFolders} currentFolderName={request.name} lastPath={lastPath} />
						}

						{
							typeOfItemToAdd === "" &&
							<>

								<SubBannerCategory language={language} hcContext={hcContext} arrows={request.arrows}
									parentFolders={request.parentFolders} nameToPrint={request.name}
									lastPath={lastPath} setShowMoveModal={setShowMoveModal} searchMode={searchMode} />

								{
									request.logoImage !== "" &&
									<Centered>
										<img unselectable="on" src={"/upload/" + request.logoImage} alt={chatbotId} className="hc-not-selectable" height={50} />
									</Centered>
								}
								<br />

								<Centered>
									<button onClick={() => {
										navigate("/" + language + "/" + GetStrLocalized(language, "chatbotFolderName") + "/" + chatbotId)
									}}
										style={{ backgroundColor: request.userBackgroundBubbleColor }}
									>
										{GetStrLocalized(language, "chatWithTheChatbot")} "{request.name}"
									</button>
								</Centered>

								<br />
								{
									request.url !== "" &&
									<><GetHtmlStrLocalized language={language} textId="url" />{GetStrLocalized(language, "twoDots")}<a href={request.url}>{request.url}</a><br /></>
								}
								{
									request.useDefaultContent === "true" &&
									<>{GetStrLocalized(language, "useTheDefaultContent")}<br /></>
								}
								{
									request.fallbackEngine !== "" &&
									<>{GetStrLocalized(language, "fallbackEngine")}{GetStrLocalized(language, "twoDots")}{request.fallbackEngine}<br /></>
								}
								<br />
								<br />

								<CategoriesBigButtons categories={request.bigButtontDatas} printNumber={hcContext.userAsRightsToModifyTheContent} hcContext={hcContext} />
								<BubblesFromUser language={language} titleId="relatedQuestions" chatMsgs={request.chatMsgs} />

								{
									hcContext.userAsRightsToModifyTheContent &&
									(request.bigButtontDatas_hidden.length > 0 || request.chatMsgs_hidden.length > 0) &&
									<>
										<br />
										<H2TitleId language={language} titleId="privateSection" />
										<br />
										<CategoriesBigButtons categories={request.bigButtontDatas_hidden} printNumber={true} hcContext={hcContext} />
										<BubblesFromUser language={language} titleId="relatedQuestions" chatMsgs={request.chatMsgs_hidden} />
									</>
								}
							</>
						}
					</>
			}

			<TypeOfItemModal language={language} show={showTypeOfItemModal} setShow={setShowTypeOfItemModal}
				onTypeOfItem={(toi) => setTypeOfItemToAdd(toi)} hcContext={hcContext} />

			<MoveDestinationPathSelectorModal language={language} show={showMoveModal} setShow={setShowMoveModal}
				backendUrl={hcContext.backendUrl} itemName={request.name}
				onPathChoosen={(path) => moveItem(request.parentFolders, path, request.name, hcContext, language, setProcessingModifications, navigate)}
				hcContext={hcContext}
			/>

		</PageContent>
	)
}

export default ChatbotConfig
