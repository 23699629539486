import React from "react"
import { Link } from "react-router-dom"
import { SourceProps } from "../../props/sourceprops";
import { onErrorImageHandler } from "../util/imagefallbacks";

interface SourceLinkProps {
    source: SourceProps;
}

const SourceLink: React.FC<SourceLinkProps> = ({ source }) => {

    return (
        <React.Fragment>
            {
                source.name !== "" &&
                <React.Fragment>
                    {source.iconPath !== undefined && source.iconPath !== "" && <img src={source.iconPath} alt="icon of the source" className="hc-source-icon"  onError={(e) => onErrorImageHandler(e, source.iconPath)} style={{ borderRadius: 100 }} /> }
                    <span style={{ display:'inline-block', paddingTop: '3px', maxWidth: 650 }}>
                        <Link to={source.path} >{source.name}</Link>
                        {
                            source.url !== "" &&
                            <React.Fragment>&nbsp;&nbsp;&nbsp;(<a href={source.url}>{source.url}</a>)</React.Fragment>
                        }
                    </span>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default SourceLink