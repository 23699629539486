
interface CenteredProps {
    children: React.ReactNode;
}

function Centered({ children }: CenteredProps) {

    return (
        <table style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td className='hc-blank-for-menu'>
                    </td>
                    <td>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {children}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>



    )
}

export default Centered;