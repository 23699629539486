import React from 'react'

const TranslationsEn: { [name: string]: string } = {
    "aboutHolyChatterIntro": "Holy Chatter is a site trying to show the beauty and veracity of the Christian religion and which allows to",
    "aboutHolyChatterFirstPoint": "Have a filtered Christian version of YouTube:",
    "aboutHolyChatterSecondPoint": "Read a passage of the Bible or the Catechism with related videos of explanations, or testimonies:",
    "aboutHolyChatterThirdPoint": "Have answers to your questions about the Christian faith thanks to our chatbot Théophile:",
    "aboutHolyChatterContent": "This site was created in 2015 by young French Catholics wanting to share their received faith.<br />\n" +
        "We hope it will improve your relationship with this God of love and it will give you the desire to meet Christians to continue the discussion!<br />\n" +
        "Holy Chatter",
    "apiHolyChatterContent": "You have a Christian project and you want to use Holy Chatter content?<br />\n" +
        "We can provide an API for that. If you are interested please contact us here: holychatter@gmail.com",
    "forWhoContent": "Whether you are an atheist or an agnostic, you may be wondering about faith? You want to learn more about Christianity? \n" +
        "Who is Jesus? What did he do ? In fact, what is faith in concrete terms? Then Holy Chatter is for you!<br/><br/>\n" +
        "You are a Christian and wish to deepen your knowledge and fully live your faith. Then Holy Chatter is for you!<br/><br/>\n" +
        "Whoever you are, atheist, practicing Christian, disbelief, lost your faith, or practice it every day, we welcome you and affirm that you have your place on Holy Chatter. \n" +
        "Thanks to the chat, videos, daily texts and other resources on various topics, you can find answers to your questions or learn more.<br/><br/>",
    "hurtubiseMsg1": "",
    "hurtubiseMsg2": "",
    "hurtubiseMsg3": "",
    "hurtubiseMsg4": "",
    "hurtubiseMsg5": "",
    "hurtubiseMsg6": "",
    "hurtubiseMsg7": "",
    "hurtubiseMsg8": "",
    "hurtubiseMsg9": "",
    "hurtubiseMsg10": "",
    "hurtubiseMsg11": "",
    "hurtubiseMsg12": "",
    "encyclicLetterPatrisCordeText": "Text: <a href='http://www.vatican.va/content/francesco/en/apost_letters/documents/papa-francesco-lettera-ap_20201208_patris-corde.html'>http://www.vatican.va/content/francesco/en/apost_letters/documents/papa-francesco-lettera-ap_20201208_patris-corde.html</a>",
    "encyclicalFratelliTuttiText": "Text: <a href='http://www.vatican.va/content/francesco/en/encyclicals/documents/papa-francesco_20201003_enciclica-fratelli-tutti.html'>http://www.vatican.va/content/francesco/en/encyclicals/documents/papa-francesco_20201003_enciclica-fratelli-tutti.html</a>",
    "encyclicalLaudatoSiText": "Text: <a href='http://www.vatican.va/content/francesco/en/encyclicals/documents/papa-francesco_20150524_enciclica-laudato-si.html'>http://www.vatican.va/content/francesco/en/encyclicals/documents/papa-francesco_20150524_enciclica-laudato-si.html</a>",
    "readThePopesWritingsLink": "<a href=\"http://www.vatican.va/content/vatican/en.html\">http://www.vatican.va/content/vatican/en.html</a>",
    "modifyMyVote": "Modify<br/>my vote"
};

const TranslationsFr: { [name: string]: string } = {
    "aboutHolyChatterIntro": "Holy Chatter est un site essayant de montrer la beauté et véracité de la religion chrétienne et qui permet de:",
    "aboutHolyChatterFirstPoint": "Avoir une version chrétienne de YouTube filtrée :",
    "aboutHolyChatterSecondPoint": "Lire un passage de la Bible ou du Catéchisme avec des vidéos d'explication, de témoignage ou de louange s'en rapportant :",
    "aboutHolyChatterThirdPoint": "Avoir des réponses sur des questions que vous posez sur la foi chrétienne grâce à notre agent conversationnel Théophile :",
    "aboutHolyChatterContent": "Ce site a été créé en 2015 par des jeunes catholiques français voulant partager la foi qu'ils ont reçu.<br />\n" +
        "On espère que ça améliorera votre relation au Dieu d'amour et vous donnera le désir de rencontrer des chrétiens pour poursuivre la discussion !<br />\n" +
        "Holy Chatter",
    "apiHolyChatterContent": "Vous avez un projet chrétien et vous voulez utiliser du contentu de Holy Chatter ?<br />\n" +
        "Nous pouvons vous fournir une API pour cela. Si cela vous intéresse merci de nous contacter à holychatter@gmail.com",
    "forWhoContent": "Que vous soyez athée ou agnostique, vous vous poser peut-être des questions sur la foi ? \n" +
        "Vous désirez en apprendre plus sur le christianisme. Qui est Jésus ? Qu'a-t-il fait ? En fait, qu'est ce que la foi concrètement ?  \n" +
        "Alors Holy Chatter est fait pour vous !<br/><br/>\n" +
        "Vous êtes chrétien et souhaitez approfondir vos connaissances et vivre pleinement votre foi. Alors Holy Chatter est fait pour vous !<br/><br/>\n" +
        "Qui que vous soyez, athée, chrétien praticant, que vous ne croyez pas, que vous ayez perdu la foi ou que vous la pratiquiez chaque jour, nous vous souhaitons la bienvenue et affirmons que vous avez votre place sur Holy Chatter. \n" +
        "Grâce au chat, aux vidéos, textes du jour et autres ressources sur des thèmes variés, vous pourrez trouver des réponses à vos questions ou en apprendre plus.<br/><br/>",
    "hurtubiseMsg1": "Jésus est là, il nous montre son cœur et il nous dit « Venez à moi » !",
    "hurtubiseMsg2": "« Je ne suis pas un Dieu de tristesse, je ne suis pas un juge. »",
    "hurtubiseMsg3": "Cessez de croire que Jésus est un juge !",
    "hurtubiseMsg4": "Cessez de croire qu'il est la cause de toutes vos souffrances, vos épreuves, vos calamités...",
    "hurtubiseMsg5": "C'est un Dieu d'amour ! Et un Dieu d'amour ça ne cherche pas à punir, ça ne cherche pas à accuser, ça ne cherche pas à condamner...",
    "hurtubiseMsg6": "C'est un SAUVEUR ! Il est venu SAUVER ! Parce qu'il nous AIME !",
    "hurtubiseMsg7": "Il voudrait tant que, dans notre monde actuel d'indifférence, notre monde actuel de si peu de cœur, nous sachions que c'est dans l'amour qu'il nous a sauvé !",
    "hurtubiseMsg8": "Il voudrait que chaque fois que nous voyons sa croix nous l'entendions nous dire :<br />\n" +
        "« Regarde mon enfant combien je t'ai AIMÉ ! Et combien encore aujourd'hui je t'AIME ! Si tu savais comme je t'AIME ! »",
    "hurtubiseMsg9": "Tant que vous n'aurez pas eu cette grâce de comprendre jusqu'où le Seigneur nous aime, il faudra demander à l'Esprit qu'il vienne prier en vous.",
    "hurtubiseMsg10": "Interpellez sans cesse le Père pour qu'il vous accorde cette grâce de comprendre la grandeur, l'immensité de l'amour de Dieu pour chacun d'entre nous.",
    "hurtubiseMsg11": "Malgré nos péchés, malgré peut-être une vie de faiblesses, de misères, malgré tout cela,<br />\n" +
        "il nous dit encore : un regard de notre part, un geste de pardon et il est prêt à nous ouvrir son cœur pour nous établir en lui pour l'éternité bienheureuse !",
    "hurtubiseMsg12": "<br />Merci Seigneur !<br />\n" +
        "Père Gaston Hurtubise<br /><br />\n" +
        "Source: <a href=\"/fr/predication-sur-le-sacre-cur-suivie-d-une-montee-au-ciel\">Après la mort, la vie continue ! (Vous allez être surpris par cette vidéo)</a>",
    "encyclicLetterPatrisCordeText": "Texte : <a href='http://www.vatican.va/content/francesco/fr/apost_letters/documents/papa-francesco-lettera-ap_20201208_patris-corde.html'>http://www.vatican.va/content/francesco/fr/apost_letters/documents/papa-francesco-lettera-ap_20201208_patris-corde.html</a>",
    "encyclicalFratelliTuttiText": "Texte : <a href='http://www.vatican.va/content/francesco/fr/encyclicals/documents/papa-francesco_20201003_enciclica-fratelli-tutti.html'>http://www.vatican.va/content/francesco/fr/encyclicals/documents/papa-francesco_20201003_enciclica-fratelli-tutti.html</a>",
    "encyclicalLaudatoSiText": "Texte: <a href='http://www.vatican.va/content/francesco/fr/encyclicals/documents/papa-francesco_20150524_enciclica-laudato-si.html'>http://www.vatican.va/content/francesco/fr/encyclicals/documents/papa-francesco_20150524_enciclica-laudato-si.html</a>",
    "readThePopesWritingsLink": "<a href=\"http://www.vatican.va/content/vatican/fr.html\">http://www.vatican.va/content/vatican/fr.html</a>",
    "modifyMyVote": "Modifier<br/>mon vote"
};


interface GetInnerHtmlStrLocalizedProps {
    language: string;
    textId: string;
}

const GetInnerHtmlStrLocalized: React.FC<GetInnerHtmlStrLocalizedProps> = ({ language, textId }) => {
    if (language === "fr") {
        return <span dangerouslySetInnerHTML={{ __html: TranslationsFr[textId] }}></span>
    }
    return <span dangerouslySetInnerHTML={{ __html: TranslationsEn[textId] }}></span>
}


export default GetInnerHtmlStrLocalized