import React, { ChangeEvent, useEffect } from 'react'
import { useState } from 'react'
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal'
import { HcInput } from '../input/HcInput';
import { ArticleTestProps } from '../../types/ArticleTests';
import { HcContext } from '../../types/hccontext';
import BubbleToUserInChat from '../chatbot/BuddleToUserInChat';
import { createErrorModalContent } from '../../props/ModalContentProps';


interface TestEditionModalProps {
    language: string;
    addOrEdit: boolean;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    articleId: string;
    inputTest: string;
    hcContext: HcContext;
    onTestValidated: (r: ArticleTestProps) => any;
}

interface SingleTestProps {
    output: string;
    outputReferences: string;
}

function TestEditionModal({ language, addOrEdit, show, setShow, articleId, inputTest, hcContext, onTestValidated }: TestEditionModalProps) {
    const [currentInput, setCurrentInput] = useState<string>("")
    const [validatedInput, setValidatedInput] = useState<string | undefined>(undefined)
    const [singleTest, setSingleTest] = useState<SingleTestProps>({ output: "", outputReferences: "" })
    const [isResultOk, setIsResultOk] = useState<string>("true")

    function updateValidatedInputText(newInput: string) {
        if (newInput !== "") {
            fetch(hcContext.backendUrl + "/single_test_json?l=" + language + "&id=" + articleId + "&i=" + newInput)
                .then(res => res.json())
                .then(jsonData => {
                    if (jsonData.errorMessage) {
                        hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
                    } else {
                        setSingleTest(jsonData)
                        if (newInput === "")
                            setValidatedInput(undefined)
                        else
                            setValidatedInput(newInput)
                    }
                })
                .catch(rejected => {
                    hcContext.setInfo(createErrorModalContent(language, rejected.message))
                });
        } else {
            setSingleTest({ output: "", outputReferences: "" })
        }
    }

    useEffect(() => {
        if (show) {
            setCurrentInput(inputTest)
            updateValidatedInputText(inputTest)
        }
    }, [show]);

    return (
        <Modal
            language={language}
            show={show}
            setShow={setShow}
            isDarkTheme={hcContext.isDarkTheme}
        >
            <ModalHeader>
                <h3><GetHtmlStrLocalized language={language} textId={addOrEdit ? "addATest" : "modifyATest"} /></h3>
            </ModalHeader>
            <ModalBody>
                <HcInput value={currentInput} onChange={(e: ChangeEvent<HTMLInputElement>) => { setCurrentInput(e.target.value); }}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            updateValidatedInputText(currentInput)
                        } else {

                        }
                    }} />
                <br />

                {
                    currentInput !== validatedInput &&
                    currentInput !== "" &&
                    <button onClick={() => {
                        updateValidatedInputText(currentInput)
                    }}>
                        <GetHtmlStrLocalized language={language} textId="seeTheAnswer" />
                    </button>
                }
                <br />
                <br />
                {
                    singleTest.output !== "" &&
                    <BubbleToUserInChat source={undefined}><span dangerouslySetInnerHTML={{ __html: singleTest.output }}></span></BubbleToUserInChat>
                }
            </ModalBody>
            <br />
            <ModalFooter>
                {
                    currentInput === validatedInput &&
                    <>
                        <input type="checkbox" checked={isResultOk === "true"} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setIsResultOk(event.target.checked ? "true" : "false")
                        }
                        } />
                        <GetHtmlStrLocalized language={language} textId="isTheAnswerCorrect" />

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <button onClick={() => {
                            onTestValidated({
                                articleId: articleId, input: validatedInput, output: singleTest.output, outputReferences: singleTest.outputReferences,
                                isOk: isResultOk, expected: singleTest.output, expectedReferences: singleTest.outputReferences
                            })
                            setShow(false)
                        }}>
                            <GetHtmlStrLocalized language={language} textId="validate" />
                        </button>
                    </>

                }
            </ModalFooter>
        </Modal>
    );
}

export default TestEditionModal;