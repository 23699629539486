import React from 'react'


interface YouTubePlayerProps {
	videoId: string;
}

const YouTubePlayer: React.FC<YouTubePlayerProps> = ({ videoId }) => {
	return (
		<>
			<div className='hc-not-very-short-screen'>
				<iframe allow="autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen frameBorder="0" height="315"
					src={"https://www.youtube.com/embed/" + videoId + "?rel=0"} title={"Video  " + videoId} width="560" style={{ display: 'block', margin: '0 auto' }}></iframe>
			</div>
			<div className='hc-very-short-screen'>
				<iframe allow="autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen frameBorder="0" height="220"
					src={"https://www.youtube.com/embed/" + videoId + "?rel=0"} title={"Video  " + videoId} width="392" style={{ display: 'block', margin: '0 auto' }}></iframe>
			</div>
		</>
	)
}


export default YouTubePlayer