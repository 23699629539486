import React, { ChangeEvent, useEffect } from 'react'
import { useState } from 'react'
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal'
import { HcInput } from '../input/HcInput';


interface TextEditionModalProps {
    language: string;
    titleId: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    isDarkTheme: boolean;
    text: string;
    onTextValidated: (r: string) => any;
}


function TextEditionModal({ language, titleId, show, setShow, isDarkTheme, text, onTextValidated }: TextEditionModalProps) {
    const [value, setValue] = useState<string>("")

    useEffect(() => {
        if (show)
            setValue(text)
    }, [show]);

    return (
        <Modal
            language={language}
            show={show}
            setShow={setShow}
            isDarkTheme={isDarkTheme}
        >
            <ModalHeader>
                <h3><GetHtmlStrLocalized language={language} textId={titleId} /></h3>
            </ModalHeader>
            <ModalBody>
                <HcInput value={value} onChange={(e: ChangeEvent<HTMLInputElement>) => { setValue(e.target.value); }} />
            </ModalBody>
            <br />
            <ModalFooter>
                <button onClick={() => {
                    onTextValidated(value)
                    setShow(false)
                }}>
                    <GetHtmlStrLocalized language={language} textId="validate" />
                </button>
            </ModalFooter>
        </Modal>
    );
}

export default TextEditionModal;