import GetHtmlStrLocalized from "../../datas/GetHtmlStrLocalized";


interface EditionLabelProps {
    paddingTop?: number;
    language: string;
    textId: string;
    color?: string;
}


function EditionLabel({ paddingTop = 17, language, textId, color="" }: EditionLabelProps) {
    return (
        <span style={{paddingTop, fontSize: 17, verticalAlign: 'middle', fontWeight: 'bold', color }}>
            <GetHtmlStrLocalized language={language} textId={textId} />
        </span>
    )
}

export default EditionLabel