import image_wait from '../../assets/resources/custom/icons/wait.gif'
import { ChangeEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import Button from '../button/Button'
import { ButtonType } from '../../props/buttondataprops'
import HorizontalSpace from '../util/HorizontalSpace'
import { HcContext } from '../../types/hccontext'
import Centered from '../util/Centered'
import EditionLabel from '../title/EditionLabel'
import H1Title from '../title/H1Title'
import { HcInput } from '../input/HcInput'
import { getWaitPopupStyle } from '../util/GetWaitPopupStyle'
import { GlossaryWordEdition, GlossaryWordInfoProps } from '../../types/GlossaryWordEdition'
import YesNoModal from '../modal/YesNoModal'
import GetStrLocalized from '../../datas/GetStrLocalized'
import { HcMessageInput } from '../input/HcMessageInput'
import { createErrorModalContent } from '../../props/ModalContentProps'


interface GlossaryWordModifierProps {
	language: string;
	hcContext: HcContext;
	inputWord: GlossaryWordInfoProps;
	lastPath: string;
}

class GlossaryWordModificationPostContentType {
	language: string;
	userKey: string;
	oldWord: string;
	value: GlossaryWordInfoProps;

	constructor(
		language: string,
		userKey: string,
		oldWord: string,
		value: GlossaryWordInfoProps) {
		this.language = language
		this.userKey = userKey
		this.oldWord = oldWord
		this.value = value
	}
}

class GlossaryWordRemovalPostContentType {
	language: string;
	userKey: string;
	word: string;

	constructor(
		language: string,
		userKey: string,
		word: string) {
		this.language = language
		this.userKey = userKey
		this.word = word
	}
}

function GlossaryWordModifier({ language, hcContext, inputWord, lastPath }: GlossaryWordModifierProps) {

	const navigate = useNavigate()
	const [modificationDone, setModificationDone] = useState(false)
	const [canBeValidated, setCanBeValidated] = useState(false)
	const [processingModifications, setProcessingModifications] = useState(false)
	const [showCanCancelModifictionsModal, setShowCanCancelModifictionsModal] = useState(false)
	const [showCanDeleteModifictionsModal, setShowCanDeleteModifictionsModal] = useState(false)
	const [word, setWord] = useState<string>("")
	const [shortDefinition, setShortDefinition] = useState<string>("")
	const [definition, setDefinition] = useState<string>("")
	const [source, setSource] = useState<string>("")
	const [url, setUrl] = useState<string>("")
	const glossaryWordEdition = new GlossaryWordEdition({ word, shortDefinition, definition, source, url },
		setWord, setShortDefinition, setDefinition, setSource, setUrl)

	useEffect(() => {
		if (JSON.stringify(inputWord) !== JSON.stringify(glossaryWordEdition.value)) {
			setModificationDone(true)
			setCanBeValidated(
				glossaryWordEdition.value.word !== "" &&
				glossaryWordEdition.value.shortDefinition !== "" &&
				glossaryWordEdition.value.source !== "")
		} else {
			setModificationDone(false)
			setCanBeValidated(false)
		}
	}, [word, shortDefinition, definition, source, url]);

	useEffect(() => {
		glossaryWordEdition.set(inputWord)
		console.log("lastPath: " + lastPath)
	}, [])

	function sendWordModification() {
		if (!hcContext.userToken)
			return
		setProcessingModifications(true)
		hcContext.setBlackCoverOfContent(true)
		const postContent = new GlossaryWordModificationPostContentType(language, hcContext.userToken,
			inputWord.word, glossaryWordEdition.value)
		var postContentStr = JSON.stringify(postContent)
		fetch(hcContext.backendUrl + "/modify_glossary_word", {
			method: 'POST',
			body: postContentStr,
			headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
		})
			.then(res => res.json())
			.then(jsonData => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				if (jsonData.errorMessage)
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
				else
					navigate(lastPath)
			})
			.catch(rejected => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	function removeWord() {
		if (!hcContext.userToken)
			return
		setProcessingModifications(true)
		hcContext.setBlackCoverOfContent(true)
		const postContent = new GlossaryWordRemovalPostContentType(language, hcContext.userToken, inputWord.word)
		fetch(hcContext.backendUrl + "/remove_glossary_word", {
			method: 'POST',
			body: JSON.stringify(postContent),
			headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
		})
			.then(res => res.json())
			.then(jsonData => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				if (jsonData.errorMessage)
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
				else
					navigate("/" + language + "/" + GetStrLocalized(language, "glossaryFolderName"))
			})
			.catch(rejected => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	return (
		<>
			{
				processingModifications &&
				<Centered>
					<div style={getWaitPopupStyle(true, 50)}>
						<img src={image_wait} alt="wait" height="100%" width="100%" />
					</div>
				</Centered>
			}
			<table width='100%'>
				<tbody>
					<tr>
						<td width={(30 + 15) * 2 + 45} style={{ paddingTop: 20 }}>
							{
								canBeValidated &&
								!processingModifications &&
								<button onClick={sendWordModification}>
									<GetHtmlStrLocalized language={language} textId="modify" />
								</button>
							}
						</td>
						<td>
							<H1Title>
								{
									glossaryWordEdition.value.word.length > 0 ?
										<EditionLabel paddingTop={0} language={language} textId="name" />
										:
										<EditionLabel paddingTop={0} language={language} textId="name" color="red" />
								}
								&nbsp;
								<HcInput width='500px' value={glossaryWordEdition.value.word} onChange={(e: ChangeEvent<HTMLInputElement>) => { glossaryWordEdition.setWord(e.target.value); }} />
							</H1Title>
						</td>
						{
							(hcContext.userAsRightsToModifyTheContent && !processingModifications) ?
								<td width={(30 + 15) * 2 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
									{
										(modificationDone || inputWord.word === "") ?
											<>
												{
													canBeValidated ?
														<>
															<Button btnData={{ type: ButtonType.YES, onClick: () => sendWordModification() }} language={language} altId="modify" />
															<HorizontalSpace />
														</>
														:
														<>
															<HorizontalSpace width={30} />
															<HorizontalSpace />
														</>
												}
											</>
											:
											<>
												<Button btnData={{ type: ButtonType.DELETE, onClick: () => setShowCanDeleteModifictionsModal(true) }} language={language} />
												<HorizontalSpace />
											</>
									}
									<Button btnData={{ type: ButtonType.NO, onClick: () => modificationDone ? setShowCanCancelModifictionsModal(true) : navigate(lastPath) }} language={language} altId="cancel" />
									<HorizontalSpace />
								</td>
								:
								<td width={(30 + 15) * 2 + 45} className="hc-not-very-short-screen"></td>
						}
					</tr>
				</tbody>
			</table>
			<br />

			<div style={{ marginLeft: 20, marginRight: 20 }}>

				{
					glossaryWordEdition.value.shortDefinition.length > 0 ?
						<EditionLabel paddingTop={0} language={language} textId="shortDefinition" />
						:
						<EditionLabel paddingTop={0} language={language} textId="shortDefinition" color="red" />
				}
				<HcInput value={glossaryWordEdition.value.shortDefinition} onChange={(e: ChangeEvent<HTMLInputElement>) => { glossaryWordEdition.setShortDefinition(e.target.value); }} />
				<br />
				<br />
				<br />

				<HcMessageInput language={language} hcContext={hcContext} titleId="definition" htmlText={glossaryWordEdition.value.definition} setHtmlText={glossaryWordEdition.setDefinition} />
				<br />
				<br />
				<br />

				{
					glossaryWordEdition.value.shortDefinition.length > 0 ?
						<EditionLabel paddingTop={0} language={language} textId="source" />
						:
						<EditionLabel paddingTop={0} language={language} textId="source" color="red" />
				}
				<HcInput value={glossaryWordEdition.value.source} onChange={(e: ChangeEvent<HTMLInputElement>) => { glossaryWordEdition.setSource(e.target.value); }} />
				<br />
				<br />
				<br />

				<EditionLabel paddingTop={0} language={language} textId="url" />
				<HcInput value={glossaryWordEdition.value.url} onChange={(e: ChangeEvent<HTMLInputElement>) => { glossaryWordEdition.setUrl(e.target.value); }} />
				<br />
				<br />
				<br />

			</div>

			<YesNoModal language={language} show={showCanCancelModifictionsModal} setShow={setShowCanCancelModifictionsModal}
			    isDarkTheme={hcContext.isDarkTheme}
				title={GetStrLocalized(language, "cancellationConfirmation")}
				text={GetStrLocalized(language, "areYouSureToUndotheChanges")}
				onYes={() => navigate(lastPath)} />

			<YesNoModal language={language} show={showCanDeleteModifictionsModal} setShow={setShowCanDeleteModifictionsModal}
			    isDarkTheme={hcContext.isDarkTheme}
				title={GetStrLocalized(language, "removalConfirmation")}
				text={GetStrLocalized(language, "areYouSureToPermanentlyDeletethisWord")}
				onYes={() => removeWord()} />
		</>
	)
}

export default GlossaryWordModifier
