

export function imgToFirebaseUrl2(image: string): string {
	if (image.length <= 0)
		return ""
	return "https://firebasestorage.googleapis.com/v0/b/holy-chatter.appspot.com/o/" + image.replaceAll('/', '%2F') + "?alt=media"
}

export function imgToFirebaseUrl(image: string): string {
	if (image.length <= 0)
		return ""
	image = image.substring(1);
	return "https://firebasestorage.googleapis.com/v0/b/holy-chatter.appspot.com/o/" + image.replaceAll('/', '%2F') + "?alt=media"
}

export const onErrorImageHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
	image: string
  ) => {
	event.currentTarget.onerror = null;
	event.currentTarget.src = imgToFirebaseUrl(image)
  };
