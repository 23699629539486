import { NavigateFunction } from "react-router-dom";
import { FolderProps } from "../props/folderprops";
import GetStrLocalized from "../datas/GetStrLocalized";
import { HcContext } from "../types/hccontext";
import { createErrorModalContent } from "../props/ModalContentProps";

class ArticleMovePostType {
    language: string;
    userKey: string;
    sourcePath: string[];
    targetPath: string[];
    filename: string;

    constructor(
        language: string,
        userKey: string,
        sourcePath: string[],
        targetPath: string[],
        filename: string) {
        this.language = language;
        this.userKey = userKey;
        this.sourcePath = sourcePath;
        this.targetPath = targetPath;
        this.filename = filename;
    }
}


export function moveItem(parentFolders: FolderProps[], targetPath: string[],
    itemName: string, hcContext: HcContext, language: string,
    setProcessingModifications: (value: React.SetStateAction<boolean>) => void,
    navigate: NavigateFunction) {
    if (!hcContext.userToken)
        return
    setProcessingModifications(true)
    hcContext.setBlackCoverOfContent(true)
    let sourcePath = Array.isArray(parentFolders) ?
        parentFolders.filter((obj) => obj.name !== "").map(item => item.name)
        : [];
    const postContent = new ArticleMovePostType(language, hcContext.userToken, sourcePath, targetPath, itemName)
    fetch(hcContext.backendUrl + "/move_item", {
        method: 'POST',
        body: JSON.stringify(postContent),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(jsonData => {
            setProcessingModifications(false)
            hcContext.setBlackCoverOfContent(false)
            if (jsonData.errorMessage) {
                hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
                return
            }
            navigate('/' + language + '/' + GetStrLocalized(language, "categoriesFolderName") + '/' + jsonData.id);
        })
        .catch(rejected => {
            setProcessingModifications(false)
            hcContext.setBlackCoverOfContent(false)
            hcContext.setInfo(createErrorModalContent(language, rejected.message))
        });
}