import './assets/resources/custom/style/main-6.css'
import './assets/resources/custom/style/bootstrap_hc.css'
import './assets/resources/custom/style/dialog_max_height.css'
import './assets/resources/custom/style/categories-1.css'
import './assets/resources/custom/style/hc-chatbot-widget.css'
import './assets/resources/custom/style/video_size-0.css'
import './assets/resources/custom/style/chapters.css'
import './assets/resources/custom/style/sources-0.css'
import { useEffect, useState } from 'react'
import { Routes, Route, useLocation } from "react-router-dom";
import Home from './pages/Home';
import { Helmet } from "react-helmet";
import Banner from './components/banner/Banner'
import HCNavBar from './components/navigation/HCNavBar'
import GetStrLocalized from './datas/GetStrLocalized'
import Search from './pages/Search'
import About from './pages/About'
import Categories from './pages/Categories'
import TextsOfThePope from './pages/TextsOfThePope'
import Chatbot from './pages/Chatbot'
import Api from './pages/Api'
import Article from './pages/Article'
import NavigateTowardDefaultLanguage from './pages/NavigateTowardDefaultLanguage'
import EmbeddedChatbot from './components/chatbot/EmbeddedChatbot'
import Sources from './pages/Sources'
import Source from './pages/Source'
import ChatbotWindow from './components/chatbot/ChatbotWindow'
import Readings from './pages/Readings'
import ReadingsOfTheDay from './pages/ReadingsOfTheDay'
import Bible from './pages/Bible'
import CatechismOfTheCatholicChurch from './pages/CatechismOfTheCatholicChurch'
import ChristianMessage from './pages/ChristianMessage'
import { ModelWithPath, ModelWithPathProps } from './types/modelwithpath'
import { HcContext, SizeOfWindow } from './types/hccontext'
import { ChatbotContext } from './types/chatbotcontext'
import { UserProps } from './props/userprops'
import ChatbotConfig from './pages/ChatbotConfig'
import TestsResults, { TestsResultsType } from './pages/TestsResults'
import History from './pages/History'
import Tests from './pages/Tests'
import CmsHome from './pages/CmsHome'
import Tags from './pages/Tags'
import Facts from './pages/Facts'
import Fact from './pages/Fact'
import ChatHistory from './pages/ChatHistory'
import ContentEditHistory from './pages/ContentEditHistory'
import Glossary from './pages/Glossary'
import GlossaryWord from './pages/GlossaryWord'
import InformationModal from './components/modal/InformationModal'
import { ModalContentProps, createErrorModalContent, emptyModalContent } from './props/ModalContentProps'


function App() {
	const backendUrl = (process.env.NODE_ENV === "development" || !process.env.REACT_APP_BACKEND_URL) ? "http://127.0.0.1:8080" : process.env.REACT_APP_BACKEND_URL
	const backendUrlNodeJS = (process.env.NODE_ENV === "development" || !process.env.REACT_APP_BACKEND_URL_NODEJS) ? "http://127.0.0.1:5000" : process.env.REACT_APP_BACKEND_URL_NODEJS
	const location = useLocation();
	const [lastPath, setLastPath] = useState("")
	const [isInChatbotPage, setIsInChatbotPage] = useState(false);

	function getSizeOfWindow() {
		if (window.innerWidth <= 850)
			return SizeOfWindow.VERY_SHORT;
		if (window.innerWidth <= 1100)
			return SizeOfWindow.INTERMEDIARY;
		return SizeOfWindow.WIDE;
	}

	const [documentTitle, setDocumentTitle] = useState("Holy Chatter");
	const [sizeOfWindow, setSizeOfWindow] = useState<SizeOfWindow>(getSizeOfWindow());
	const [user, setUser] = useState<UserProps | undefined>(undefined)
	const [userToken, setUserToken] = useState<string | undefined>(undefined)
	const [userIsIdentified, setUserIsIdentified] = useState<boolean>(false)
	const [userAsRightsToModifyTheContent, setUserAsRightsToModifyTheContent] = useState<boolean>(false)
	const [blackCoverOfContent, setBlackCoverOfContent] = useState(false)
	const [info, setInfo] = useState<ModalContentProps>(emptyModalContent())

	const [isDarkTheme, setIsDarkTheme] = useState(
		window.matchMedia('(prefers-color-scheme: dark)').matches
	);
	useEffect(() => {
		const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
		const handleChange = () => setIsDarkTheme(mediaQuery.matches);
		mediaQuery.addEventListener('change', handleChange);
		return () => {
			mediaQuery.removeEventListener('change', handleChange);
		};
	}, []);

	const hcContext = new HcContext(documentTitle, setDocumentTitle, sizeOfWindow, setSizeOfWindow,
		backendUrl, backendUrlNodeJS, user, setUser, userToken, setUserToken,
		userIsIdentified, setUserIsIdentified, userAsRightsToModifyTheContent, setUserAsRightsToModifyTheContent,
		blackCoverOfContent, setBlackCoverOfContent, info, setInfo, isDarkTheme);

	const [language, setLanguage] = useState(location.pathname.length >= 3 ? location.pathname.substring(1, 3) : "en");

	const [loadedModel, setLoadedModel] = useState<ModelWithPathProps>();
	const modelWithPath = new ModelWithPath(loadedModel, setLoadedModel);
	const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false)

	const [chatbotStarted, setChatbotStarted] = useState(false);
	const [hasAWaitingMessage, setHasAWaitingMessage] = useState(false);
	const [isChatbotWindowOpen, setIsChatbotWindowOpen] = useState(false);
	const chatbotContext = new ChatbotContext(chatbotStarted, setChatbotStarted,
		hasAWaitingMessage, setHasAWaitingMessage,
		isChatbotWindowOpen, setIsChatbotWindowOpen);

	const [firstLoad, setFirstLoad] = useState(true);
	const [sessionId, setSessionId] = useState("")
	useEffect(() => {
		setInterval(() => {
			const timestamp = Math.floor(Date.now() / 1000);
			fetch(backendUrl + "/session_ping_content_json?sessionId=" + sessionId + "&cookieId=cookieId&timestamp=" + timestamp)
				.catch(rejected => {
					hcContext.setInfo(createErrorModalContent(language, rejected.message))
				});
			console.log('ping: ' + sessionId + ' time:' + timestamp);
		}, 30000); // every 30 seconds
	}, [sessionId]);
	if (firstLoad) {
		setSessionId(Math.random().toString(16).slice(-8));
		setFirstLoad(false);
	}

	useEffect(() => {
		setShowErrorMessage(info.content !== "");
	}, [info.content])

	function updateWidowSize() {
		console.log("window.innerWidth: " + window.innerWidth)
		setSizeOfWindow(getSizeOfWindow());
	}

	window.addEventListener("resize", updateWidowSize)
	window.addEventListener("load", updateWidowSize)

	const [searchValue, setSearchValue] = useState("")

	if (location.pathname !== lastPath) {
		setLastPath(location.pathname);
		const foldersArray = location.pathname.split('/');
		setIsInChatbotPage(foldersArray.length > 2 && foldersArray[2] === 'chatbot')

		if (foldersArray.length > 2 && foldersArray[2] === GetStrLocalized(language, "searchFolderName")) {
			if (foldersArray.length > 3) {
				setSearchValue(decodeURI(foldersArray[3]).replaceAll("%23", "#"));
			} else {
				setSearchValue("");
			}
		}
	}

	useEffect(() => {
		document.body.className = hcContext.getBackgroundColorClass()
	}, [isDarkTheme]);

	return (
		<>
			<Helmet>
				<html lang={language} />
				<title>{documentTitle}</title>
				<meta http-equiv="content-language" content={language} />
				<meta name="language" content={language} />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
			</Helmet>
			<Routes>
				<Route path='/fr/' element={<Home language="fr" hcContext={hcContext} />}></Route>
				<Route path='/fr/*'>
					<Route path={GetStrLocalized("fr", "aboutFolderName")} element={<About language="fr" setDocumentTitle={setDocumentTitle} backendUrl={backendUrl} />}></Route>
					<Route path={GetStrLocalized("fr", "apiFolderName")} element={<Api language="fr" setDocumentTitle={setDocumentTitle} />}></Route>
					<Route path={GetStrLocalized("fr", "categoriesFolderName") + "/*"} element={<Categories language="fr" hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("fr", "chatbotFolderName") + "/*"} element={<Chatbot language="fr" hcContext={hcContext} chatbotContext={chatbotContext} sessionId={sessionId} />}></Route>
					<Route path={GetStrLocalized("fr", "chatbotConfigFolderName") + "/*"} element={<ChatbotConfig language="fr" hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("fr", "christianMessageFolderName")} element={<ChristianMessage language="fr" setDocumentTitle={setDocumentTitle} isDarkTheme={hcContext.isDarkTheme} />}></Route>
					<Route path={GetStrLocalized("fr", "cmsFolderName")} element={<CmsHome language="fr" hcContext={hcContext} backendUrl={backendUrl} />}></Route>
					<Route path={GetStrLocalized("fr", "cmsFolderName") + "/" + GetStrLocalized("fr", "testsFolderName")} element={<Tests language="fr" hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("fr", "cmsFolderName") + "/" + GetStrLocalized("fr", "testsFolderName") + "/" + GetStrLocalized("fr", "resultsFolderName")} element={<TestsResults language="fr" hcContext={hcContext} testsResultsType={TestsResultsType.SHOW_MODIFIED} />}></Route>
					<Route path={GetStrLocalized("fr", "cmsFolderName") + "/" + GetStrLocalized("fr", "testsFolderName") + "/" + GetStrLocalized("fr", "notOkFolderName")} element={<TestsResults language="fr" hcContext={hcContext} testsResultsType={TestsResultsType.SHOW_NOTOK} />}></Route>
					<Route path={GetStrLocalized("fr", "cmsFolderName") + "/" + GetStrLocalized("fr", "historyFolderName")} element={<History language="fr" hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("fr", "cmsFolderName") + "/" + GetStrLocalized("fr", "historyFolderName") + "/" + GetStrLocalized("fr", "chatHistoryFolderName")} element={<ChatHistory language="fr" hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("fr", "cmsFolderName") + "/" + GetStrLocalized("fr", "historyFolderName") + "/" + GetStrLocalized("fr", "contentEditHistoryFolderName")} element={<ContentEditHistory language="fr" hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("fr", "factsFolderName") + "/"} element={<Facts language="fr" setDocumentTitle={setDocumentTitle} backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("fr", "factsFolderName") + "/*"} element={<Fact language="fr" setDocumentTitle={setDocumentTitle} backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("fr", "searchFolderName") + "/*"} element={<Search language="fr" hcContext={hcContext} searchValue={searchValue} />}></Route>
					<Route path={GetStrLocalized("fr", "readingsFolderName")} element={<Readings language="fr" setDocumentTitle={setDocumentTitle} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("fr", "readingsFolderName") + "/" + GetStrLocalized("fr", "bibleFolderName") + "/*"} element={<Bible language="fr" backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("fr", "readingsFolderName") + "/" + GetStrLocalized("fr", "catechismOfTheCatholicChurchFolderName") + "/*"} element={<CatechismOfTheCatholicChurch language="fr" backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("fr", "readingsFolderName") + "/" + GetStrLocalized("fr", "textsOfThePopeFolderName")} element={<TextsOfThePope language="fr" setDocumentTitle={setDocumentTitle} />}></Route>
					<Route path={GetStrLocalized("fr", "readingsOfTheDayFolderName") + "/*"} element={<ReadingsOfTheDay language="fr" backendUrl={backendUrl} readingsType="all" hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("fr", "gospelOfTheDayFolderName") + "/*"} element={<ReadingsOfTheDay language="fr" backendUrl={backendUrl} readingsType="gospel" hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("fr", "sourcesFolderName") + "/"} element={<Sources language="fr" setDocumentTitle={setDocumentTitle} backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("fr", "sourcesFolderName") + "/*"} element={<Source language="fr" setDocumentTitle={setDocumentTitle} backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("fr", "tagsFolderName") + "/"} element={<Tags language="fr" setDocumentTitle={setDocumentTitle} backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("fr", "glossaryFolderName") + "/"} element={<Glossary language="fr" backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("fr", "glossaryFolderName") + "/*"} element={<GlossaryWord language="fr" backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path='*' element={<Article language="fr" hcContext={hcContext} />}></Route>
				</Route>
				<Route path='/en/' element={<Home language="en" hcContext={hcContext} />}></Route>
				<Route path='/en/*'>
					<Route path={GetStrLocalized("en", "aboutFolderName")} element={<About language="en" setDocumentTitle={setDocumentTitle} backendUrl={backendUrl} />}></Route>
					<Route path={GetStrLocalized("en", "apiFolderName")} element={<Api language="en" setDocumentTitle={setDocumentTitle} />}></Route>
					<Route path={GetStrLocalized("en", "categoriesFolderName") + "/*"} element={<Categories language="en" hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("en", "chatbotFolderName") + "/*"} element={<Chatbot language="en" hcContext={hcContext} chatbotContext={chatbotContext} sessionId={sessionId} />}></Route>
					<Route path={GetStrLocalized("en", "chatbotConfigFolderName") + "/*"} element={<ChatbotConfig language="en" hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("en", "cmsFolderName")} element={<CmsHome language="en" hcContext={hcContext} backendUrl={backendUrl} />}></Route>
					<Route path={GetStrLocalized("en", "cmsFolderName") + "/" + GetStrLocalized("en", "testsFolderName")} element={<Tests language="en" hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("en", "cmsFolderName") + "/" + GetStrLocalized("en", "testsFolderName") + "/" + GetStrLocalized("en", "resultsFolderName")} element={<TestsResults language="en" hcContext={hcContext} testsResultsType={TestsResultsType.SHOW_MODIFIED} />}></Route>
					<Route path={GetStrLocalized("en", "cmsFolderName") + "/" + GetStrLocalized("en", "testsFolderName") + "/" + GetStrLocalized("en", "notOkFolderName")} element={<TestsResults language="en" hcContext={hcContext} testsResultsType={TestsResultsType.SHOW_NOTOK} />}></Route>
					<Route path={GetStrLocalized("en", "cmsFolderName") + "/" + GetStrLocalized("en", "historyFolderName")} element={<History language="en" hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("en", "cmsFolderName") + "/" + GetStrLocalized("en", "historyFolderName") + "/" + GetStrLocalized("en", "chatHistoryFolderName")} element={<ChatHistory language="en" hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("en", "cmsFolderName") + "/" + GetStrLocalized("en", "historyFolderName") + "/" + GetStrLocalized("en", "contentEditHistoryFolderName")} element={<ContentEditHistory language="en" hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("en", "factsFolderName") + "/"} element={<Facts language="en" setDocumentTitle={setDocumentTitle} backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("en", "factsFolderName") + "/*"} element={<Fact language="en" setDocumentTitle={setDocumentTitle} backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("en", "searchFolderName") + "/*"} element={<Search language="en" hcContext={hcContext} searchValue={searchValue} />}></Route>
					<Route path={GetStrLocalized("en", "readingsFolderName")} element={<Readings language="en" setDocumentTitle={setDocumentTitle} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("en", "readingsFolderName") + "/" + GetStrLocalized("en", "bibleFolderName") + "/*"} element={<Bible language="en" backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("en", "readingsFolderName") + "/" + GetStrLocalized("en", "catechismOfTheCatholicChurchFolderName") + "/*"} element={<CatechismOfTheCatholicChurch language="en" backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("en", "readingsFolderName") + "/" + GetStrLocalized("en", "textsOfThePopeFolderName")} element={<TextsOfThePope language="en" setDocumentTitle={setDocumentTitle} />}></Route>
					<Route path={GetStrLocalized("en", "readingsOfTheDayFolderName") + "/*"} element={<ReadingsOfTheDay language="en" backendUrl={backendUrl} readingsType="all" hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("en", "gospelOfTheDayFolderName") + "/*"} element={<ReadingsOfTheDay language="en" backendUrl={backendUrl} readingsType="gospel" hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("en", "sourcesFolderName") + "/"} element={<Sources language="en" setDocumentTitle={setDocumentTitle} backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("en", "sourcesFolderName") + "/*"} element={<Source language="en" setDocumentTitle={setDocumentTitle} backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("en", "tagsFolderName") + "/"} element={<Tags language="en" setDocumentTitle={setDocumentTitle} backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("en", "glossaryFolderName") + "/"} element={<Glossary language="en" backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path={GetStrLocalized("en", "glossaryFolderName") + "/*"} element={<GlossaryWord language="en" backendUrl={backendUrl} hcContext={hcContext} />}></Route>
					<Route path='*' element={<Article language="en" hcContext={hcContext} />}></Route>
				</Route>
				<Route path='*' element={<NavigateTowardDefaultLanguage language="fr" setLanguage={setLanguage} />}></Route>
			</Routes>
			<HCNavBar language={language} setLanguage={setLanguage} hcContext={hcContext} />
			<Banner language={language} hcContext={hcContext} isInChatbotPage={isInChatbotPage} searchValue={searchValue} />

			{!isInChatbotPage &&
				<>
					<EmbeddedChatbot language={language} hcContext={hcContext} chatbotContext={chatbotContext} setIsChatbotWindowOpen={setIsChatbotWindowOpen} />
					{isChatbotWindowOpen && <ChatbotWindow language={language} hcContext={hcContext} sessionId={sessionId} chatbotContext={chatbotContext} modelWithPath={modelWithPath} />}
				</>
			}

			{
				blackCoverOfContent &&
				<div style={{
					position: 'fixed',
					top: '0px',
					bottom: '0px',
					width: '100%',
					height: '100%',
					zIndex: 999,
					backgroundColor: 'black',
					opacity: '0.5'
				}}>
				</div>
			}

			<InformationModal language={language} show={showErrorMessage} setShow={(show) => {
				setShowErrorMessage(show)
				setInfo(emptyModalContent())
			}} isDarkTheme={hcContext.isDarkTheme} title={info.title}
				text={info.content} />
		</>
	)
}

export default App
