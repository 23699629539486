import { ButtonDataProps } from '../../props/buttondataprops'
import Button from '../button/Button'
import HorizontalSpace from '../util/HorizontalSpace'


interface NavigationArrowsProps {
	btnDatas: ButtonDataProps[],
	language: string
}


function NavigationArrows({btnDatas, language}: NavigationArrowsProps) {

	return (
		<>
			{
				btnDatas &&
				btnDatas.length > 0 &&
				<>
					{
						btnDatas.map((btnData, btnDatasIndex) => {
							return (
								<span key={"arrow-" + btnDatasIndex}>
									<Button btnData={btnData} language={language} />
									<HorizontalSpace />
								</span>
							)
						})
					}
				</>
			}
		</>
	)
}

export default NavigationArrows