import GetHtmlStrLocalized from "../../datas/GetHtmlStrLocalized"
import H1Title from "./H1Title"

interface H1TitleIdProps {
    language: string;
    titleId: string;
}

const H1TitleId: React.FC<H1TitleIdProps> = ({language, titleId}) => {

    return <H1Title><GetHtmlStrLocalized language={language} textId={titleId} /></H1Title>
}

export default H1TitleId