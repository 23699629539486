import React, { useEffect } from 'react'
import { useState } from 'react'
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal'
import CategoriesBigButtons, { CategoryProps } from '../bigButton/CategoriesBigButtons'
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import { FolderProps } from '../../props/folderprops'
import GetStrLocalized from '../../datas/GetStrLocalized'
import YesNoModal from './YesNoModal'
import { HcContext } from '../../types/hccontext'


interface MoveDestinationPathSelectorModalProps {
    language: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    backendUrl: string;
    itemName: string;
    onPathChoosen: (path: string[]) => void;
    hcContext: HcContext;
}


function MoveDestinationPathSelectorModal({ language, show, setShow, backendUrl, itemName, onPathChoosen, hcContext }: MoveDestinationPathSelectorModalProps) {
    const [currentFolderId, setCurrentFolderId] = useState<string>("")
    const [request, setRequest] = useState({
        name: "", nameToPrint: "", parentFolders: [], bigButtontDatas: [], chatMsgs: [], arrows: [],
        bigButtontDatas_hidden: [], chatMsgs_hidden: []
    })
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [targetPath, setTargetPath] = useState<string[]>([])
    const [publicFolders, setPublicFolders] = useState<CategoryProps[]>([])
    const [hiddenFolders, setHiddenFolders] = useState<CategoryProps[]>([])


    useEffect(() => {
        if (show) {
            setCurrentFolderId("")
        }
    }, [show]);

    useEffect(() => {
        if (show && !showConfirmationModal) {
            fetch(backendUrl + "/categories_page_json?l=" + language + "&id=" + currentFolderId)
                .then(res => res.json())
                .then(jsonData => {
                    setRequest(jsonData);
                    let pf = Array.isArray(jsonData.bigButtontDatas) ? jsonData.bigButtontDatas.filter((item: CategoryProps) => item.type === "folder") : []
                    setPublicFolders(pf)
                    let hf = Array.isArray(jsonData.bigButtontDatas_hidden) ? jsonData.bigButtontDatas_hidden.filter((item: CategoryProps) => item.type === "folder") : []
                    setHiddenFolders(hf)
                    if (pf.length === 0 && hf.length === 0)
                        validateMove(jsonData.parentFolders)
                })
                .catch(rejected => {
                    console.log(rejected);
                });
        }
    }, [show, currentFolderId]);


    function validateMove(parentFolders: FolderProps[]) {
        let tp = Array.isArray(parentFolders) ? parentFolders.filter((item: FolderProps) => item.name !== "").map((item: FolderProps) => item.name) : []
        setTargetPath(tp)
        setShow(false)
        setShowConfirmationModal(true)
        setCurrentFolderId("") // After `setShowConfirmationModal(true)` to not request again the backend
    }

    return (
        <>
            <Modal
                language={language}
                show={show}
                setShow={setShow}
                isDarkTheme={hcContext.isDarkTheme}
                display='flex'
                contentWidth="90%"
            >
                <ModalHeader>
                    <h3><GetHtmlStrLocalized language={language} textId={"moveTowards"} param1={itemName} /></h3>
                </ModalHeader>
                <ModalBody>
                    <CategoriesBigButtons categories={publicFolders} printNumber={false} onClick={(id) => setCurrentFolderId(id)} hcContext={hcContext} />
                    {
                        hiddenFolders.length > 0 &&
                        <>
                            <br />
                            <br />
                            <b><GetHtmlStrLocalized language={language} textId="privateSection" /></b>
                            <CategoriesBigButtons categories={hiddenFolders} printNumber={false} onClick={(id) => setCurrentFolderId(id)} hcContext={hcContext} />
                        </>
                    }
                </ModalBody>
                <br />
                <ModalFooter>
                    <button onClick={() => { validateMove(request.parentFolders) }}>
                        <GetHtmlStrLocalized language={language} textId="moveHere" />
                    </button>
                </ModalFooter>
            </Modal>

            <YesNoModal language={language} show={showConfirmationModal} setShow={setShowConfirmationModal}
                isDarkTheme={hcContext.isDarkTheme}
                title={GetStrLocalized(language, "moveConfirmation")}
                text={GetStrLocalized(language, "moveTowards", itemName, request.name)}
                onYes={() => {
                    if (request.name !== "") {
                        onPathChoosen([...targetPath, request.name])
                    } else {
                        onPathChoosen([])
                    }
                }} />
        </>
    );
}

export default MoveDestinationPathSelectorModal;