import React from 'react';
import Select, { StylesConfig } from 'react-select';
import { SelectEltProps } from '../../types/hccontext';


interface SourcesSelectProps {
  elts: SelectEltProps[];
  selectedElt: SelectEltProps | null;
  setSelectedElt: React.Dispatch<React.SetStateAction<SelectEltProps | null>>;
  styles: StylesConfig<SelectEltProps, false>;
}


function SourcesSelect({ elts, selectedElt, setSelectedElt, styles }: SourcesSelectProps) {
  return (
    <Select
      value={selectedElt}
      onChange={(option: SelectEltProps | null) => {
        setSelectedElt(option);
      }}
      getOptionLabel={(elt: SelectEltProps) => elt.label}
      getOptionValue={(elt: SelectEltProps) => elt.value}
      options={elts}
      isClearable={false}
      backspaceRemovesValue={true}
      styles={styles}
    />
  );
}

export default SourcesSelect;