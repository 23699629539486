import '../assets/resources/custom/style/main-6.css'
import PageContent from '../components/util/PageContent'
import H1TitleId from '../components/title/H1TitleId'
import { Navigate } from 'react-router-dom'
import { HcContext } from '../types/hccontext'
import GetStrLocalized from '../datas/GetStrLocalized'
import GetHtmlStrLocalized from '../datas/GetHtmlStrLocalized'
import CategoriesBigButtons, { CategoryProps } from '../components/bigButton/CategoriesBigButtons'
import { useEffect, useState } from 'react'
import Centered from '../components/util/Centered'
import { getWaitPopupStyle } from '../components/util/GetWaitPopupStyle'
import image_wait from '../assets/resources/custom/icons/wait.gif'
import InformationModal from '../components/modal/InformationModal'
import H2TitleId from '../components/title/H2TitleId'
import { createErrorModalContent } from '../props/ModalContentProps'


interface CmsHomeProps {
    language: string;
    hcContext: HcContext;
    backendUrl: string;
}

class AnyPostContentType {
    language: string;
    userKey: string;

    constructor(
        language: string,
        userKey: string) {
        this.language = language
        this.userKey = userKey
    }
}

function CmsHome({ language, hcContext, backendUrl }: CmsHomeProps) {

    useEffect(() => {
        hcContext.setDocumentTitle("CMS - Holy Chatter")
    }, [hcContext])

    const categories: CategoryProps[] = [
        {
            nb: undefined, type: "", id: "", link: "/" + language + "/" + GetStrLocalized(language, "categoriesFolderName"), image: "/categories/postits.jpg",
            duration: "", title: GetStrLocalized(language, "categories"), subTitle: "", sourceName: "", sourceImage: "", stars: undefined
        },
        {
            nb: undefined, type: "", id: "", link: "/" + language + "/" + GetStrLocalized(language, "factsFolderName"), image: "/categories/christian_chatbot.jpg",
            duration: "", title: GetStrLocalized(language, "factsForChatbotPlannification"), subTitle: "", sourceName: "", sourceImage: "", stars: undefined
        },
        {
            nb: undefined, type: "", id: "", link: "/" + language + "/" + GetStrLocalized(language, "cmsFolderName") + "/" + GetStrLocalized(language, "testsFolderName"), image: "/categories/tests.avif",
            duration: "", title: GetStrLocalized(language, "tests"), subTitle: "", sourceName: "", sourceImage: "", stars: undefined
        },
        {
            nb: undefined, type: "", id: "", link: "/" + language + "/" + GetStrLocalized(language, "cmsFolderName") + "/" + GetStrLocalized(language, "historyFolderName"), image: "/categories/clock.jpg",
            duration: "", title: GetStrLocalized(language, "history"), subTitle: "", sourceName: "", sourceImage: "", stars: undefined
        },
        {
            nb: undefined, type: "", id: "", link: "/" + language + "/" + GetStrLocalized(language, "glossaryFolderName"), image: "/categories/glossary.jpg",
            duration: "", title: GetStrLocalized(language, "glossary"), subTitle: "", sourceName: "", sourceImage: "", stars: undefined
        }
    ]
    const [processingModifications, setProcessingModifications] = useState(false)
    const [dialogText, setDialogText] = useState("")
    const [errorMessages, setErrorMessages] = useState([])
    const [showSanityCheckModal, setShowSanityCheckModal] = useState(false)

    function regenerateSitemapXml() {
        if (!hcContext.userToken)
            return
        setProcessingModifications(true)
        hcContext.setBlackCoverOfContent(true)
        const postContent = new AnyPostContentType(language, hcContext.userToken)
        fetch(hcContext.backendUrl + "/regenerate_sitemap", {
            method: 'POST',
            body: JSON.stringify(postContent),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
            .then(res => res.json())
            .then(jsonData => {
                setProcessingModifications(false)
                hcContext.setBlackCoverOfContent(false)
                if (jsonData.errorMessage) {
                    hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
                    return
                }
            })
            .catch(rejected => {
                setProcessingModifications(false)
                hcContext.setBlackCoverOfContent(false)
                hcContext.setInfo(createErrorModalContent(language, rejected.message))
            });
    }

    function showSanityChecksResult() {
        if (!hcContext.userToken)
            return
        setProcessingModifications(true)
        hcContext.setBlackCoverOfContent(true)
        const postContent = new AnyPostContentType(language, hcContext.userToken)
        fetch(hcContext.backendUrl + "/sanity_checks_json", {
            method: 'POST',
            body: JSON.stringify(postContent),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
            .then(res => res.json())
            .then(jsonData => {
                setProcessingModifications(false)
                hcContext.setBlackCoverOfContent(false)

                if (jsonData.errorMessage)
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
                else if (jsonData.errors === "")
                    setDialogText(GetStrLocalized(language, "thereIsnothingToNotify"))
                else
                    setDialogText("")

                if (jsonData.errors === "")
                    jsonData.errors = []

                setErrorMessages(jsonData.errors)
                setShowSanityCheckModal(true)
            })
            .catch(rejected => {
                setProcessingModifications(false)
                hcContext.setBlackCoverOfContent(false)
                hcContext.setInfo(createErrorModalContent(language, rejected.message))
            });
    }


    function generateSentencesForOnsemTests() {
        if (!hcContext.userToken)
            return
        setProcessingModifications(true)
        hcContext.setBlackCoverOfContent(true)
        const postContent = new AnyPostContentType(language, hcContext.userToken)
        fetch(hcContext.backendUrl + "/generate_sentence_for_onsem_tests", {
            method: 'POST',
            body: JSON.stringify(postContent),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
            .then(res => res.json())
            .then(jsonData => {
                setProcessingModifications(false)
                hcContext.setBlackCoverOfContent(false)
                if (jsonData.errorMessage) {
                    hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
                    return
                }
            })
            .catch(rejected => {
                setProcessingModifications(false)
                hcContext.setBlackCoverOfContent(false)
                hcContext.setInfo(createErrorModalContent(language, rejected.message))
            });
    }

    function writeCccWithoutHtml() {
        if (!hcContext.userToken)
            return
        setProcessingModifications(true)
        hcContext.setBlackCoverOfContent(true)
        fetch(hcContext.backendUrl + "/write_ccc_without_html", {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
            .then(res => res.json())
            .then(jsonData => {
                setProcessingModifications(false)
                hcContext.setBlackCoverOfContent(false)
                if (jsonData.errorMessage) {
                    hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
                    return
                }
            })
            .catch(rejected => {
                setProcessingModifications(false)
                hcContext.setBlackCoverOfContent(false)
                hcContext.setInfo(createErrorModalContent(language, rejected.message))
            });
    }

    return (
        <PageContent>
            {
                !hcContext.userAsRightsToModifyTheContent &&
                <Navigate to={"/" + language} />
            }

            {
                processingModifications &&
                <Centered>
                    <div style={getWaitPopupStyle(true, 50)}>
                        <img src={image_wait} alt="wait" height="100%" width="100%" />
                    </div>
                </Centered>
            }

            <H1TitleId language={language} titleId="cms" />
            <br />
            <CategoriesBigButtons categories={categories} hcContext={hcContext} />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <H2TitleId language={language} titleId="veryAdvancedActions" />
            <br />

            <button onClick={regenerateSitemapXml} style={{ marginLeft: '30px' }} >
                <GetHtmlStrLocalized language={language} textId="regenerateTheSitemapXml" />
            </button>

            <button onClick={showSanityChecksResult} style={{ marginLeft: '30px' }}>
                <GetHtmlStrLocalized language={language} textId="sanityChecks" />
            </button>

            <button onClick={generateSentencesForOnsemTests} style={{ marginLeft: '30px' }}>
                <GetHtmlStrLocalized language={language} textId="generateSentencesForOnsemTests" />
            </button>

            <button onClick={writeCccWithoutHtml} style={{ marginLeft: '30px' }}>
                <GetHtmlStrLocalized language={language} textId="writeCccWithoutHtml" />
            </button>

            {/*
            <button onClick={() => fetch(hcContext.backendUrl + "/stress_test")} style={{ marginLeft: '30px' }}>
                Start stress test
            </button>
            */}

            <br />
            <br />
            <br />
            <a href={'https://holychatter.slack.com'} style={{ marginLeft: '30px' }}><GetHtmlStrLocalized language={language} textId="openSlack" /></a>

            <InformationModal language={language} show={showSanityCheckModal} setShow={setShowSanityCheckModal}
                isDarkTheme={hcContext.isDarkTheme}
                title={GetStrLocalized(language, "sanityChecks")} text={dialogText} texts={errorMessages} />
        </PageContent>
    )
}



export default CmsHome
