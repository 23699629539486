import image_wait from '../../assets/resources/custom/icons/wait.gif'
import React, { ChangeEvent, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import Button from '../button/Button'
import { ButtonType } from '../../props/buttondataprops'
import HorizontalSpace from '../util/HorizontalSpace'
import { HcContext, SelectEltProps } from '../../types/hccontext'
import Centered from '../util/Centered'
import EditionLabel from '../title/EditionLabel'
import H1Title from '../title/H1Title'
import { HcInput } from '../input/HcInput'
import YesNoModal from '../modal/YesNoModal'
import { FolderProps } from '../../props/folderprops'
import GetStrLocalized from '../../datas/GetStrLocalized'
import { getWaitPopupStyle } from '../util/GetWaitPopupStyle'
import { QuizEdition, QuizEditionProps, SuggestedAnswerProps } from '../../types/QuizEdition'
import { HcMessageInput } from '../input/HcMessageInput'
import SourceSelectWithUrl from '../source/SourceSelectWithUrl'
import Select from 'react-select';
import VerticalDisplay from '../util/VerticalDisplay'
import QuizAnswersListViewer from '../listview/QuizAnswersListViewer'
import { createErrorModalContent } from '../../props/ModalContentProps'


interface QuestionForQuizAdderProps {
	language: string;
	hcContext: HcContext;
	parentFolders: FolderProps[];
	currentFolderName?: string;
	lastPath: string;
};


class QuizAdditionPostContentType {
	language: string;
	userKey: string;
	parentFolders: FolderProps[];
	currentFolderName: string;
	value: QuizEditionProps;

	constructor(
		language: string,
		userKey: string,
		parentFolders: FolderProps[],
		currentFolderName: string,
		value: QuizEditionProps) {
		this.language = language;
		this.userKey = userKey;
		this.parentFolders = parentFolders;
		this.currentFolderName = currentFolderName;
		this.value = value;
	}
}

function QuestionForQuizAdder({ language, hcContext, parentFolders, currentFolderName = "", lastPath }: QuestionForQuizAdderProps) {

	const navigate = useNavigate()
	const [processingModifications, setProcessingModifications] = useState(false)
	const [modificationDone, setModificationDone] = useState(false)
	const [canBeValidated, setCanBeValidated] = useState(false)
	const [showCanCancelModifictionsModal, setShowCanCancelModifictionsModal] = useState(false)
	const [liturgicalTimePossibilities, setLiturgicalTimePossibilities] = useState<SelectEltProps[]>([])
	const [sourcesPossibilities, setSourcesPossibilities] = useState<SelectEltProps[]>([])
	const [nb, setNb] = useState<number | null>(null)
	const [name, setName] = useState<string>("")
	const [question, setQuestion] = useState<string>("")
	const [selectedSource, setSelectedSource] = useState<SelectEltProps | null>(null)
	const [sourceUrl, setSourceUrl] = useState<string>("")
	const [suggestedAnswers, setSuggestedAnswers] = useState<SuggestedAnswerProps[]>([])
	const [suggestedAnswersStr, setSuggestedAnswersStr] = useState("")
	const [rightAnswer, setRightAnswer] = useState<string>("")
	const [wrongAnswer, setWrongAnswer] = useState<string>("")
	const [liturgicalTime, setLiturgicalTime] = useState<string>("")
	const quizEdition = useMemo(() => new QuizEdition({
		nb, name, question, source: selectedSource,
		sourceUrl, suggestedAnswers, rightAnswer,
		wrongAnswer, liturgicalTime
	},
		setNb, setName, setQuestion, setSelectedSource, setSourceUrl,
		setSuggestedAnswers, setSuggestedAnswersStr, setRightAnswer,
		setWrongAnswer, setLiturgicalTime
	),
		[nb, name, question, selectedSource, sourceUrl, suggestedAnswers,
			rightAnswer, wrongAnswer, liturgicalTime])

	useEffect(() => {
		setModificationDone(quizEdition.value.nb !== null ||
			quizEdition.value.name !== "" ||
			quizEdition.value.question !== "" ||
			quizEdition.value.source !== null ||
			quizEdition.value.sourceUrl !== "" ||
			suggestedAnswersStr !== "" ||
			quizEdition.value.rightAnswer !== "" ||
			quizEdition.value.wrongAnswer !== "" ||
			quizEdition.value.liturgicalTime !== "")
		setCanBeValidated(
			quizEdition.value.name.length > 0 &&
			quizEdition.value.question.length > 0 &&
			quizEdition.value.rightAnswer.length > 0 &&
			quizEdition.value.wrongAnswer.length > 0 &&
			quizEdition.value.source !== null)
	}, [nb, name, question, selectedSource, sourceUrl, suggestedAnswers,
		rightAnswer, wrongAnswer, liturgicalTime,
		quizEdition.value]);

	const [fetched, setFetched] = useState(false)
	useEffect(() => {
		if (!fetched) {
			setFetched(true)
			fetch(hcContext.backendUrl + "/quiz_edition_get_page_json?l=" + language)
				.then(res => res.json())
				.then(jsonData => {
					if (jsonData.errorMessage) {
						hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
					} else {
						setLiturgicalTimePossibilities(jsonData.liturgicalTimePossibilities)
						setSourcesPossibilities(jsonData.sourcesPossibilities)
						hcContext.setDocumentTitle("Ajout d'une question pour le quiz")
					}
				})
				.catch(rejected => {
					hcContext.setInfo(createErrorModalContent(language, rejected.message))
				});
		}
	}, [fetched, hcContext, language])


	function sendQuizAddition() {
		if (!hcContext.userToken)
			return
		setProcessingModifications(true)
		hcContext.setBlackCoverOfContent(true)
		const postContent = new QuizAdditionPostContentType(language, hcContext.userToken, parentFolders,
			currentFolderName, quizEdition.value)
		var postContentStr = JSON.stringify(postContent)
		console.log("postContentStr: " + postContentStr)
		fetch(hcContext.backendUrl + "/add_quiz", {
			method: 'POST',
			body: postContentStr,
			headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
		})
			.then(res => res.json())
			.then(jsonData => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				if (jsonData.errorMessage)
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
				else
					navigate(lastPath)
			})
			.catch(rejected => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}


	return (
		<>
			{
				processingModifications &&
				<Centered>
					<div style={getWaitPopupStyle(true, 50)}>
						<img src={image_wait} alt="wait" height="100%" width="100%" />
					</div>
				</Centered>
			}
			<table width='100%'>
				<tbody>
					<tr>
						<td width={(30 + 15) * 2 + 45} style={{ paddingTop: 20 }}>
							{
								canBeValidated &&
								!processingModifications &&
								<button onClick={sendQuizAddition}>
									<GetHtmlStrLocalized language={language} textId="modify" />
								</button>
							}
						</td>
						<td>
							<H1Title>
								<EditionLabel paddingTop={0} language={language} textId="number" />&nbsp;
								<HcInput width='70px' value={quizEdition.value.nb?.toString()} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { quizEdition.setNb(Number(event.target.value)); }} />
								&nbsp; &nbsp;
								{
									quizEdition.value.name.length > 0 ?
										<EditionLabel paddingTop={0} language={language} textId="name" />
										:
										<EditionLabel paddingTop={0} language={language} textId="name" color="red" />
								}
								&nbsp;
								<HcInput width='500px' value={quizEdition.value.name} onChange={(e: ChangeEvent<HTMLInputElement>) => { quizEdition.setName(e.target.value); }} />
							</H1Title>
						</td>
						{
							(hcContext.userAsRightsToModifyTheContent && !processingModifications) ?
								<td width={(30 + 15) * 2 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
									<>
										{
											canBeValidated ?
												<>
													<Button btnData={{ type: ButtonType.YES, onClick: sendQuizAddition }} language={language} altId="modify" />
													<HorizontalSpace />
												</>
												:
												<>
													<HorizontalSpace width={30} />
													<HorizontalSpace />
												</>
										}
									</>
									<Button btnData={{ type: ButtonType.NO, onClick: () => modificationDone ? setShowCanCancelModifictionsModal(true) : navigate(lastPath) }} language={language} altId="cancel" />
									<HorizontalSpace />
								</td>
								:
								<td width={(30 + 15) * 2 + 45} className="hc-not-very-short-screen"></td>
						}
					</tr>
				</tbody>
			</table>
			<br />

			<div style={{ marginLeft: 20, marginRight: 20 }}>
				<table style={{ width: "100%" }}>
					<tbody>
						<tr>
							<td style={{ width: "50%", paddingRight: 20 }}>

								<HcMessageInput language={language} hcContext={hcContext} titleId="question" htmlText={quizEdition.value.question} setHtmlText={quizEdition.setQuestion} />
								<br />
								<br />
								<br />

								<SourceSelectWithUrl language={language} hcContext={hcContext}
									source={quizEdition.value.source} setSource={quizEdition.setSource}
									sourceUrl={quizEdition.value.sourceUrl} setSourceUrl={quizEdition.setSourceUrl}
									sourcesPossibilities={sourcesPossibilities} setSourcesPossibilities={setSourcesPossibilities} />
								<br />
								<br />
								<br />
								<QuizAnswersListViewer
									language={language}
									answers={quizEdition.value.suggestedAnswers}
									setAnswers={(t) => { quizEdition.setSuggestedAnswersFn(t) }}
									hcContext={hcContext}
								/>

							</td>
							<td style={{ width: "50%", paddingLeft: 20 }}>

								<HcMessageInput language={language} hcContext={hcContext} titleId="rightAnswer" htmlText={quizEdition.value.rightAnswer} setHtmlText={quizEdition.setRightAnswer} />
								<br />
								<br />
								<br />

								<HcMessageInput language={language} hcContext={hcContext} titleId="wrongAnswer" htmlText={quizEdition.value.wrongAnswer} setHtmlText={quizEdition.setWrongAnswer} />
								<br />
								<br />
								<br />

								<VerticalDisplay
									elts={[
										{
											component: <EditionLabel language={language} textId="liturgicalTime" />,
											width: '170px',
											paddingRight: '20px'
										},
										{
											component: <Select
												value={{ label: quizEdition.value.liturgicalTime, value: quizEdition.value.liturgicalTime }}
												onChange={(elt: SelectEltProps | null) => {
													if (elt !== null)
														quizEdition.setLiturgicalTime(elt.value)
												}}
												getOptionLabel={(elt: SelectEltProps) => elt.label}
												getOptionValue={(elt: SelectEltProps) => elt.value}
												options={liturgicalTimePossibilities}
												isClearable={false}
												backspaceRemovesValue={true}
												styles={hcContext.getStylesForSelect<SelectEltProps>()}
											/>
										}
									]}
								/>
							</td>
						</tr>
					</tbody>
				</table>

			</div>



			<YesNoModal language={language} show={showCanCancelModifictionsModal} setShow={setShowCanCancelModifictionsModal}
			    isDarkTheme={hcContext.isDarkTheme}
				title={GetStrLocalized(language, "cancellationConfirmation")}
				text={GetStrLocalized(language, "areYouSureToUndotheChanges")}
				onYes={() => navigate(lastPath)} />
		</>
	)
}

export default QuestionForQuizAdder