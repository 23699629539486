
export interface FolderEditionProps {
  nb: number | null;
  name: string;
  image: string;
  isHidden: boolean;
}


export class FolderEdition {
  value: FolderEditionProps;
  setNb: React.Dispatch<React.SetStateAction<number | null>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;

  constructor(
    value: FolderEditionProps,
    setNb: React.Dispatch<React.SetStateAction<number | null>>,
    setName: React.Dispatch<React.SetStateAction<string>>,
    setImage: React.Dispatch<React.SetStateAction<string>>,
    setIsHidden: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    this.value = value;
    this.setNb = setNb;
    this.setName = setName;
    this.setImage = setImage;
    this.setIsHidden = setIsHidden;
  }

  set(value: FolderEditionProps) {
    this.setNb(value.nb)
    this.setName(value.name)
    this.setImage(value.image)
    this.setIsHidden(value.isHidden)
  }
}
