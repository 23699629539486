
interface AlignRightProps {
    children: React.ReactNode;
}

function AlignRight({ children }: AlignRightProps) {

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-end'
            }}
        >
            {children}
        </div>
    )
}

export default AlignRight;