export interface InsideBookRangesProps {
    chapter: string;
    chapterEnd: string;
    verseBegin: string;
    verseEnd: string;
}

export function createEmptyInsideBookRangesProps(): InsideBookRangesProps {
	return { chapter: "", chapterEnd: "", verseBegin: "", verseEnd: "" }
}


export interface ReferenceEditionProps {
    type: string;

    // book attributes
    bookScope: string;
    book: string;
    insideBookRanges: InsideBookRangesProps[];
    linkFromCorpus: string;

    // site attributes
    url: string;

    // pretty print
    str: string;
};


export class ReferenceEdition {
    value: ReferenceEditionProps
    setType: React.Dispatch<React.SetStateAction<string>>
    setBookScope: React.Dispatch<React.SetStateAction<string>>
    setBook: React.Dispatch<React.SetStateAction<string>>
    setInsideBookRanges: React.Dispatch<React.SetStateAction<InsideBookRangesProps[]>>
    setLinkFromCorpus: React.Dispatch<React.SetStateAction<string>>
    setUrl: React.Dispatch<React.SetStateAction<string>>
    setStr: React.Dispatch<React.SetStateAction<string>>

    constructor(
        value: ReferenceEditionProps,
        setType: React.Dispatch<React.SetStateAction<string>>,
        setBookScope: React.Dispatch<React.SetStateAction<string>>,
        setBook: React.Dispatch<React.SetStateAction<string>>,
        setInsideBookRanges: React.Dispatch<React.SetStateAction<InsideBookRangesProps[]>>,
        setLinkFromCorpus: React.Dispatch<React.SetStateAction<string>>,
        setUrl: React.Dispatch<React.SetStateAction<string>>,
        setStr: React.Dispatch<React.SetStateAction<string>>
    ) {
        this.value = value;
        this.setType = setType;
        this.setBookScope = setBookScope;
        this.setBook = setBook;
        this.setInsideBookRanges = setInsideBookRanges;
        this.setLinkFromCorpus = setLinkFromCorpus;
        this.setUrl = setUrl;
        this.setStr = setStr;
    }

    set(value: ReferenceEditionProps) {
        this.setType(value.type)
        this.setBookScope(value.bookScope)
        this.setBook(value.book)
        this.setInsideBookRanges(value.insideBookRanges)
        this.setLinkFromCorpus(value.linkFromCorpus)
        this.setUrl(value.url)
        this.setStr(value.str)
    }
}