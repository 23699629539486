import React from "react"

interface RecommendationProps {
    children: React.ReactNode;
    isPrimary: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}


const Recommendation: React.FC<RecommendationProps> = ({ children, isPrimary, onClick }) => {

    return (
        <React.Fragment>
            {
                isPrimary ?
                    <button style={{ fontSize: '14px', lineHeight: '27px', margin: '5px', textAlign: 'start' }} onClick={onClick}>{children}</button>
                    :
                    <button style={{ color: '#2980B9', backgroundColor: 'white', fontSize: '14px', lineHeight: '27px', margin: '5px', textAlign: 'start' }} onClick={onClick}>{children}</button>
            }
        </React.Fragment>
    )
}

export default Recommendation



