import { Link } from "react-router-dom"
import image_house_over from '../../assets/resources/custom/images/house_over.png'
import image_house_out from '../../assets/resources/custom/images/house_out.png'
import { FolderProps } from "../../props/folderprops";


interface H1TitleBreadcrumbProps {
    language: string;
    parentFolders: FolderProps[];
    children: React.ReactNode;
}


const H1TitleBreadcrumb: React.FC<H1TitleBreadcrumbProps> = ({ language, parentFolders, children }) => {

    function onMouseOverHome(e: React.MouseEvent<HTMLImageElement>) {
        (e.target as HTMLImageElement).src = image_house_over;
    }

    function onMouseOutHome(e: React.MouseEvent<HTMLImageElement>) {
        (e.target as HTMLImageElement).src = image_house_out;
    }

    return (
        <div className='hc-text-align-center'>
            <ol className="breadcrumb" style={{ marginTop: 0, fontSize: 14 }}>
                {
                    parentFolders.length > 0 &&
                    parentFolders.map((item, index) => {
                        if (item.name === "") {
                            return <li key={"breadcrumb-elt-" + index}><Link to={"/" + language + "/" + item.path}><img onMouseOver={onMouseOverHome} onMouseOut={onMouseOutHome} src={image_house_out} alt="home" width="14px" height="14px" style={{ border: '0px'}} /></Link></li>
                        }
                        return <li key={"breadcrumb-elt-" + index} ><Link to={"/" + language + "/" + item.path}>{item.name}</Link></li>
                    })
                }
            </ol>

            <div>
                <h1 className='hc-h1-not-main-title'>{children}</h1>
            </div>
        </div>
    )
}

export default H1TitleBreadcrumb
