import React, { useCallback, useEffect, useRef } from 'react';
import './modal.scss';
import GetStrLocalized from '../../datas/GetStrLocalized';

interface ModalProps {
    language: string;
    children: React.ReactNode;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    show: boolean;
    isDarkTheme: boolean;
    hideCloseButton?: boolean;
    display?: string;
    contentWidth?: string | undefined;
};

function Modal({ language, children, setShow, show, isDarkTheme, hideCloseButton, display = "block", contentWidth = undefined }: ModalProps) {

    const contentWidthNotOptional = contentWidth !== undefined ? contentWidth :
        Math.min(700, window.innerWidth - 100)
    const modalRef = useRef<HTMLDivElement>(null);

    // Close modal when escape is pressed
    const KEY_NAME_ESC = 'Escape';
    const KEY_EVENT_TYPE = 'keyup';
    function useEscapeKey() {
        const handleEscKey = useCallback((event: KeyboardEvent) => {
            if (event.key === KEY_NAME_ESC) {
                setShow(false)
            }
        }, []);
        useEffect(() => {
            document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

            return () => {
                document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
            };
        }, [handleEscKey]);
    }
    useEscapeKey();

    useEffect(() => {
        const clickOutsideContent = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                setShow(false);
            }
        };
        window.addEventListener('click', clickOutsideContent);
        return () => {
            window.removeEventListener('click', clickOutsideContent);
        };
    }, []);

    return (
        <>
            {
                show &&
                <div ref={modalRef} className={`modal ${show ? 'active' : ''}`} style={{ display: display }}>
                    <div className={`modal__content ${isDarkTheme ? 'hc-dark-theme' : 'hc-light-theme'}`} style={{ width: contentWidthNotOptional }} >
                        {
                            !hideCloseButton && <span onClick={() => setShow(false)} className="modal__close" title={GetStrLocalized(language, "pressEscToClose")}>
                                &times;
                            </span>
                        }
                        {children}
                    </div>
                </div>
            }
        </>);
};

export default Modal;

interface PropsWithChildren {
    children: React.ReactNode;
}

export const ModalHeader: React.FC<PropsWithChildren> = props => {
    return <div className="modal__header">
        {props.children}
    </div>
}

export const ModalBody: React.FC<PropsWithChildren> = props => {
    return <div className="modal__body">
        {props.children}
    </div>
}

export const ModalFooter: React.FC<PropsWithChildren> = props => {
    return <div className="modal__footer">
        {props.children}
    </div>
}