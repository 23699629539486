import image_wait from '../../assets/resources/custom/icons/wait.gif'
import React, { ChangeEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import Button from '../button/Button'
import { ButtonType } from '../../props/buttondataprops'
import HorizontalSpace from '../util/HorizontalSpace'
import { HcContext } from '../../types/hccontext'
import Centered from '../util/Centered'
import EditionLabel from '../title/EditionLabel'
import H1Title from '../title/H1Title'
import { HcInput } from '../input/HcInput'
import { FolderProps } from '../../props/folderprops'
import { getWaitPopupStyle } from '../util/GetWaitPopupStyle'
import { FolderEdition, FolderEditionProps } from '../../types/FolderEdition'
import GetStrLocalized from '../../datas/GetStrLocalized'
import BigButtonWithTextAtBottom from '../bigButton/BigButtonWithTextAtBottom'
import { imgToFirebaseUrl2 } from '../util/imagefallbacks'
import SelectImageModal from '../modal/SelectImageModal'
import YesNoModal from '../modal/YesNoModal'
import { removeItem } from '../../util/removeItem'
import { createErrorModalContent } from '../../props/ModalContentProps'


interface FolderModifierProps {
	language: string;
	hcContext: HcContext;
	parentFolders: FolderProps[];
	currentFolderName: string;
	folderId: string;
	lastPath: string;
};

export class FolderModificationPostContentType {
	language: string;
	userKey: string;
	parentFolders: FolderProps[];
	currentFolderName: string;
	id: string;
	oldValue: FolderEditionProps;
	value: FolderEditionProps;

	constructor(
		language: string,
		userKey: string,
		parentFolders: FolderProps[],
		currentFolderName: string,
		id: string,
		oldValue: FolderEditionProps,
		value: FolderEditionProps) {
		this.language = language
		this.userKey = userKey
		this.parentFolders = parentFolders
		this.currentFolderName = currentFolderName
		this.id = id
		this.oldValue = oldValue
		this.value = value
	}
}

function FolderModifier({ language, hcContext, parentFolders, currentFolderName, folderId, lastPath }: FolderModifierProps) {

	const navigate = useNavigate()
	const [modificationDone, setModificationDone] = useState(false)
	const [canBeValidated, setCanBeValidated] = useState(false)
	const [processingModifications, setProcessingModifications] = useState(false)
	const [pageFound, setPageFound] = useState(false)
	const [showCanCancelModifictionsModal, setShowCanCancelModifictionsModal] = useState(false)
	const [showCanDeleteModifictionsModal, setShowCanDeleteModifictionsModal] = useState(false)
	const [folderEditionGetOld, setFolderEditionGetOld] = useState<FolderEditionProps>({
		nb: null, name: "", image: "", isHidden: false
	})
	const [nb, setNb] = useState<number | null>(null)
	const [name, setName] = useState<string>("")
	const [image, setImage] = useState<string>("")
	const [isHidden, setIsHidden] = useState<boolean>(false)
	const folderEdition = new FolderEdition({ nb, name, image, isHidden },
		setNb, setName, setImage, setIsHidden)
	const [showImageAdditionModal, setShowImageAdditionModal] = React.useState(false)


	useEffect(() => {
		if (pageFound) {
			if (folderId === "" ||
				JSON.stringify(folderEditionGetOld) !== JSON.stringify(folderEdition.value)) {
				setModificationDone(true)
				setCanBeValidated(
					folderEdition.value.name.length > 0)
			} else {
				setModificationDone(false)
				setCanBeValidated(false)
			}
		}
	}, [nb, name, image, isHidden]);

	useEffect(() => {
		if (folderId !== "") {
			fetch(hcContext.backendUrl + "/folder_edit_get_page_json?l=" + language + "&id=" + folderId)
				.then(res => res.json())
				.then(jsonData => {
					if (jsonData.errorMessage) {
						setPageFound(false)
						hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
						return
					}
					jsonData.folder.isHidden = jsonData.folder.isHidden === "true"
					setFolderEditionGetOld(jsonData.folder)
					folderEdition.set(jsonData.folder)
					hcContext.setDocumentTitle(jsonData.folder.name + " - Edition")
					setPageFound(true)
				})
				.catch(rejected => {
					setPageFound(false)
					hcContext.setInfo(createErrorModalContent(language, rejected.message))
				});
		}
		else {
			hcContext.setDocumentTitle("Ajout d'un dossier")
			setPageFound(true)
		}
	}, [])

	function sendFolderModification() {
		if (!hcContext.userToken)
			return
		setProcessingModifications(true)
		hcContext.setBlackCoverOfContent(true)
		const postContent = new FolderModificationPostContentType(language, hcContext.userToken,
			parentFolders, currentFolderName, folderId, folderEditionGetOld, folderEdition.value)
		var postContentStr = JSON.stringify(postContent)
		fetch(hcContext.backendUrl + "/modify_folder", {
			method: 'POST',
			body: postContentStr,
			headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
		})
			.then(res => res.json())
			.then(jsonData => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				if (jsonData.errorMessage)
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
				else
					navigate('/' + language + '/' + GetStrLocalized(language, "categoriesFolderName") + '/' + jsonData.id)
			})
			.catch(rejected => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	return (
		<>
			{pageFound ?
				<>
					{
						processingModifications &&
						<Centered>
							<div style={getWaitPopupStyle(true, 50)}>
								<img src={image_wait} alt="wait" height="100%" width="100%" />
							</div>
						</Centered>
					}
					<table width='100%'>
						<tbody>
							<tr>
								<td width={(30 + 15) * 2 + 45} style={{ paddingTop: 20 }}>
									{
										canBeValidated &&
										!processingModifications &&
										<button onClick={sendFolderModification}>
											<GetHtmlStrLocalized language={language} textId="modify" />
										</button>
									}
								</td>
								<td>
									<H1Title>
										<EditionLabel paddingTop={0} language={language} textId="number" />&nbsp;
										<HcInput width='70px' value={folderEdition.value.nb?.toString()} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { folderEdition.setNb(Number(event.target.value)); }} />
										&nbsp; &nbsp;
										{
											folderEdition.value.name.length > 0 ?
												<EditionLabel paddingTop={0} language={language} textId="name" />
												:
												<EditionLabel paddingTop={0} language={language} textId="name" color="red" />
										}
										&nbsp;
										<HcInput width='500px' value={folderEdition.value.name} onChange={(e: ChangeEvent<HTMLInputElement>) => { folderEdition.setName(e.target.value); }} />
									</H1Title>
								</td>
								{
									(hcContext.userAsRightsToModifyTheContent && !processingModifications) ?
										<td width={(30 + 15) * 2 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
											{
												(modificationDone || folderId === "") ?
													<>
														{
															canBeValidated ?
																<>
																	<Button btnData={{ type: ButtonType.YES, onClick: () => sendFolderModification() }} language={language} altId="modify" />
																	<HorizontalSpace />
																</>
																:
																<>
																	<HorizontalSpace width={30} />
																	<HorizontalSpace />
																</>
														}
													</>
													:
													<>
														<Button btnData={{ type: ButtonType.DELETE, onClick: () => setShowCanDeleteModifictionsModal(true) }} language={language} />
														<HorizontalSpace />
													</>
											}
											<Button btnData={{ type: ButtonType.NO, onClick: () => modificationDone ? setShowCanCancelModifictionsModal(true) : navigate(lastPath) }} language={language} altId="cancel" />
											<HorizontalSpace />
										</td>
										:
										<td width={(30 + 15) * 2 + 45} className="hc-not-very-short-screen"></td>
								}
							</tr>
						</tbody>
					</table>
					<br />

					<div style={{ marginLeft: 20, marginRight: 20 }}>

						<table style={{ width: "100%" }}>
							<tbody>
								<tr>
									<td style={{ width: "50%", paddingRight: 20 }}>
										<table>
											<tbody>
												<tr>
													<td>
														{
															folderEdition.value.image.length > 0 ?
																<EditionLabel paddingTop={0} language={language} textId="image" />
																:
																<EditionLabel paddingTop={0} language={language} textId="image" color="red" />
														}
													</td>
													<td style={{ textAlign: 'center', width: '100%' }}></td>
													<td style={{ paddingRight: '15px' }}>
														{
															folderEdition.value.image.length === 0 ?
																<Button btnData={{
																	type: ButtonType.ADD, onClick: () => {
																		setShowImageAdditionModal(true)
																	}
																}} language={language} />
																:
																<Button btnData={{
																	type: ButtonType.EDIT, onClick: () => {
																		setShowImageAdditionModal(true)
																	}
																}} language={language} />
														}
													</td>
												</tr>
											</tbody>
										</table>

										&nbsp; &nbsp; &nbsp;
										{
											folderEdition.value.image.length > 0 &&
											<BigButtonWithTextAtBottom link="" image={imgToFirebaseUrl2("buttons/" + folderEdition.value.image)} duration="" title={folderEdition.value.image} tags=""
												beginOfClassName="hc-big-button-normal" onClick={() => { }} hcContext={hcContext} />
										}
										<br />
										<br />
									</td>
									<td style={{ width: "50%", paddingLeft: 20 }}>
									</td>
								</tr>
							</tbody>
						</table>
						<br />
						<br />
						<br />

						<div style={{ display: 'flex', alignItems: 'flex-end' }}>
							<EditionLabel language={language} textId="isHidden" />
							&nbsp; &nbsp; &nbsp;
							<input type="checkbox" checked={folderEdition.value.isHidden} onChange={(event: ChangeEvent<HTMLInputElement>) => {
								folderEdition.setIsHidden(event.target.checked)
							}
							} />
							<br />
						</div>
					</div>
				</>
				:
				<>
					<br />
					<br />
					<div className='hc-content-text' style={{ textAlign: 'center', margin: '0 auto' }}>
						<GetHtmlStrLocalized language={language} textId="sorryThisPageIsNotAvailable" />
					</div>
				</>
			}

			<YesNoModal language={language} show={showCanCancelModifictionsModal} setShow={setShowCanCancelModifictionsModal}
			    isDarkTheme={hcContext.isDarkTheme}
				title={GetStrLocalized(language, "cancellationConfirmation")}
				text={GetStrLocalized(language, "areYouSureToUndotheChanges")}
				onYes={() => navigate(lastPath)} />

			<SelectImageModal show={showImageAdditionModal} setShow={setShowImageAdditionModal}
				language={language}
				beginOfPathImage="buttons"
				onImageSelected={(s: string) => {
					folderEdition.setImage(s)
				}}
				hcContext={hcContext} />

			<YesNoModal language={language} show={showCanDeleteModifictionsModal} setShow={setShowCanDeleteModifictionsModal}
			    isDarkTheme={hcContext.isDarkTheme}
				title={GetStrLocalized(language, "removalConfirmation")}
				text={GetStrLocalized(language, "areYouSureToPermanentlyDeletethisFolder")}
				onYes={() => removeItem(folderId, "folder", hcContext, language, setProcessingModifications, navigate)} />
		</>
	)
}

export default FolderModifier
