import GetStrLocalized from "../datas/GetStrLocalized"


function nbToString(nb: number): string {

    if (nb < 1000)
        return nb.toString()

    if (nb < 10000)
        return (nb / 1000).toFixed(1).toString() + "K"

    if (nb < 1000000)
        return (nb / 1000).toFixed(0).toString() + "K"

    if (nb < 10000000)
        return (nb / 1000000).toFixed(1).toString() + "M"

    return (nb / 1000000).toFixed(0).toString() + "M"
}


export function nbToViewPrint(nb: number | undefined, language: string): string {
    if (typeof nb !== 'number' || isNaN(nb) || nb === undefined || nb === 0)
        return ""

    if (nb === 1)
        return "1 " + GetStrLocalized(language, "view")

    return nbToString(nb) + " " + GetStrLocalized(language, "views")
}
