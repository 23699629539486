import './stars.scss';
import image_star_empty from '../../assets/resources/custom/icons/star_empty.png'
import { useEffect, useState } from 'react';
import { StarsStats, starsStatsToValue, valueToStarImage } from './starsUtil';


interface StarsDynamicProps {
	starsStats: StarsStats;
	size: string;
}

function StarsDynamic({ starsStats, size }: StarsDynamicProps) {

	const [starImg1, setStarImg1] = useState(image_star_empty)
	const [starImg2, setStarImg2] = useState(image_star_empty)
	const [starImg3, setStarImg3] = useState(image_star_empty)
	const [starImg4, setStarImg4] = useState(image_star_empty)
	const [starImg5, setStarImg5] = useState(image_star_empty)

	function refreshStarImages(val: number) {
		setStarImg1(valueToStarImage(0, val))
		setStarImg2(valueToStarImage(1, val))
		setStarImg3(valueToStarImage(2, val))
		setStarImg4(valueToStarImage(3, val))
		setStarImg5(valueToStarImage(4, val))
	}

	useEffect(() => {
		refreshStarImages(starsStatsToValue(starsStats))
	}, [starsStats])

	return (
		<div className={`stars-list stars-list-size-${size}`}>
			<img src={starImg1} alt={"Star 1"} className={`star-item star-item-size-${size}`} />
			<img src={starImg2} alt={"Star 2"} className={`star-item star-item-size-${size}`} />
			<img src={starImg3} alt={"Star 3"} className={`star-item star-item-size-${size}`} />
			<img src={starImg4} alt={"Star 4"} className={`star-item star-item-size-${size}`} />
			<img src={starImg5} alt={"Star 5"} className={`star-item star-item-size-${size}`} />
		</div>
	)
}

export default StarsDynamic