import React, { useEffect } from 'react'
import { useState } from 'react'
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal'
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized';
import Select from 'react-select';
import GetStrLocalized from '../../datas/GetStrLocalized';
import BigButtonWithTextAtBottom from '../bigButton/BigButtonWithTextAtBottom';
import { imgToFirebaseUrl2 } from '../util/imagefallbacks';
import InformationModal from './InformationModal';
import { HcContext, SelectEltProps } from '../../types/hccontext';


export enum TypeOfImageDisplay {
    BUTTON,
    SMALL,
    NORMAL
}

interface SelectImageModalProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    language: string;
    beginOfPathImage: string;
    onImageSelected: (s: string) => any;
    hcContext: HcContext;
    typesOfSelectionIndex?: number;
    typeOfImageDisplay?: TypeOfImageDisplay;
    titleId?: string;
}

interface ButtonImageProps {
    image: string;
    name: string;
};

function SelectImageModal({ show, setShow, language, beginOfPathImage, onImageSelected, hcContext,
    typesOfSelectionIndex = 0, typeOfImageDisplay = TypeOfImageDisplay.BUTTON, titleId = "selectAnImage" }: SelectImageModalProps) {

    const beginOfPathImageWithSlash = beginOfPathImage.endsWith("/") ? beginOfPathImage : (beginOfPathImage + "/")
    const firebaseUrlForSubCurrentPath = "https://console.firebase.google.com/u/0/project/holy-chatter/storage/holy-chatter.appspot.com/files/~2F" + beginOfPathImage
    const typesOfSelection = [{ value: "yourDevice", label: "yourDevice" }, { value: "existingImageOftheSite", label: "existingImageOftheSite" }]
    const [typeOfSelectionValue, setTypeOfSelectionValue] = useState<SelectEltProps | null>(typesOfSelection[typesOfSelectionIndex]);
    const [showImageAlreadyExitModal, setShowImageAlreadyExitModal] = useState(false)
    const [allExistingPrefixes, setAllExistingPrefixes] = useState<SelectEltProps[]>([])
    const [existingPrefixesValue, setExistingPrefixesValue] = useState<SelectEltProps | null>(null);

    const listExistingPrefixes = () => {
        fetch(hcContext.backendUrlNodeJS + '/firebase/listDirectories', {
            method: 'POST',
            body: JSON.stringify({
                path: beginOfPathImageWithSlash
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .then(jsonData => {
                var allExistingPrefixesArray = new Array<SelectEltProps>();
                allExistingPrefixesArray.push({ value: "", label: "" })

                jsonData.forEach((image: any) => {
                    console.log('prefixName: ' + image)
                    var newElt = { value: image, label: image }
                    allExistingPrefixesArray.push(newElt)
                })
                setAllExistingPrefixes(allExistingPrefixesArray)
            })
            .catch(rejected => {
                console.log("firebase/listDirectories throw an error: " + rejected)
            })
    }

    // Upload image states

    const [imageUpload, setImageUpload] = useState<File>()
    const uploadFile = async () => {
        if (!imageUpload)
            return

        try {
            const formData = new FormData();
            formData.append('file', imageUpload);
            formData.append('destinationPath', beginOfPathImageWithSlash + ((existingPrefixesValue) ? (existingPrefixesValue?.value + "/") : ""));

            const response = await fetch(hcContext.backendUrlNodeJS + '/upload', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                console.log('File uploaded successfully on NodedJS backend');
                onImageSelected(imageUpload.name)
                setShow(false)
            } else if (response.status == 409) {
                setShowImageAlreadyExitModal(true)
            } else {
                console.error('Error uploading file on NodedJS backend');
            }
        } catch (error) {
            console.error('Error uploading file on NodedJS backend', error);
        }
    }
    const [preview, setPreview] = useState<string | undefined>()
    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!imageUpload) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(imageUpload)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageUpload])


    // Select existing image state
    const [buttonsImgs, setButtonsImgs] = useState<ButtonImageProps[]>([])

    useEffect(() => {
        if (show && typeOfSelectionValue?.value === "existingImageOftheSite") {

            fetch(hcContext.backendUrlNodeJS + '/firebase/ls', {
                method: 'POST',
                body: JSON.stringify({
                    path: beginOfPathImageWithSlash
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .then(jsonData => {
                    let keys: ButtonImageProps[] = []
                    jsonData.forEach((image: any, index: number) => {
                        if (index >= 0 && index <= 10000 && !image.name.endsWith("/")) {
                            if (image.name.length > beginOfPathImageWithSlash.length) {
                                const subPath = image.name.substring(beginOfPathImageWithSlash.length)
                                if (!subPath.includes("/"))
                                  keys.push({ image: imgToFirebaseUrl2(image.name), name: subPath });
                              }
                        }
                    })
                    setButtonsImgs(keys)
                })
                .catch(rejected => {
                    console.log("firebase/ls throw an error: " + rejected)
                })
        }
    }, [show, typeOfSelectionValue, beginOfPathImageWithSlash]);

    return (
        <>
            <Modal
                language={language}
                show={show}
                setShow={setShow}
                isDarkTheme={hcContext.isDarkTheme}
                display='flex'
                contentWidth="90%"
            >
                <ModalHeader>
                    <h3><GetHtmlStrLocalized language={language} textId={titleId} /></h3>
                </ModalHeader>
                <ModalBody>
                    <b><GetHtmlStrLocalized language={language} textId="chooseFrom" /></b>
                    <Select
                        value={typeOfSelectionValue}
                        onChange={(elt: SelectEltProps | null) => {
                            setTypeOfSelectionValue(elt);
                        }}
                        getOptionValue={(elt: SelectEltProps) => elt.value}
                        getOptionLabel={(elt: SelectEltProps) => GetStrLocalized(language, elt.label)}
                        options={typesOfSelection}
                        styles={hcContext.getStylesForSelect<SelectEltProps>()}
                    />
                    For information images are stored here: <a href={firebaseUrlForSubCurrentPath} target="_blank" rel="noreferrer">{firebaseUrlForSubCurrentPath}</a>
                    <br />



                    {
                        typeOfSelectionValue?.value === "yourDevice" ?
                            <>
                                <br />
                                <input type="file" onChange={(event) => {
                                    if (event.target.files !== null &&
                                        event.target.files?.length > 0) {
                                        setImageUpload(event.target.files[0])
                                        listExistingPrefixes()
                                    }
                                }} />
                                {
                                    imageUpload &&
                                    <img className={"hc-big-button-normal-width hc-big-button-normal-height hc-not-selectable"}
                                        style={{ objectFit: 'cover', borderRadius: 10, borderWidth: 0 }} src={preview} alt="upload preview" />
                                }


                                {
                                    imageUpload &&
                                    allExistingPrefixes.length > 0 &&
                                    <Select
                                        value={existingPrefixesValue}
                                        onChange={(elt: SelectEltProps | null) => {
                                            setExistingPrefixesValue(elt);
                                        }}
                                        getOptionValue={(elt: SelectEltProps) => elt.value}
                                        getOptionLabel={(elt: SelectEltProps) => elt.label}
                                        options={allExistingPrefixes}
                                        styles={hcContext.getStylesForSelect<SelectEltProps>()}
                                    />
                                }


                            </>
                            :
                            <>
                                {
                                    buttonsImgs.map((item, index) => {
                                        return (<span key={"btn-ref-" + index}>

                                            {
                                                typeOfImageDisplay === TypeOfImageDisplay.BUTTON &&
                                                <BigButtonWithTextAtBottom link="" image={item.image} duration="" title={item.name} tags=""
                                                    beginOfClassName="hc-big-button-normal"
                                                    onClick={() => {
                                                        onImageSelected(item.name)
                                                        setShow(false)
                                                    }
                                                    } hcContext={hcContext} />
                                            }

                                            {
                                                typeOfImageDisplay === TypeOfImageDisplay.SMALL &&
                                                <img src={item.image} height={20} style={{ paddingTop: 30, paddingRight: 10, cursor: 'pointer' }}
                                                    onClick={() => {
                                                        onImageSelected(item.name)
                                                        setShow(false)
                                                    }} alt="small view" />
                                            }

                                            {
                                                typeOfImageDisplay === TypeOfImageDisplay.NORMAL &&
                                                <img src={item.image} style={{ maxHeight: '150px', maxWidth: '400px', paddingTop: 30, paddingRight: 10, cursor: 'pointer' }}
                                                    onClick={() => {
                                                        onImageSelected(item.name)
                                                        setShow(false)
                                                    }} alt="normal view" />
                                            }

                                        </span>);
                                    })
                                }
                            </>
                    }


                </ModalBody>
                {
                    typeOfSelectionValue?.value === "yourDevice" &&
                    imageUpload &&
                    <ModalFooter>
                        <button onClick={uploadFile}>
                            <GetHtmlStrLocalized language={language} textId="validate" />
                        </button>
                    </ModalFooter>
                }
            </Modal>

            <InformationModal language={language} show={showImageAlreadyExitModal} setShow={setShowImageAlreadyExitModal}
                isDarkTheme={hcContext.isDarkTheme}
                title={GetStrLocalized(language, "error")} text={GetStrLocalized(language, "sorryThereIsAlreadyAnImageWithThatName")} />
        </>
    );
}

export default SelectImageModal;