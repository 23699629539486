import React, { useEffect } from 'react'
import { useState } from 'react'
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal'
import Select from 'react-select';
import { HcContext, SelectEltProps } from '../../types/hccontext';


interface TagEditionModalProps {
    language: string;
    addOrEdit: boolean;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    hcContext: HcContext;
    tagText: string;
    onTagValidated: (r: string) => any;
    currentTags: string[];
    allPossibleTags: string[];
}


function TagEditionModal({ language, addOrEdit, show, setShow, hcContext, tagText, onTagValidated, currentTags, allPossibleTags }: TagEditionModalProps) {
    const [isValid, setIsValid] = useState<boolean>(false)
    const [selectedElt, setSelectedElt] = useState<SelectEltProps | null>(null)
    const [allPossibleTagsForSelect, setAllPossibleTagsForSelect] = useState<SelectEltProps[]>([])

    useEffect(() => {
        if (show) {
            var allPossibleTagsArray = new Array<SelectEltProps>();
            var validity = false
            allPossibleTags.forEach((elt) => {
                if (elt === tagText) {
                    var newElt = { value: elt, label: elt }
                    allPossibleTagsArray.push(newElt)
                    setSelectedElt(newElt)
                    validity = true
                } else if (currentTags.find((currTag) => { return currTag === elt ? currTag : undefined }) == undefined) {
                    allPossibleTagsArray.push({ value: elt, label: elt })
                }
            })
            setAllPossibleTagsForSelect(allPossibleTagsArray)
            if (validity) {
                setIsValid(true)
            } else {
                setSelectedElt(null)
                setIsValid(false)
            }
        }
    }, [show]);

    return (
        <Modal
            language={language}
            show={show}
            setShow={setShow}
            isDarkTheme={hcContext.isDarkTheme}
        >
            <ModalHeader>
                <h3><GetHtmlStrLocalized language={language} textId={addOrEdit ? "addATag" : "modifyATag"} /></h3>
            </ModalHeader>
            <ModalBody>
                <Select
                    value={selectedElt}
                    onChange={(elt: SelectEltProps | null) => {
                        setSelectedElt(elt)
                        setIsValid(elt != null)
                    }}
                    getOptionLabel={(elt: SelectEltProps) => elt.label}
                    getOptionValue={(elt: SelectEltProps) => elt.value}
                    options={allPossibleTagsForSelect}
                    styles={hcContext.getStylesForSelect<SelectEltProps>()}
                />
            </ModalBody>
            <br />
            <ModalFooter>
                {
                    isValid &&
                    <button onClick={() => {
                        if (selectedElt !== null && selectedElt.value !== "")
                            onTagValidated(selectedElt.value)
                        setShow(false)
                    }}>
                        <GetHtmlStrLocalized language={language} textId="validate" />
                    </button>
                }
            </ModalFooter>
        </Modal>
    );
}

export default TagEditionModal;