import { Model } from "vosk-browser";

export interface ModelWithPathProps {
    model: Model;
    path: string;
}

export class ModelWithPath {
    loadedModel: ModelWithPathProps | undefined;
    setLoadedModel: React.Dispatch<React.SetStateAction<ModelWithPathProps | undefined>>;

    constructor(
        loadedModel: ModelWithPathProps | undefined,
        setLoadedModel: React.Dispatch<React.SetStateAction<ModelWithPathProps | undefined>>) {
        this.loadedModel = loadedModel;
        this.setLoadedModel = setLoadedModel;
    }
}
