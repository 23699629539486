import image_wait from '../assets/resources/custom/icons/wait.gif'
import React, { ChangeEvent, useEffect, useState } from "react"
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom"
import H1Title from '../components/title/H1Title'
import PageContent from '../components/util/PageContent'
import GetStrLocalized from '../datas/GetStrLocalized'
import { HcContext, SizeOfWindow } from '../types/hccontext'
import HorizontalSpace from '../components/util/HorizontalSpace'
import Button from '../components/button/Button'
import { ButtonType } from '../props/buttondataprops'
import { FactDescAndFollowingFacts, continuationTextDescriptionPropsToStr, emptyFactDescAndFollowingFacts, factOrTextToStr, strListToStr } from '../props/FactsProps'
import { DisplayPannerFact } from '../components/fact/DisplayPlannerFact'
import { DisplayPreconditionFact } from '../components/fact/DisplayPreconditionFact'
import { DisplayPannerFactWithLink } from '../components/fact/DisplayPlannerFactWithLink'
import { FactEdition, FactEditionProps } from '../types/FactEdition'
import GetHtmlStrLocalized from '../datas/GetHtmlStrLocalized'
import EditionLabel from '../components/title/EditionLabel'
import { HcInput } from '../components/input/HcInput'
import Centered from '../components/util/Centered'
import { getWaitPopupStyle } from '../components/util/GetWaitPopupStyle'
import { HCFileTypeIcon } from '../components/icon/HCFileTypeIcon'
import { createErrorModalContent } from '../props/ModalContentProps'


export class FactModificationPostContentType {
	language: string;
	userKey: string;
	id: string;
	newId: string;
	preconditionFactsStr: string;
	triggersStr: string;
	continuationsStr: string;
	commentaries: string;

	constructor(
		language: string,
		userKey: string,
		id: string,
		newId: string,
		preconditionFactsStr: string,
		triggersStr: string,
		continuationsStr: string,
		commentaries: string) {
		this.language = language;
		this.userKey = userKey;
		this.id = id;
		this.newId = newId;
		this.preconditionFactsStr = preconditionFactsStr;
		this.triggersStr = triggersStr;
		this.continuationsStr = continuationsStr;
		this.commentaries = commentaries;
	}
}

export class FactRemovalPostContentType {
	language: string;
	userKey: string;
	id: string;

	constructor(
		language: string,
		userKey: string,
		id: string) {
		this.language = language;
		this.userKey = userKey;
		this.id = id;
	}
}

interface MessageForAFactProps {
	itemType: string;
	fileType: string;
	name: string;
	id: string;
	potentialPreconditions: string;
	preconditions: string;
	potentialEffects: string;
	effects: string;
	goalsToAdd: string;
}


interface FactInfosProps {
	name: string;
	factDescAndFollowingFacts: FactDescAndFollowingFacts;
	messages: MessageForAFactProps[];
}


interface FactProps {
	language: string;
	setDocumentTitle: React.Dispatch<React.SetStateAction<string>>;
	backendUrl: string;
	hcContext: HcContext;
}



function Fact({ language, setDocumentTitle, backendUrl, hcContext }: FactProps) {

	useEffect(() => {
		setDocumentTitle(GetStrLocalized(language, "factsForChatbotPlannification") + " - Holy Chatter");
	}, [setDocumentTitle, language]);

	const navigate = useNavigate();
	const location = useLocation()
	const [lastPath, setLastPath] = useState("")
	const [lastSearch, setLastSearch] = useState("")

	const [request, setRequest] = useState<FactInfosProps>({ name: "", factDescAndFollowingFacts: emptyFactDescAndFollowingFacts, messages: [] })
	const [modificationDone, setModificationDone] = useState(false)
	const [processingModifications, setProcessingModifications] = useState(false)

	const [factEditiontOld, setFactEditiontOld] = useState<FactEditionProps>({
		name: "", preconditionFactsStr: "", triggersStr: "", continuationsStr: "",
		commentaries: ""
	})
	const [name, setName] = useState<string>("")
	const [preconditionFactsStr, setPreconditionFactsStr] = useState<string>("")
	const [triggersStr, setTriggersStr] = useState<string>("")
	const [continuationsStr, setContinuationsStr] = useState<string>("")
	const [commentaries, SetCommentaries] = useState<string>("")
	const factEdition = new FactEdition({ name, preconditionFactsStr, triggersStr, continuationsStr, commentaries },
		setName, setPreconditionFactsStr, setTriggersStr, setContinuationsStr, SetCommentaries);

	useEffect(() => {
		if (JSON.stringify(factEditiontOld) !== JSON.stringify(factEdition.value)) {
			setModificationDone(true)
		} else {
			setModificationDone(false)
		}
	}, [name, preconditionFactsStr, triggersStr, continuationsStr, commentaries, factEditiontOld, factEdition.value])


	function refreshPage(factId: string) {
		fetch(backendUrl + "/fact_json?l=" + language + "&f=" + factId)
			.then(res => res.json())
			.then(jsonData => {
				if (jsonData.errorMessage) {
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
					return
				}
				if (jsonData.factDescAndFollowingFacts.preconditionFacts === "")
					jsonData.factDescAndFollowingFacts.preconditionFacts = []
				if (jsonData.factDescAndFollowingFacts.triggers === "")
					jsonData.factDescAndFollowingFacts.triggers = []
				if (jsonData.factDescAndFollowingFacts.continuationTexts === "")
					jsonData.factDescAndFollowingFacts.continuationTexts = []
				if (jsonData.messages === "")
					jsonData.messages = []
				setRequest(jsonData);
				const reqPreconditionFactsStr = factOrTextToStr(jsonData.factDescAndFollowingFacts.preconditionFacts)
				const reqTriggersStr = strListToStr(jsonData.factDescAndFollowingFacts.triggers)
				const reqContinuationsStr = continuationTextDescriptionPropsToStr(jsonData.factDescAndFollowingFacts.continuationTexts)
				setFactEditiontOld({
					name: jsonData.name, preconditionFactsStr: reqPreconditionFactsStr, triggersStr: reqTriggersStr,
					continuationsStr: reqContinuationsStr, commentaries: jsonData.factDescAndFollowingFacts.commentaries
				})
				setName(jsonData.name)
				setPreconditionFactsStr(reqPreconditionFactsStr)
				setTriggersStr(reqTriggersStr)
				setContinuationsStr(reqContinuationsStr)
				SetCommentaries(jsonData.factDescAndFollowingFacts.commentaries)
			})
			.catch(rejected => {
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	if (location.pathname !== lastPath || location.search !== lastSearch) {
		setLastPath(location.pathname)
		setLastSearch(location.search)
		const urlParams = new URLSearchParams(location.search);
		const factValue = urlParams.get('value')

		const foldersArray = location.pathname.split('/');
		if (foldersArray.length > 3) {
			const factId = foldersArray[3]
			const fact = factId + (factValue !== null ? "=" + factValue : "")
			refreshPage(fact)
		}
	}

	function sendFactModification() {
		if (!hcContext.userToken)
			return
		setProcessingModifications(true)
		hcContext.setBlackCoverOfContent(true)
		const postContent = new FactModificationPostContentType(language, hcContext.userToken,
			factEditiontOld.name, name, preconditionFactsStr, triggersStr, continuationsStr, commentaries)
		var postContentStr = JSON.stringify(postContent)
		fetch(hcContext.backendUrl + "/modify_fact", {
			method: 'POST',
			body: postContentStr,
			headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
		})
			.then(res => res.json())
			.then(jsonData => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				if (jsonData.errorMessage) {
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
				} else {
					refreshPage(name)
					setModificationDone(false)
				}
			})
			.catch(rejected => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	function sendFactRemoval() {
		if (!hcContext.userToken)
			return
		setProcessingModifications(true)
		hcContext.setBlackCoverOfContent(true)
		const postContent = new FactRemovalPostContentType(language, hcContext.userToken, name)
		fetch(hcContext.backendUrl + "/remove_fact", {
			method: 'POST',
			body: JSON.stringify(postContent),
			headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
		})
			.then(res => res.json())
			.then(jsonData => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				if (jsonData.errorMessage) {
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
				} else {
					navigate('/' + language + '/' + GetStrLocalized(language, "factsFolderName"))
				}
			})
			.catch(rejected => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	function ModifyButton() {
		return (
			<>
				{
					modificationDone &&
					!processingModifications &&
					<button onClick={sendFactModification}>
						<GetHtmlStrLocalized language={language} textId="modify" />
					</button>
				}
			</>
		)
	}

	function DisplayMessageTableTitle() {
		return (
			<div>
				<table width="100%" style={{ marginTop: '20px', marginBottom: '20px' }}>
					<tbody>
						<tr>
							<td className='hc-content-text hc-content-text-paddings' width="16%">
								<b><GetHtmlStrLocalized language={language} textId="name" /></b>
							</td>
							<td className='hc-content-text hc-content-text-paddings' width="16%">
								<b><GetHtmlStrLocalized language={language} textId="preferInContext" /></b>
							</td>
							<td className='hc-content-text hc-content-text-paddings' width="16%">
								<b><GetHtmlStrLocalized language={language} textId="precondition" /></b>
							</td>
							<td className='hc-content-text hc-content-text-paddings' width="16%">
								<b><GetHtmlStrLocalized language={language} textId="possibleEffect" /></b>
							</td>
							<td className='hc-content-text hc-content-text-paddings' width="16%">
								<b><GetHtmlStrLocalized language={language} textId="effect" /></b>
							</td>
							<td className='hc-content-text hc-content-text-paddings' width="16%">
								<b><GetHtmlStrLocalized language={language} textId="goalsToAdd" /></b>
							</td>
						</tr>
					</tbody>
				</table>
				<hr style={{ height: 2, backgroundColor: '#333333', border: 'none' }} />
			</div>
		)
	}

	interface DisplayMessageLineProps {
		message: MessageForAFactProps;
	}

	function DisplayMessageLine({ message }: DisplayMessageLineProps) {
		return (
			<div style={{ fontWeight: 'bold' }}>
				<Link to={"/" + language + "/" + message.id} style={{ color: '#22292f', textDecoration: 'none' }}>
					<table width="100%" style={{ marginTop: '20px', marginBottom: '20px' }}>
						<tbody>
							<tr>
								<td className='hc-content-text hc-content-text-paddings' style={{ paddingLeft: '10px', paddingRight: '10px' }} width="16%">
									<HCFileTypeIcon fileType={message.fileType} />
									&nbsp; &nbsp; {message.name}
								</td>
								<td className='hc-content-text hc-content-text-paddings' style={{ paddingLeft: '10px', paddingRight: '10px' }} width="16%">
									{message.potentialPreconditions}
								</td>
								<td className='hc-content-text hc-content-text-paddings' style={{ paddingLeft: '10px', paddingRight: '10px' }} width="16%">
									{message.preconditions}
								</td>
								<td className='hc-content-text hc-content-text-paddings' style={{ paddingLeft: '10px', paddingRight: '10px' }} width="16%">
									{message.potentialEffects}
								</td>
								<td className='hc-content-text hc-content-text-paddings' style={{ paddingLeft: '10px', paddingRight: '10px' }} width="16%">
									{message.effects}
								</td>
								<td className='hc-content-text hc-content-text-paddings' style={{ paddingLeft: '10px', paddingRight: '10px' }} width="16%">
									{message.goalsToAdd}
								</td>
							</tr>

						</tbody>
					</table>
				</Link>
				{
					hcContext.sizeOfWindow === SizeOfWindow.WIDE &&
					<hr style={{ height: 1, backgroundColor: 'grey', border: 'none' }} />
				}
			</div>
		)
	}

	return (
		<PageContent>
			{
				!hcContext.userAsRightsToModifyTheContent &&
				<Navigate to={"/" + language} />
			}
			{
				processingModifications &&
				<Centered>
					<div style={getWaitPopupStyle(true, 50)}>
						<img src={image_wait} alt="wait" height="100%" width="100%" />
					</div>
				</Centered>
			}
			<table width='100%'>
				<tbody>
					<tr>
						<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
							<HorizontalSpace width={45} />
							<ModifyButton />
						</td>
						<td>
							<H1Title>
								<HcInput width='500px' value={factEdition.value.name} onChange={(e: ChangeEvent<HTMLInputElement>) => { factEdition.setName(e.target.value); }} />
							</H1Title>
						</td>

						<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
							{
								!processingModifications &&
								<>
									{
										modificationDone ?
											<>
												<Button btnData={{ type: ButtonType.YES, onClick: sendFactModification }} language={language} />
												<HorizontalSpace />
												<Button btnData={{ type: ButtonType.NO, onClick: () => refreshPage(factEditiontOld.name) }} language={language} />
												<HorizontalSpace />
											</>
											:
											<>
												<Button btnData={{ type: ButtonType.DELETE, onClick: sendFactRemoval }} language={language} />
												<HorizontalSpace />
											</>

									}
								</>
							}
						</td>
					</tr>
				</tbody>
			</table>

			<div style={{ padding: '15px' }}>
				{
					request.factDescAndFollowingFacts.preconditionFacts.length > 0 ?
						request.factDescAndFollowingFacts.preconditionFacts.map((preconditionFact, preconditionFactsIndex) => (
							<div key={`precondition-fact-${preconditionFactsIndex}`}>
								<DisplayPreconditionFact preconditionFact={preconditionFact} language={language} />&nbsp;=&gt;&nbsp;
								<DisplayPannerFact fact={request.factDescAndFollowingFacts.fact} />
								{
									request.factDescAndFollowingFacts.followingFacts.length > 0 &&
									request.factDescAndFollowingFacts.followingFacts.map((followingFact, followingFactIndex) => (
										<span key={`following-fact-${followingFactIndex}`}>
											{followingFactIndex === 0 ? " ~=> " : ", "}
											<DisplayPannerFactWithLink fact={followingFact} language={language} />
										</span>
									))
								}
							</div>
						))
						:
						<>
							<DisplayPannerFact fact={request.factDescAndFollowingFacts.fact} />
							{
								request.factDescAndFollowingFacts.followingFacts.length > 0 &&
								request.factDescAndFollowingFacts.followingFacts.map((followingFact, followingFactIndex) => (
									<span key={`following-fact-${followingFactIndex}`}>
										{followingFactIndex === 0 ? " ~=> " : ", "}
										<DisplayPannerFactWithLink fact={followingFact} language={language} />
									</span>
								))
							}
						</>
				}
				<br />
				<br />

				<EditionLabel language={language} textId="condition" />
				<br />
				<textarea className='form-control' style={{ width: "100%", height: "70px" }} value={preconditionFactsStr} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => { setPreconditionFactsStr(e.target.value); }} />
				<br />
				<br />
				<br />

				<EditionLabel language={language} textId="vocalTriggers" />
				<br />
				<textarea className='form-control' style={{ width: "100%", height: "70px" }} value={triggersStr} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => { setTriggersStr(e.target.value); }} />
				<br />
				<br />
				<br />

				<EditionLabel language={language} textId="messages" />
				{
					request.messages.length > 0 &&
					<>
						<DisplayMessageTableTitle />
						{
							request.messages.map((message, messageIndex) => {
								return (<DisplayMessageLine key={"message-" + messageIndex} message={message} />)
							})
						}
					</>
				}
				<br />
				<br />
				<br />

				<EditionLabel language={language} textId="continuations" />
				<br />
				<textarea className='form-control' style={{ width: "100%", height: "120px" }} value={continuationsStr} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => { setContinuationsStr(e.target.value); }} />
				<br />
				<br />
				<br />

				<EditionLabel language={language} textId="commentaries" />
				<br />
				<textarea className='form-control' style={{ width: "100%", height: "70px" }} value={commentaries} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => { SetCommentaries(e.target.value); }} />
				<br />
			</div>
		</PageContent>
	)
}

export default Fact;