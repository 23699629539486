import '../assets/resources/custom/style/sources-0.css'
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import PageContent from '../components/util/PageContent'
import H1TitleId from '../components/title/H1TitleId'
import BigButtonWithTextAtRight from '../components/bigButton/BigButtonWithTextAtRight'
import GetStrLocalized from '../datas/GetStrLocalized'
import SearchInput from '../components/banner/SearchInput'
import GetHtmlStrLocalized from '../datas/GetHtmlStrLocalized'
import { HcContext } from '../types/hccontext'
import MainSearchInput from '../components/banner/MainSearchInput'
import { createErrorModalContent } from '../props/ModalContentProps'


interface SearchProps {
	language: string;
	hcContext: HcContext;
	searchValue: string;
}


const Search: React.FC<SearchProps> = ({ language, hcContext, searchValue }) => {

	const location = useLocation();
	const [lastPath, setLastPath] = useState("")
	const [pageTitle, setPageTitle] = useState("")
	const [request, setRequest] = useState({ videos: [] })
	const [searching, setSearching] = useState(false)

	if (location.pathname !== lastPath) {
		setLastPath(location.pathname)
		setSearching(true)
		setRequest({ videos: [] })
		if (searchValue !== "") {
			setPageTitle(searchValue.replaceAll("%23", "#") + " - " + GetStrLocalized(language, "holyChatterSearch"));
			fetch(hcContext.backendUrl + "/search_content_json?l=" + language + "&q=" + searchValue.replaceAll("#", "@"))
				.then(res => res.json())
				.then(jsonData => {
					setSearching(false)
					if (jsonData.errorMessage) {
						hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
						return
					}
					setRequest(jsonData)
				})
				.catch(rejected => {
					setSearching(false)
					hcContext.setInfo(createErrorModalContent(language, rejected.message))
				});
		} else {
			setPageTitle(GetStrLocalized(language, "holyChatterSearch"));
		}
	}

	useEffect(() => {
		hcContext.setDocumentTitle(pageTitle);
	}, [hcContext, pageTitle]);

	return (
		<PageContent>
			<div className="hc-very-short-screen">
				<H1TitleId language={language} titleId="search" />
				<br />
				<MainSearchInput language={language} searchValue={searchValue} isDarkTheme={hcContext.isDarkTheme} />
				<br />
			</div>
			<br />
			<br />

			{
				request.videos.length > 0 ?
					<div className="hc-search-result-paddings">
						{
							request.videos.map((item, index) => {
								return <React.Fragment key={"video-search-result-" + index}>
									<div className="hc-inline-flex" >
										<BigButtonWithTextAtRight allowPreview={false} language={language} item={item} allowToDisplayLinkFromCorpus={hcContext.userAsRightsToModifyTheContent} hcContext={hcContext} />
									</div>
									<br />
									<br />
									<br />
								</React.Fragment>
							})
						}
					</div>
					:
					<div className='hc-content-text' style={{ textAlign: 'center', margin: '0 auto' }}>
						{
							searching ?
								<GetHtmlStrLocalized language={language} textId="currentyResearching" />
								:
								<GetHtmlStrLocalized language={language} textId="sorryWeDidNotFindAnyResults" />
						}
					</div>
			}



		</PageContent >
	)
}

export default Search;