import React from 'react'
import BigButtonWithTextAtBottom from './BigButtonWithTextAtBottom'
import { StarsStats } from '../star/starsUtil';
import { HcContext } from '../../types/hccontext';

export interface CategoryProps {
	nb: number | undefined;
	type: string;
	id: string;
	link: string;
	image: string;
	duration: string;
	title: string;
	subTitle: string;
	sourceImage: string;
	sourceName: string;
	stars: StarsStats | undefined;
}


interface CategoriesBigButtonsProps {
	categories: CategoryProps[];
	printNumber?: boolean;
	beginOfKey?: string;
	numberOfCategoriesToDisplay?: number;
	onClick?: (id: string) => void;
	hcContext: HcContext;
}


const CategoriesBigButtons: React.FC<CategoriesBigButtonsProps> = ({ categories, printNumber = false, beginOfKey = "", numberOfCategoriesToDisplay = 10000, onClick = null, hcContext }) => {

	return (
		<div className="hc-categories-left-margin">
			{
				categories !== null &&
				categories.length > 0 &&
				<>
					{
						categories.slice(0, numberOfCategoriesToDisplay).map((item, index) => {
							return (
								<BigButtonWithTextAtBottom key={beginOfKey + "categories-btn-" + index} link={onClick === null ? item.link : ""}
								    onClick={() => onClick !== null ? onClick(item.id) : {}} image={item.image}
									duration={item.duration} nb={printNumber ? item.nb?.toString() : undefined} title={item.title} tags={item.subTitle}
									descriptionImage={item.sourceImage} description={[item.sourceName]} beginOfClassName="hc-big-button-normal"
									stars={item.stars} hcContext={hcContext} />
							)
						})
					}
				</>
			}
		</div>
	)
}

export default CategoriesBigButtons