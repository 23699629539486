

export const chatbotAlreadyStartedCookieName = "chatbotAlreadyStarted"

export class ChatbotContext {
  started: boolean;
  setStarted: React.Dispatch<React.SetStateAction<boolean>>;
  hasAWaitingMessage: boolean;
  setHasAWaitingMessage: React.Dispatch<React.SetStateAction<boolean>>;
  isWindowOpen: boolean;
  setIsWindowOpen: React.Dispatch<React.SetStateAction<boolean>>;

  constructor(
    started: boolean,
    setStarted: React.Dispatch<React.SetStateAction<boolean>>,
    hasAWaitingMessage: boolean,
    setHasAWaitingMessage: React.Dispatch<React.SetStateAction<boolean>>,
    isWindowOpen: boolean,
    setIsWindowOpen: React.Dispatch<React.SetStateAction<boolean>>
    ) {
    this.started = started;
    this.setStarted = setStarted;
    this.hasAWaitingMessage = hasAWaitingMessage;
    this.setHasAWaitingMessage = setHasAWaitingMessage;
    this.isWindowOpen = isWindowOpen;
    this.setIsWindowOpen = setIsWindowOpen;
  }


}
