import { ReferenceEditionProps } from "../props/ReferenceEditionProps";
import { SelectEltProps } from "./hccontext";


export interface RecommendationEltProps {
  text: string;
  outMessageId: string;
}

export interface RecommendationsInfosProps {
  recommendations: RecommendationEltProps[];
  replanIfNoRecommendationHasBeenTriggered: string; // boolean
  skipRecommendationToMessagesAlreadySaid: string; // boolean
  allowToRepeat: string;
}


export function createEmptyRecommendationsInfosProps(): RecommendationsInfosProps {
  return {
    recommendations: [], replanIfNoRecommendationHasBeenTriggered: 'false',
    skipRecommendationToMessagesAlreadySaid: 'true', allowToRepeat: 'automatic'
  }
}

export interface PlannerActionProps {
  preferInContext: string;
  precondition: string;
  potentialEffect: string;
  effect: string;
  goalsToAdd: string;
}

export function createEmptyPlannerActionProps(): PlannerActionProps {
  return {
    preferInContext: "",
    precondition: "",
    potentialEffect: "",
    effect: "",
    goalsToAdd: ""
  }
}

export interface ArticleEditionProps {
  nb: number | null;
  name: string;
  html: string;
  isDeadLink: boolean;
  thumbnail: string;
  duration: string;
  tags: string[];
  source: SelectEltProps | null;
  sourceUrl: string;
  references: ReferenceEditionProps[];
  triggers: string[];
  nextMessageId: string;
  recommendationsInfos: RecommendationsInfosProps;
  plannerAction: PlannerActionProps;
  description: string;
  descriptions: string[];
}

export class ArticleEdition {
  value: ArticleEditionProps;
  setNb: React.Dispatch<React.SetStateAction<number | null>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setHtml: React.Dispatch<React.SetStateAction<string>>;
  setIsDeadLink: React.Dispatch<React.SetStateAction<boolean>>;
  setThumbnail: React.Dispatch<React.SetStateAction<string>>;
  setDuration: React.Dispatch<React.SetStateAction<string>>;
  private setTags: React.Dispatch<React.SetStateAction<string[]>>;
  private setTagsStr: React.Dispatch<React.SetStateAction<string>>;
  setSource: React.Dispatch<React.SetStateAction<SelectEltProps | null>>;
  setSourceUrl: React.Dispatch<React.SetStateAction<string>>;
  private setReferences: React.Dispatch<React.SetStateAction<ReferenceEditionProps[]>>;
  private setReferencesStr: React.Dispatch<React.SetStateAction<string>>;
  private setTriggers: React.Dispatch<React.SetStateAction<string[]>>;
  private setTriggersStr: React.Dispatch<React.SetStateAction<string>>;
  setNextMessageId: React.Dispatch<React.SetStateAction<string>>;
  private setRecommendationsInfos: React.Dispatch<React.SetStateAction<RecommendationsInfosProps>>;
  private setRecommendationsInfosStr: React.Dispatch<React.SetStateAction<string>>;
  private setPlannerAction: React.Dispatch<React.SetStateAction<PlannerActionProps>>;
  private setPlannerActionStr: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<string>>;

  constructor(
    value: ArticleEditionProps,
    setNb: React.Dispatch<React.SetStateAction<number | null>>,
    setName: React.Dispatch<React.SetStateAction<string>>,
    setHtml: React.Dispatch<React.SetStateAction<string>>,
    setIsDeadLink: React.Dispatch<React.SetStateAction<boolean>>,
    setThumbnail: React.Dispatch<React.SetStateAction<string>>,
    setDuration: React.Dispatch<React.SetStateAction<string>>,
    setTags: React.Dispatch<React.SetStateAction<string[]>>,
    setTagsStr: React.Dispatch<React.SetStateAction<string>>,
    setSource: React.Dispatch<React.SetStateAction<SelectEltProps | null>>,
    setSourceUrl: React.Dispatch<React.SetStateAction<string>>,
    setReferences: React.Dispatch<React.SetStateAction<ReferenceEditionProps[]>>,
    setReferencesStr: React.Dispatch<React.SetStateAction<string>>,
    setTriggers: React.Dispatch<React.SetStateAction<string[]>>,
    setTriggersStr: React.Dispatch<React.SetStateAction<string>>,
    setNextMessageId: React.Dispatch<React.SetStateAction<string>>,
    setRecommendationsInfos: React.Dispatch<React.SetStateAction<RecommendationsInfosProps>>,
    setRecommendationsInfosStr: React.Dispatch<React.SetStateAction<string>>,
    setPlannerAction: React.Dispatch<React.SetStateAction<PlannerActionProps>>,
    setPlannerActionStr: React.Dispatch<React.SetStateAction<string>>,
    setDescription: React.Dispatch<React.SetStateAction<string>>
  ) {
    this.value = value;
    this.setNb = setNb;
    this.setName = setName;
    this.setHtml = setHtml;
    this.setIsDeadLink = setIsDeadLink;
    this.setThumbnail = setThumbnail;
    this.setDuration = setDuration;
    this.setTags = setTags;
    this.setTagsStr = setTagsStr;
    this.setSource = setSource;
    this.setSourceUrl = setSourceUrl;
    this.setReferences = setReferences;
    this.setReferencesStr = setReferencesStr;
    this.setTriggers = setTriggers;
    this.setTriggersStr = setTriggersStr;
    this.setNextMessageId = setNextMessageId;
    this.setRecommendationsInfos = setRecommendationsInfos;
    this.setRecommendationsInfosStr = setRecommendationsInfosStr;
    this.setPlannerAction = setPlannerAction;
    this.setPlannerActionStr = setPlannerActionStr;
    this.setDescription = setDescription;
  }

  set(value: ArticleEditionProps) {
    this.setNb(value.nb)
    this.setName(value.name)
    this.setHtml(value.html)
    this.setIsDeadLink(value.isDeadLink)
    this.setThumbnail(value.thumbnail)
    this.setDuration(value.duration)
    this.setTagsFn([...value.tags])
    this.setSource(value.source)
    this.setSourceUrl(value.sourceUrl)
    this.setRefs(JSON.parse(JSON.stringify(value.references)))
    this.setTriggersFn([...value.triggers])
    this.setNextMessageId(value.nextMessageId)
    this.setRecommendationsInfosFn({ ...value.recommendationsInfos })
    this.setDescription(value.description)
  }

  setTagsFn(tags: string[]) {
    this.setTags(tags);
    this.setTagsStr(JSON.stringify(tags))
  }

  setRefs(refs: ReferenceEditionProps[]) {
    this.setReferences(refs);
    this.setReferencesStr(JSON.stringify(refs))
  }

  setTriggersFn(triggers: string[]) {
    this.setTriggers(triggers);
    this.setTriggersStr(JSON.stringify(triggers))
  }

  setRecommendationsInfosFn(recommendationsInfos: RecommendationsInfosProps) {
    this.setRecommendationsInfos(recommendationsInfos);
    this.setRecommendationsInfosStr(JSON.stringify(recommendationsInfos))
  }

  setPlannerActionFn(plannerAction: PlannerActionProps) {
    this.setPlannerAction(plannerAction);
    this.setPlannerActionStr(JSON.stringify(plannerAction))
  }
}
