import '../assets/resources/custom/style/main-6.css'
import PageContent from '../components/util/PageContent'
import H1TitleId from '../components/title/H1TitleId'
import { Navigate } from 'react-router-dom'
import { useEffect } from 'react'
import { HcContext } from '../types/hccontext'
import GetStrLocalized from '../datas/GetStrLocalized'
import CategoriesBigButtons, { CategoryProps } from '../components/bigButton/CategoriesBigButtons'


interface TestsProps {
    language: string;
    hcContext: HcContext;
}

function Tests({ language, hcContext }: TestsProps) {

    useEffect(() => {
        hcContext.setDocumentTitle("Tests - Holy Chatter");
    }, [language, hcContext])

	const categories: CategoryProps[] = [
        {nb: undefined, type: "", id: "", link: "/" + language + "/" + GetStrLocalized(language, "cmsFolderName") + "/" + GetStrLocalized(language, "testsFolderName") + "/" + GetStrLocalized(language, "resultsFolderName"), image: "/categories/tests.avif",
        duration: "", title: GetStrLocalized(language, "testsResults"), subTitle: "", sourceName: "", sourceImage: "", stars: undefined },
        {nb: undefined, type: "", id: "", link: "/" + language + "/" + GetStrLocalized(language, "cmsFolderName") + "/" + GetStrLocalized(language, "testsFolderName") + "/" + GetStrLocalized(language, "notOkFolderName"), image: "/categories/tests.avif",
        duration: "", title: GetStrLocalized(language, "testsNotOk"), subTitle: "", sourceName: "", sourceImage: "", stars: undefined }
   ]

    return (
        <PageContent>
            {
                !hcContext.userAsRightsToModifyTheContent &&
                <Navigate to={"/" + language} />
            }
            <H1TitleId language={language} titleId="tests" />
            <br />
            <CategoriesBigButtons categories={categories} hcContext={hcContext} />
            <br />
        </PageContent>
    )
}

export default Tests
