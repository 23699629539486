import React, { useState } from "react"
import { useLocation, useNavigate } from 'react-router-dom'
import H1Title from "../components/title/H1Title";
import H2Title from "../components/title/H2Title";
import PageContent from '../components/util/PageContent'
import ReadingContent from "../components/util/ReadingContent";
import GetStrLocalized from "../datas/GetStrLocalized";
import { VerseToDisplayProps } from "../props/versetodisplayprops";
import { HcContext } from "../types/hccontext";
import { createErrorModalContent } from "../props/ModalContentProps";

interface AReadingProps {
	title: string;
	subTitle: string;
	referenceUrl: string;
	reference: string;
	content: VerseToDisplayProps[];
}

interface ExternalLink {
	title: string;
	url: string;
}

interface MassProps {
	name: string;
	readings: AReadingProps[];
}

interface RequestProps {
	title: string;
	externalLinks: ExternalLink[];
	masses: MassProps[];
}

interface ReadingsOfTheDayProps {
	language: string;
	backendUrl: string;
	readingsType: string;
	hcContext: HcContext;
}

function ReadingsOfTheDay({ language, backendUrl, readingsType, hcContext }: ReadingsOfTheDayProps) {

	const location = useLocation();
	const [lastPath, setLastPath] = useState("");
	const [request, setRequest] = useState<RequestProps>({ title: "", externalLinks: [], masses: [] });
	const navigate = useNavigate();
	const [typeOfReadingsFolder, setTypeOfReadingsFolder] = useState("");

	if (location.pathname !== lastPath) {
		setLastPath(location.pathname);
		const foldersArray = location.pathname.split('/');
		if (foldersArray.length > 2)
			setTypeOfReadingsFolder(foldersArray[2]);
		var dateFolder = ""
		if (foldersArray.length > 3)
			dateFolder = foldersArray[3];
		fetch(backendUrl + "/readings_of_the_day_content_json?l=" + language + "&r=" + readingsType + "&d=" + dateFolder)
			.then(res => res.json())
			.then(jsonData => {
				if (jsonData.errorMessage) {
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
					return
				}
				setRequest(jsonData);
				var title = jsonData.title
				if (dateFolder === "")
					title = GetStrLocalized(language, readingsType === "gospel" ? "gospelOfTheDay" : "readingsOfTheDay")
					hcContext.setDocumentTitle(title + " - Holy Chatter");
			})
			.catch(rejected => {
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	return (
		<PageContent>
			<H1Title>{request.title}</H1Title>
			<br /><br />

			<div className='hc-content-text' style={{ paddingLeft: '55px' }}>
				Changer la date : <input type="date" id='select-date-id' onChange={e => navigate("/fr/" + typeOfReadingsFolder + "/" + e.target.value)} />
				<br /><br />
				{
					request &&
					request.externalLinks.length > 0 &&
					<React.Fragment>
						{
							request.externalLinks.map((externalLink, externalLinkIndex) => {
								return (
									<React.Fragment key={"external-link-" + externalLinkIndex}>
										<a href={externalLink.url}>{externalLink.title}</a>
										<br />
									</React.Fragment>)
							})
						}
					</React.Fragment>
				}
			</div>

			<br /><br />
			{
				request &&
				request.masses.length > 0 &&
				<React.Fragment>
					{
						request.masses.map((mass, massIndex) => {

							return (
								<div key={"mass-" + massIndex}>
									<H2Title>{mass.name}</H2Title>
									{
										mass &&
										mass.readings.length > 0 &&
										<React.Fragment>
											{
												mass.readings.map((reading, readingIndex) => {
													return (
														<React.Fragment key={"reading-" + massIndex + "-" + readingIndex}>
															<br />
															<div style={{ marginLeft: 40 }}>
																<div className='hc-content-text' style={{ paddingLeft: '15px' }}>
																	<b dangerouslySetInnerHTML={{ __html: reading.title }}></b>
																	<br />
																	<a href={reading.referenceUrl}>{reading.reference}</a>
																	<br />
																	<span dangerouslySetInnerHTML={{ __html: reading.subTitle }}></span>
																</div>
																<br /><br />
																<ReadingContent language={language} type="bible" content={reading.content} allowToDisplayLinkFromCorpus={hcContext.userAsRightsToModifyTheContent} hcContext={hcContext} />
															</div>
															<br />
														</React.Fragment>
													)
												})
											}
										</React.Fragment>
									}
								</div>
							)

						})
					}
				</React.Fragment>
			}

		</PageContent>
	)
}

export default ReadingsOfTheDay;