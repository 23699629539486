import { FactOrText } from "../../props/FactsProps";
import { DisplayPreconditionFact } from "./DisplayPreconditionFact";


interface DisplayPreconditionFactsProps {
    preconditionFacts: FactOrText[][];
    language: string;
}

export function DisplayPreconditionFacts({ preconditionFacts, language }: DisplayPreconditionFactsProps) {
    return (
        <>
            {
                preconditionFacts.length > 0 &&
                preconditionFacts.map((preconditionFact, preconditionFactsIndex) => (
                    <div key={`precondition-fact-${preconditionFactsIndex}`}>
                        <DisplayPreconditionFact preconditionFact={preconditionFact} language={language} />
                    </div>
                ))
            }
        </>
    )
}