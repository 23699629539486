
import '../assets/resources/custom/style/main-6.css'
import image_pret_a_chatter from '../assets/resources/custom/images/pret_a_chatter.png'
import image_facebook from '../assets/resources/custom/images/facebook.png'
import image_youtube from '../assets/resources/custom/images/youtube.png'
import React, { useEffect, useState } from 'react'
import GetInnerHtmlStrLocalized from '../datas/GetInnerHtmlStrLocalized'
import GetHtmlStrLocalized from '../datas/GetHtmlStrLocalized'
import GetStrLocalized from '../datas/GetStrLocalized'
import PageContent from '../components/util/PageContent'
import H1TitleId from '../components/title/H1TitleId'
import H2TitleId from '../components/title/H2TitleId'
import { Link, useLocation } from "react-router-dom"
import H2Title from '../components/title/H2Title'

interface AboutProps {
    language: string;
    setDocumentTitle: React.Dispatch<React.SetStateAction<string>>;
    backendUrl: string;
};


interface InFiguresProps {
    nbOfHtmlMessages: number;
    nbOfTextMessages: number;
    nbOfApiMessages: number;

    nbOfBibleReferences: number;
    nbOfCccReferences: number;
    nbOfSiteReferences: number;

    nbOfSources: number;
}

const About: React.FC<AboutProps> = ({ language, setDocumentTitle, backendUrl }) => {

    useEffect(() => {
        setDocumentTitle(GetStrLocalized(language, "about") + " - Holy Chatter");
    }, [setDocumentTitle, language]);

    const location = useLocation()
    const [lastPath, setLastPath] = useState("")
    const [lastSearch, setLastSearch] = useState("")
    const [infigures, setInfigures] = useState<InFiguresProps>({
        nbOfHtmlMessages: 0, nbOfTextMessages: 0, nbOfApiMessages: 0,
        nbOfBibleReferences: 0, nbOfCccReferences: 0, nbOfSiteReferences: 0,
        nbOfSources: 0
    })

	if (location.pathname !== lastPath || location.search !== lastSearch) {
		setLastPath(location.pathname)
		setLastSearch(location.search)

		fetch(backendUrl + "/in_numbers_json?l=" + language)
			.then(res => res.json())
			.then(jsonData => {
				setInfigures(jsonData)
			})
			.catch(rejected => {
				console.log(rejected)
			});
	}

    return (
        <PageContent>
            <H1TitleId language={language} titleId="about" />
            <br /><br />

            <div className='hc-content-text hc-content-text-paddings'>
                <GetInnerHtmlStrLocalized language={language} textId="aboutHolyChatterIntro" />
                <ul>
                    <li><GetInnerHtmlStrLocalized language={language} textId="aboutHolyChatterFirstPoint" />&nbsp;&nbsp;&nbsp;
                        <Link to={language === "fr" ? "/fr" : "/en"}><GetHtmlStrLocalized language={language} textId="link" /></Link>
                    </li>
                    <li><GetInnerHtmlStrLocalized language={language} textId="aboutHolyChatterSecondPoint" />&nbsp;&nbsp;&nbsp;
                        <Link to={language === "fr" ? "/fr/lectures/bible/Mt_5" : "/en/readings/bible/Mt_5"}><GetHtmlStrLocalized language={language} textId="link" /></Link>
                    </li>
                    <li><GetInnerHtmlStrLocalized language={language} textId="aboutHolyChatterThirdPoint" />&nbsp;&nbsp;&nbsp;
                        <Link to={language === "fr" ? "/fr/chatbot" : "/en/chatbot"}><GetHtmlStrLocalized language={language} textId="link" /></Link>
                    </li>
                </ul>
                <GetInnerHtmlStrLocalized language={language} textId="aboutHolyChatterContent" />
            </div>

            {
                language === "fr" &&
                <>
                    <br />
                    <br />
                    <div className='hc-content-text-paddings'>
                        <span className='hc-content-text' style={{ lineHeight: '20px', verticalAlign: 'top' }}>
                            PS: Vous avez un autre projet chrétien et vous voulez utiliser du contenu de Holy Chatter ? Consultez cette page :
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        <Link to={"/" + language + "/" + GetStrLocalized(language, "apiFolderName")} className="hc-api" title={GetStrLocalized(language, "accessTheApi")}>API</Link>
                    </div>

                    <br />
                    <br /><br />
                    <H2Title>Nous suivre sur les réseaux sociaux</H2Title>
                    <br />
                    <div className='hc-content-text hc-content-text-paddings'>
                        <span style={{ lineHeight: '30px', verticalAlign: 'top' }}>Page Facebook:</span>
                        &nbsp;&nbsp;&nbsp;
                        <a href="https://www.facebook.com/profile.php?id=100087443046272">
                            <img src={image_facebook} alt="Facebook logo" height="30" width="30" />
                        </a>
                        <br />
                        <span style={{ lineHeight: '30px', verticalAlign: 'top' }}>Chaîne YouTube:</span>
                        &nbsp;&nbsp;&nbsp;
                        <a href="https://www.youtube.com/c/HOLYChatterfr">
                            <img src={image_youtube} alt="YouTube logo" height="30" width="30" />
                        </a>
                        <br />
                    </div>
                </>
            }
            <br /><br />

            <H2TitleId language={language} titleId="inNumbers" />
            <div className='hc-content-text hc-content-text-paddings'>
                <br/>
                <b><GetHtmlStrLocalized language={language} textId="numberOfMessages" />{GetStrLocalized(language, "twoDots")}{Number(infigures.nbOfHtmlMessages) + Number(infigures.nbOfTextMessages) + Number(infigures.nbOfApiMessages)}<br/></b>
                <GetHtmlStrLocalized language={language} textId="numberOfVideos" />{GetStrLocalized(language, "twoDots")}{infigures.nbOfHtmlMessages}<br/>
                <GetHtmlStrLocalized language={language} textId="numberOfAnswersToQuestions" />{GetStrLocalized(language, "twoDots")}{infigures.nbOfTextMessages}<br/>
                <GetHtmlStrLocalized language={language} textId="numberOfExternalAPIs" />{GetStrLocalized(language, "twoDots")}{infigures.nbOfApiMessages}<br/>
                <br/>
                <b><GetHtmlStrLocalized language={language} textId="numberOfReferences" />{GetStrLocalized(language, "twoDots")}{Number(infigures.nbOfBibleReferences) + Number(infigures.nbOfCccReferences) + Number(infigures.nbOfSiteReferences)}<br/></b>
                <GetHtmlStrLocalized language={language} textId="numberOfBibleReferences" />{GetStrLocalized(language, "twoDots")}{infigures.nbOfBibleReferences}<br/>
                <GetHtmlStrLocalized language={language} textId="numberOfCccReferences" />{GetStrLocalized(language, "twoDots")}{infigures.nbOfCccReferences}<br/>
                <GetHtmlStrLocalized language={language} textId="numberOfExternalReferencesReferences" />{GetStrLocalized(language, "twoDots")}{infigures.nbOfSiteReferences}<br/>
                <br/>
                <b><GetHtmlStrLocalized language={language} textId="numberOfSources" />{GetStrLocalized(language, "twoDots")}{infigures.nbOfSources}</b>
            </div>

            <br /><br />
            <H2TitleId language={language} titleId="recipientsOfTheSite" />
            <br />
            <div className='hc-content-text hc-content-text-paddings'>
                <GetInnerHtmlStrLocalized language={language} textId="forWhoContent" />
                <Link to={"/" + language + "/" + GetStrLocalized(language, "chatbotFolderName")}><GetHtmlStrLocalized language={language} textId="readyToChat" /></Link>
            </div>

            {
                language === "fr" ?
                    <React.Fragment>
                        <br /><br />
                        <div className="hc-text-align-center" style={{ padding: 15 }}>
                            <Link to={"/" + language + "/" + GetStrLocalized(language, "christianMessageFolderName")}>
                                <img src={image_pret_a_chatter} alt="Prêt à chatter ?" className="hc-cursor-pointer" width='100%' style={{ maxWidth: 730, maxHeight: 730 }} />
                            </Link>
                        </div>
                    </React.Fragment> :
                    <React.Fragment />
            }
        </PageContent >
    )
}

export default About
