import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';



const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!!);
root.render(
  <>
    <BrowserRouter>
      <meta name="referrer" content="no-referrer" />
      <App />
    </BrowserRouter>
  </>,
);
