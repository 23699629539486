
export interface FactEditionProps {
  name: string;
  preconditionFactsStr: string;
  triggersStr: string;
  continuationsStr: string;
  commentaries: string;
}

export class FactEdition {
  value: FactEditionProps;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setPreconditionFactsStr: React.Dispatch<React.SetStateAction<string>>;
  setTriggersStr: React.Dispatch<React.SetStateAction<string>>;
  setContinuationsStr: React.Dispatch<React.SetStateAction<string>>;
  setCommentaries: React.Dispatch<React.SetStateAction<string>>;

  constructor(
    value: FactEditionProps,
    setName: React.Dispatch<React.SetStateAction<string>>,
    setPreconditionFactsStr: React.Dispatch<React.SetStateAction<string>>,
    setTriggersStr: React.Dispatch<React.SetStateAction<string>>,
    setContinuationsStr: React.Dispatch<React.SetStateAction<string>>,
    setCommentaries: React.Dispatch<React.SetStateAction<string>>,
  ) {
    this.value = value
    this.setName = setName
    this.setPreconditionFactsStr = setPreconditionFactsStr
    this.setTriggersStr = setTriggersStr
    this.setContinuationsStr = setContinuationsStr
    this.setCommentaries = setCommentaries
  }

  set(value: FactEditionProps) {
    this.setName(value.name)
    this.setPreconditionFactsStr(value.preconditionFactsStr)
    this.setTriggersStr(value.triggersStr)
    this.setContinuationsStr(value.continuationsStr)
    this.setCommentaries(value.commentaries)
  }
}
