import '../assets/resources/custom/style/sources-0.css'
import React, { useState } from "react"
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom"
import H1TitleBreadcrumb from '../components/title/H1TitleBreadcrumb'
import PageContent from '../components/util/PageContent'
import H2TitleId from '../components/title/H2TitleId'
import BigButtonsWithTextAtBottom from '../components/bigButton/BigButtonsWithTextAtBottom'
import GetStrLocalized from "../datas/GetStrLocalized"
import BubblesFromUser from '../components/chatbot/BubblesFromUser'
import { MessageProps } from '../props/messageprops'
import { BigButtonProps } from '../props/bigbuttonprops'
import NavigationArrows from '../components/navigation/NavigationArrows'
import HorizontalSpace from '../components/util/HorizontalSpace'
import { ButtonDataProps, ButtonType } from '../props/buttondataprops'
import GetHtmlStrLocalized from '../datas/GetHtmlStrLocalized'
import { HcContext } from '../types/hccontext'
import SourceModifier from '../components/modifier/SourceModifier'
import Button from '../components/button/Button'
import BigButtonWithTextAtBottom from '../components/bigButton/BigButtonWithTextAtBottom'
import { SourceCharacteristicsProps, SourceMessageCharacteristicsProps, emptySourceCharacteristics, isSourceMessageCharacteristicsEmpty } from '../props/SourceCharacteristicsProps'
import H2Title from '../components/title/H2Title'
import { createErrorModalContent } from '../props/ModalContentProps'

interface SiteProps {
	title: string;
	url: string;
}

interface RequestProps {
	name: string;
	iconPath: string;
	description: string;
	videos: BigButtonProps[];
	chatMsgs: MessageProps[];
	sites: SiteProps[];
	characteristics: SourceCharacteristicsProps;
}

interface SourceProps {
	language: string;
	setDocumentTitle: React.Dispatch<React.SetStateAction<string>>;
	backendUrl: string;
	hcContext: HcContext;
}


interface DisplaySourceCharacteristicsProps {
	language: string;
	characteristics: SourceMessageCharacteristicsProps;
}

function DisplaySourceCharacteristics({ language, characteristics }: DisplaySourceCharacteristicsProps) {
	return (
		<>
			{
				characteristics.nbOfMessages > 0 &&
				<><GetHtmlStrLocalized language={language} textId="numberOfMessages" />{GetStrLocalized(language, "twoDots")}{characteristics.nbOfMessages}<br /></>
			}
			{
				characteristics.nbOfTextualAnswers > 0 &&
				<><GetHtmlStrLocalized language={language} textId="numberOfTextualAnswers" />{GetStrLocalized(language, "twoDots")}{characteristics.nbOfTextualAnswers}<br /></>
			}
			{
				characteristics.nbOfBibleReferences > 0 &&
				<><GetHtmlStrLocalized language={language} textId="numberOfBibleReferences" />{GetStrLocalized(language, "twoDots")}{characteristics.nbOfBibleReferences}<br /></>
			}
			{
				characteristics.nbOfCccReferences > 0 &&
				<><GetHtmlStrLocalized language={language} textId="numberOfCccReferences" />{GetStrLocalized(language, "twoDots")}{characteristics.nbOfCccReferences}<br /></>
			}
			{
				characteristics.nbOfExternalWebsiteReferences > 0 &&
				<><GetHtmlStrLocalized language={language} textId="numberOfExternalReferencesReferences" />{GetStrLocalized(language, "twoDots")}{characteristics.nbOfExternalWebsiteReferences}<br /></>
			}
		</>
	)
}


function Source({ language, setDocumentTitle, backendUrl, hcContext }: SourceProps) {

	const navigate = useNavigate();
	const location = useLocation();
	const [lastPath, setLastPath] = useState("")
	const [lastSearch, setLastSearch] = useState("")
	const [displayAllMessages, setDisplayAllMessages] = useState(false)
	const [request, setRequest] = useState<RequestProps>({ name: "", iconPath: "", description: "", videos: [], chatMsgs: [], sites: [], characteristics: emptySourceCharacteristics })
	const [topArrow, setTopArrow] = useState<ButtonDataProps>({
		type: ButtonType.EMPTY,
		onClick: () => { }
	})
	const [sourceId, setSourceId] = useState("")
	const [editMode, setEditMode] = useState(false)

	if (location.pathname !== lastPath || location.search !== lastSearch) {
		setLastPath(location.pathname)
		setLastSearch(location.search)
		const urlParams = new URLSearchParams(location.search);
		const isEditMode = urlParams.has('edit')
		setEditMode(isEditMode)

		let restApi = backendUrl + "/source_page_json?l=" + language
		const foldersArray = location.pathname.split('/');
		if (foldersArray.length > 3) {
			setSourceId(foldersArray[3])
			restApi += "&id=" + foldersArray[3]
		}
		if (foldersArray.length > 4 && foldersArray[4] === "all") {
			restApi += "&maxNbOfResults=100000"
			setDisplayAllMessages(true)
			setTopArrow({
				type: ButtonType.TOP,
				onClick: () => navigate("/" + language + "/" + GetStrLocalized(language, "sourcesFolderName") + "/" + sourceId)
			})
		} else {
			restApi += "&maxNbOfResults=24"
			setDisplayAllMessages(false)
			setTopArrow({
				type: ButtonType.TOP,
				onClick: () => navigate("/" + language + "/" + GetStrLocalized(language, "sourcesFolderName"))
			})
		}

		fetch(restApi)
			.then(res => res.json())
			.then(jsonData => {
				if (jsonData.errorMessage) {
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
					return
				}
				setRequest(jsonData)
				setDocumentTitle(jsonData.name + " - Holy Chatter")
			})
			.catch(rejected => {
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	const parentFolders = [
		{
			path: GetStrLocalized(language, "sourcesFolderName"),
			name: 'Sources'
		}
	]

	function MessageSubTitle() {
		return (
			<>
				{
					request.videos.length > 0 &&
					<>
						{
							request.videos.length < request.characteristics.publicMessages.nbOfMessages ?
								<>
									<H2Title><GetHtmlStrLocalized language={language} textId="messages" /> ({request.videos.length} / {request.characteristics.publicMessages.nbOfMessages})</H2Title>
									<div className="hc-content-text hc-categories-left-margin">
										<br />
										<Link to={"/" + language + "/" + GetStrLocalized(language, "sourcesFolderName") + "/" + sourceId + "/all"}><GetHtmlStrLocalized language={language} textId="seeAllMessages" /> ({request.characteristics.publicMessages.nbOfMessages})</Link>
									</div>
								</>
								:
								<H2Title><GetHtmlStrLocalized language={language} textId="messages" /> ({request.characteristics.publicMessages.nbOfMessages})</H2Title>
						}
					</>
				}
			</>
		)
	}

	return (
		<PageContent>

			{
				editMode &&
				!hcContext.userAsRightsToModifyTheContent &&
				<Navigate to={lastPath} />
			}

			{
				editMode ?
					<SourceModifier language={language} hcContext={hcContext} sourceId={sourceId} lastPath={lastPath} displayValidationButtonsOnTop={true}
						onModified={(_, id) => navigate('/' + language + '/' + GetStrLocalized(language, "sourcesFolderName") + '/' + id)}
					/>
					:
					<>
						<div className='hc-not-very-short-screen'>
							<table width='100%'>
								<tbody>
									<tr>
										<td width={45 + (30 + 15) * 3} style={{ paddingTop: 20 }}>
											<HorizontalSpace width={45} />
											<NavigationArrows btnDatas={[topArrow]} language={language} />
										</td>
										<td>
											<H1TitleBreadcrumb language={language} parentFolders={parentFolders}>{request.name}</H1TitleBreadcrumb>
										</td>

										{
											hcContext.userAsRightsToModifyTheContent ?
												<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
													<Button btnData={{ type: ButtonType.EDIT, onClick: () => navigate("?edit") }} language={language} />
													<HorizontalSpace />
												</td>
												:
												<td width={45 + (30 + 15) * 3} />
										}
									</tr>
								</tbody>
							</table>
						</div>

						<div className='hc-very-short-screen'>
							<H1TitleBreadcrumb language={language} parentFolders={parentFolders}>{request.name}</H1TitleBreadcrumb>
						</div>


						<br /><br />

						{
							displayAllMessages ?
								<>
									<BigButtonsWithTextAtBottom videos={request.videos} hcContext={hcContext} />
								</>
								:
								<>
									<div style={{ width: '100%' }}>
										<table style={{ width: '100%' }}>
											<tbody>
												<tr>
													<td className="hc-message-viewer-width">
														<br />
														<div style={{ textAlign: 'center', paddingTop: '10px' }}>
															{
																request.iconPath !== undefined && request.iconPath !== "" &&
																<BigButtonWithTextAtBottom link="" image={request.iconPath} duration="" title="" tags="" beginOfClassName="hc-big-button-source" hcContext={hcContext} imageBorderRadius={100} />
															}
														</div>
														<br /><br /><br />
														<div className="hc-content-text" style={{ paddingLeft: '30px', paddingRight: '15px', paddingBottom: '15px' }}>
															{request.description}
															<br />
															<br />
															<Link to={"/" + language + "/" + GetStrLocalized(language, "searchFolderName") + "/source:" + sourceId}><GetHtmlStrLocalized language={language} textId="searchInThisSource" /></Link>
														</div>
														<br />

														<H2TitleId language={language} titleId="inNumbers" />
														<br />

														<div className="hc-content-text" style={{ paddingLeft: '30px', paddingRight: '15px', paddingBottom: '15px' }} >
															<DisplaySourceCharacteristics language={language} characteristics={request.characteristics.publicMessages} />
															{
																hcContext.userAsRightsToModifyTheContent &&
																!isSourceMessageCharacteristicsEmpty(request.characteristics.hiddenMessages) &&
																<>
																	<br />
																	<b><GetHtmlStrLocalized language={language} textId="hiddenForChatbotOnly" /></b><br />
																	<DisplaySourceCharacteristics language={language} characteristics={request.characteristics.hiddenMessages} />
																</>
															}
														</div>

														<br />
														<H2TitleId language={language} titleId="site" />
														<br />
														<div className="hc-content-text" style={{ paddingLeft: '30px', paddingRight: '15px', paddingBottom: '15px' }} >

															{
																request.sites &&
																request.sites.map((siteItem, siteIndex) => {
																	return (
																		<span key={'source-site-' + siteIndex} style={{ textAlign: 'left' }}>
																			<span style={{ textAlign: 'left', color: '#22292f' }}><a href={siteItem.url}>{siteItem.title}</a></span>
																			<br />
																			<br />
																			<br />
																		</span>
																	)
																})
															}


														</div>
													</td>
													<td className="hc-long-screen hc-reference-viewer-width" style={{ paddingBottom: '15px' }}>
														<MessageSubTitle />
														<div style={{ paddingTop: '13px' }}>
															<BigButtonsWithTextAtBottom videos={request.videos} hcContext={hcContext} />
															<BubblesFromUser language={language} titleId="relatedQuestions" chatMsgs={request.chatMsgs} />
														</div>
														<br />
													</td>
												</tr >
											</tbody >
										</table >
										<div className="hc-short-screen" style={{ textAlign: 'left', paddingBottom: '15px' }} >
											<br />
											<MessageSubTitle />
											<div className="hc-categories-left-margin" style={{ paddingTop: '13px' }}>
												<BigButtonsWithTextAtBottom videos={request.videos} hcContext={hcContext} />
												<BubblesFromUser language={language} titleId="relatedQuestions" chatMsgs={request.chatMsgs} />
											</div>
										</div>
									</div >
								</>
						}
					</>
			}


		</PageContent >
	)
}

export default Source;