
export const minWindowSizeForNavBarAlawaysOpened=2400


const OpenNav = () => {
    var hcSideBarTextElements = document.getElementsByClassName("hc-sidebar-text");
    Array.prototype.filter.call(hcSideBarTextElements, function (hcSideBarTextElement) {
        return hcSideBarTextElement.style.display = 'inline-block';
    });
    const hcSidenavIdEltId = document.getElementById("hcSidenavId")
    if (hcSidenavIdEltId != null)
        hcSidenavIdEltId.style.width = "250px";
}

export default OpenNav
