

export function getWaitPopupStyle(topOrBottom: boolean, offset: number): React.CSSProperties {
    return {
      position: 'fixed',
      top: topOrBottom ? offset : '',
      bottom: topOrBottom ? '' : offset,
      width: '150px',
      height: '150px',
      padding: '20px',
      zIndex: 1000,
      backgroundColor: 'white'
    }
  }
