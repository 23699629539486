import { useRef, useState } from "react";
import EditionLabel from "../title/EditionLabel";
import Button from "../button/Button";
import { ButtonType } from "../../props/buttondataprops";
import SelectImageModal, { TypeOfImageDisplay } from "../modal/SelectImageModal";
import { imgToFirebaseUrl2 } from "../util/imagefallbacks";
import { HcContext } from "../../types/hccontext";

interface HcMessageInputProps {
    language: string;
    hcContext: HcContext;
    titleId: string;
    htmlText: string;
    setHtmlText: React.Dispatch<React.SetStateAction<string>>;
};


export function HcMessageInput({ language, hcContext, titleId, htmlText, setHtmlText }: HcMessageInputProps) {
    const [editHtml, setEditHtml] = useState(true)
    const messageTextAreaRef = useRef<HTMLTextAreaElement>(null)
    const [showEmojiSelectionModal, setShowEmojiSelectionModal] = useState(false)
    const [showGifSelectionModal, setShowGifSelectionModal] = useState(false)

    function addBeginAndEndingBeaconOnSelectedText(beaconName: string) {

        // Obtain the object reference for the <textarea>
        const txtarea = messageTextAreaRef.current;
        // Obtain the index of the first selected character
        const start = txtarea?.selectionStart;
        // Obtain the index of the last selected character
        const finish = txtarea?.selectionEnd;
        const text = messageTextAreaRef.current?.value

        // Obtain the selected text
        if (start && finish && text) {
            const beforeSelection = text.substring(0, start)
            const selectedText = text.substring(start, finish)
            const afterSelection = text.substring(finish, text.length)
            const newText = beforeSelection + "<" + beaconName + ">" + selectedText + "</" + beaconName + ">" + afterSelection
            setHtmlText(newText)
        }
    }

	function addImageOnTextArea(imgPath: string, sizeHmlCode: string) {
		const imgHtml = "<img src=\"" + imgPath + "\" " + sizeHmlCode + " />"
		const txtarea = messageTextAreaRef.current;
		const start = txtarea?.selectionStart;
		const finish = txtarea?.selectionEnd;
		const text = messageTextAreaRef.current?.value

		if (text) {
			if (start) {
				const beforeSelection = text.substring(0, start)
				var afterSelection = ""
				if (finish && start < finish) {
					afterSelection = text.substring(finish, text.length)
				} else {
					afterSelection = text.substring(start, text.length)
				}
				const newText = beforeSelection + imgHtml + afterSelection
				setHtmlText(newText)
				return
			}
		}

		setHtmlText(htmlText + imgHtml)
	}

    return (
        <>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td style={{ width: '200px' }}>
                            {
                                htmlText.length > 0 ?
                                    <EditionLabel language={language} textId={titleId} />
                                    :
                                    <EditionLabel language={language} textId={titleId} color='red' />
                            }
                        </td>
                        <td></td>
                        {
                            editHtml ?
                                <>
                                    <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
                                        type: ButtonType.BOLD, onClick: () => addBeginAndEndingBeaconOnSelectedText("b")
                                    }} language={language} /></td>
                                    <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
                                        type: ButtonType.ITALIC, onClick: () => addBeginAndEndingBeaconOnSelectedText("i")
                                    }} language={language} /></td>
                                    <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
                                        type: ButtonType.UNDERLINE, onClick: () => addBeginAndEndingBeaconOnSelectedText("u")
                                    }} language={language} /></td>
                                    <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
                                        type: ButtonType.HTML, onClick: () => addBeginAndEndingBeaconOnSelectedText("span")
                                    }} language={language} /></td>
                                    <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
                                        type: ButtonType.EMOJI, onClick: () => setShowEmojiSelectionModal(true)
                                    }} language={language} /></td>
                                    <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
                                        type: ButtonType.GIF, onClick: () => setShowGifSelectionModal(true)
                                    }} language={language} /></td>
                                    <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
                                        type: ButtonType.SEE, onClick: () => setEditHtml(false)
                                    }} language={language} /></td>
                                </>
                                :
                                <>
                                    <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
                                        type: ButtonType.NO, onClick: () => setEditHtml(true)
                                    }} language={language} /></td>
                                </>
                        }
                    </tr>
                </tbody>
            </table>


            <br />
            {
                editHtml ?
                    <>
                        <textarea ref={messageTextAreaRef} className='form-control'
                            style={{ width: "100%", height: "150px" }}
                            value={htmlText}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => { setHtmlText(event.target.value); }} />
                    </>
                    :
                    <>
                        <span style={{ paddingLeft: 30, paddingRight: 30 }} dangerouslySetInnerHTML={{ __html: htmlText }}></span>
                    </>
            }

            <SelectImageModal show={showEmojiSelectionModal} setShow={setShowEmojiSelectionModal}
                language={language}
                beginOfPathImage="emoji"
                onImageSelected={(s: string) => {
                    addImageOnTextArea(imgToFirebaseUrl2("emoji/" + s), "height=\"20\"")
                }}
                typesOfSelectionIndex={1}
                typeOfImageDisplay={TypeOfImageDisplay.SMALL}
                titleId="addAnEmoji"
                hcContext={hcContext}
            />

            <SelectImageModal show={showGifSelectionModal} setShow={setShowGifSelectionModal}
                language={language}
                beginOfPathImage="gif"
                onImageSelected={(s: string) => {
                    addImageOnTextArea(imgToFirebaseUrl2("gif/" + s), "style=\"max-height: 150px; max-width: 400px;\"")
                }}
                typesOfSelectionIndex={1}
                typeOfImageDisplay={TypeOfImageDisplay.NORMAL}
                titleId="addAnGif"
                hcContext={hcContext}
            />
        </>
    );
};