import React from "react"
import { SourceProps } from "../../props/sourceprops";
import SourceLink from "../source/SourceLink"
import BubbleToUser from "./BubbleToUser"

interface BubblesToUserInChatProps {
    source?: SourceProps;
    children: React.ReactNode;
}


const BubbleToUserInChat: React.FC<BubblesToUserInChatProps> = ({ source, children }) => {

    return (
        <table width="100%">
            <tbody>
                <tr>
                    <td width="4%"></td>
                    <td width="96%" style={{paddingTop: '7px'}}>
                        <div>
                            <BubbleToUser>{children}</BubbleToUser>
                            {
                                source !== undefined &&
                                <div style={{ display: 'inline-block', marginLeft: 14, padding: 5, marginRight: 30, backgroundColor: 'white' }}>
                                    <SourceLink source={source} />
                                </div>
                            }
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default BubbleToUserInChat



