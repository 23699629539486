import React, { useState } from "react"
import GetStrLocalized from '../datas/GetStrLocalized'
import H1TitleBreadcrumb from '../components/title/H1TitleBreadcrumb'
import PageContent from '../components/util/PageContent'
import { useLocation } from 'react-router-dom'
import ReadingContent from "../components/util/ReadingContent"
import CategoriesBigButtons from "../components/bigButton/CategoriesBigButtons"
import { FolderProps } from "../props/folderprops"
import { HcContext } from "../types/hccontext"
import { createErrorModalContent } from "../props/ModalContentProps"


interface CatechismOfTheCatholicChurchProps {
	language: string;
	backendUrl: string;
	hcContext: HcContext;
};


function CatechismOfTheCatholicChurch({ language, backendUrl, hcContext }: CatechismOfTheCatholicChurchProps) {

	const location = useLocation();
	const [lastPath, setLastPath] = useState("")
	const [parentFolders, setParentFolders] = useState<FolderProps[]>([])
	const [request, setRequest] = useState({ breadcrumb: [], title: "", categories: [], content: [] })

	const readingPartOfBreadcrumb = {
		path: GetStrLocalized(language, "readingsFolderName"),
		name: GetStrLocalized(language, "readings")
	}

	if (location.pathname !== lastPath) {
		setLastPath(location.pathname);
		const foldersArray = location.pathname.split('/');
		var cccSubFolder = ""
		if (foldersArray.length > 4) {
			cccSubFolder = foldersArray[4];
		}
		fetch(backendUrl + "/ccc_content_json?l=" + language + "&t=" + cccSubFolder)
			.then(res => res.json())
			.then(jsonData => {
				if (jsonData.errorMessage) {
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
					return
				}
				setRequest(jsonData)
				if (cccSubFolder === "")
					hcContext.setDocumentTitle(GetStrLocalized(language, "catechismOfTheCatholicChurch") + " - Holy Chatter");
				else
					hcContext.setDocumentTitle(jsonData.title + " - " + GetStrLocalized(language, "catechismOfTheCatholicChurch"));
				setParentFolders([
					readingPartOfBreadcrumb,
					...jsonData.breadcrumb
				])
			})
			.catch(rejected => {
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	return (
		<PageContent>
			<H1TitleBreadcrumb language={language} parentFolders={parentFolders}>{request.title}</H1TitleBreadcrumb>
			<br /><br />
			<CategoriesBigButtons categories={request.categories} hcContext={hcContext} />
			<br /><br />

			<div style={{ marginLeft: 40 }}>
				<ReadingContent language={language} type="bible" content={request.content} allowToDisplayLinkFromCorpus={hcContext.userAsRightsToModifyTheContent} hcContext={hcContext} />
			</div>
		</PageContent>
	)
}

export default CatechismOfTheCatholicChurch;