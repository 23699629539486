import React from "react"
import { VerseToDisplayProps } from "../../props/versetodisplayprops";
import BigButtonWithTextAtRight from '../bigButton/BigButtonWithTextAtRight'
import { HcContext } from "../../types/hccontext";



interface ReadingContentProps {
    language: string;
    type: string;
    content: VerseToDisplayProps[];
    allowToDisplayLinkFromCorpus: boolean;
    hcContext: HcContext;
}

function ReadingContent({ language, type, content, allowToDisplayLinkFromCorpus, hcContext }: ReadingContentProps) {

    const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')

    interface VerseProps {
        nb: number;
        text: string;
    }

    function PrintVerse({ nb, text }: VerseProps) {
        return (
            <React.Fragment>
                <span style={{ color: "#bf2329" }}>{zeroPad(nb, 2)}</span> <span dangerouslySetInnerHTML={{ __html: text }}></span>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className='hc-long-screen'>
                {
                    content &&
                    content.map((item, index) => {
                        return (
                            <React.Fragment key={item.text + "-" + type + "-verse-" + index}>

                                {index > 0 && (Number(content[index - 1].nb) + 1) < Number(item.nb) &&
                                    <div className='hc-content-text' style={{ marginLeft: 10, paddingLeft: 5, paddingRight: 5 }}>
                                        [...]<br /><br />
                                    </div>
                                }

                                <table style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '50%', paddingLeft: 5, paddingRight: 15 }}>
                                                <div className='hc-content-text'>
                                                    <div style={{ marginLeft: 10 }}>
                                                        <PrintVerse nb={item.nb} text={item.text} />
                                                    </div>
                                                    <br />
                                                </div>
                                            </td>
                                            <td style={{ width: '50%', paddingLeft: 15, paddingRight: 5 }}>
                                                {
                                                    item.link &&
                                                    <div style={{ marginLeft: 30 }}>
                                                        <BigButtonWithTextAtRight allowPreview={true} allowToDisplayLinkFromCorpus={allowToDisplayLinkFromCorpus} language={language} item={item.link} hcContext={hcContext} />
                                                        <br />
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </React.Fragment>
                        )
                    })
                }
            </div>

            <div className='hc-short-screen'>
                {
                    content &&
                    content.map((item, index) => {
                        return (
                            <React.Fragment key={type + "-verse-" + index}>

                                <div className='hc-content-text'>
                                    <div style={{ marginRight: 20 }}>
                                        <PrintVerse nb={item.nb} text={item.text} />
                                    </div>
                                    <br />
                                </div>
                                <br />

                                {
                                    item.link &&
                                    <div style={{ marginRight: 20 }}>
                                        <BigButtonWithTextAtRight allowPreview={true} language={language} item={item.link} allowToDisplayLinkFromCorpus={allowToDisplayLinkFromCorpus} hcContext={hcContext} />
                                        <br />
                                    </div>
                                }
                            </React.Fragment>
                        )
                    })
                }
            </div>
        </React.Fragment>
    )
}

export default ReadingContent