import React from 'react'
import { Link } from "react-router-dom"
import GetStrLocalized from '../../datas/GetStrLocalized';
import YouTube, { YouTubePlayer } from 'react-youtube';
import { Options } from 'youtube-player/dist/types';
import { useState } from 'react'
import { BigButtonProps } from '../../props/bigbuttonprops';
import { onErrorImageHandler } from '../util/imagefallbacks';
import Stars from '../star/Stars';
import { HcContext } from '../../types/hccontext';


interface BigButtonWithTextAtRightProps {
	allowPreview: boolean;
	allowToDisplayLinkFromCorpus: boolean;
	language: string;
	item: BigButtonProps;
	hcContext: HcContext;
}

const BigButtonWithTextAtRight: React.FC<BigButtonWithTextAtRightProps> = ({ allowPreview, allowToDisplayLinkFromCorpus, language, item, hcContext }) => {

	if (item.image === "") {
		item.image = item.sourceImage;
	}

	var hasManyVerses = false
	let versesStr = ""
	if (item.versesScopes) {
		item.versesScopes.forEach((elt, index) => {
			if (elt.firstVerse) {
				if (index > 0) {
					hasManyVerses = true
					versesStr += "."
				} else {
					versesStr += " "
				}
				if (elt.lastVerse) {
					hasManyVerses = true
					versesStr += `${elt.firstVerse}-${elt.lastVerse}`
				} else {
					versesStr += elt.firstVerse
				}
			}
		})
		if (hasManyVerses) {
			versesStr = GetStrLocalized(language, "verses") + versesStr
		} else {
			versesStr = GetStrLocalized(language, "verse") + versesStr
		}
	}


	if (allowToDisplayLinkFromCorpus &&
		item.linkFromCorpus && item.linkFromCorpus !== "" && item.linkFromCorpus !== "normal") {
		versesStr += " (" + GetStrLocalized(language, "linkFromCorpus_" + item.linkFromCorpus) + ")"
	}

	const opts: Options = {
		height: '135',
		width: '243',
		playerVars: {
			autoplay: 1,
			start: Number(item.startTimeSeconds),
			end: Number(item.endTimeSeconds),
		},
	};

	const [videoDisplayed, setVideoDisplayed] = useState(false)
	const [videoPlayer, setVideoPlayer] = useState<YouTubePlayer>()
	const [buttonText, setButtonText] = useState(GetStrLocalized(language, "listen"))

	function onVideoReady(event: any) {
		setVideoPlayer(event.target);
	}

	function Bb_loadYoutubeVideo() {
		if (!videoDisplayed) {
			setVideoDisplayed(true);
			setButtonText(GetStrLocalized(language, "stop"));
		} else {
			try {
				if (videoPlayer != null)
					videoPlayer.pauseVideo();
				setVideoDisplayed(false);
				setButtonText(GetStrLocalized(language, "listen"));
			} catch (error) {
				console.error(error);
			}
		}
	}

	return (
		<>
			<table style={{ width: '100%' }}>
				<tbody>
					<tr>
						<td className="hc-big-button-search-width">
							{videoDisplayed ?
								<YouTube opts={opts} videoId={item.youtubeId} onReady={onVideoReady} /> :
								<Link style={{ color: 'white', textAlign: 'left', width: '100%', textDecoration: 'none', outline: 'none' }} to={item.link}>
									<div className={"hc-big-button-search-width hc-big-button-search-height"} style={{ position: 'relative', objectFit: 'cover', borderRadius: 10, borderWidth: 0 }}>
										<img className={"hc-big-button-search-width hc-big-button-search-height hc-not-selectable"} style={{ objectFit: 'cover', borderRadius: 10, borderWidth: 0 }} src={item.image}
											onError={(e) => onErrorImageHandler(e, item.image)} />
										{item.duration !== "" && <span className="hc-big-button-search-durationtop" style={{ position: 'absolute', backgroundColor: 'black', padding: 3, right: 8 }}>{item.duration}</span>}
									</div>
								</Link>
							}
						</td>
						<td className="hc-big-button-text-at-right-padding">
							<Link to={item.link} style={{ textDecoration: 'none', color: hcContext.getTextColor() }}><b className='hc-button-title-style'>{item.title}</b></Link>
							<div style={{ color: hcContext.getSubTextColor(), paddingTop: '10px' }}>
								{allowPreview && <React.Fragment> <button style={{ cursor: 'pointer' }} onClick={Bb_loadYoutubeVideo}>{buttonText}</button> &nbsp; </React.Fragment>}{item.subTitle}
								{
									item.stars.numberOfVotes > 0 &&
									<span style={{ marginLeft: '10px' }} >
										<Stars starsStats={item.stars} size="small" />
									</span>
								}
							</div>
							<div style={{ textAlign: 'left', color: hcContext.getSubTextColor(), paddingTop: '10px' }}>
								{item.sourceImage !== "" && <img src={item.sourceImage} alt='Source logo' width="23" height="23" style={{ marginRight: 13, borderRadius: 100 }} onError={(e) => onErrorImageHandler(e, item.sourceImage)} />}
								<span style={{ lineHeight: '25px', verticalAlign: 'top' }}>{item.sourceName}</span>
							</div>
							<div style={{ textAlign: 'left', color: hcContext.getSubTextColor(), paddingTop: '10px' }}>
								{versesStr}
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	)
}

export default BigButtonWithTextAtRight