import { useEffect, useState } from "react"
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom"
import H1Title from '../components/title/H1Title'
import PageContent from '../components/util/PageContent'
import GetStrLocalized from '../datas/GetStrLocalized'
import { HcContext, SizeOfWindow } from '../types/hccontext'
import HorizontalSpace from '../components/util/HorizontalSpace'
import Button from '../components/button/Button'
import { ButtonType } from '../props/buttondataprops'
import GetHtmlStrLocalized from '../datas/GetHtmlStrLocalized'
import { GlossaryWordInfoProps, createEmptyGlossaryWordInfoProps } from "../types/GlossaryWordEdition"
import GlossaryWordModifier from "../components/modifier/GlossaryWordModifier"
import { createErrorModalContent } from "../props/ModalContentProps"

interface GlossaryProps {
	language: string;
	backendUrl: string;
	hcContext: HcContext;
}


function Glossary({ language, backendUrl, hcContext }: GlossaryProps) {

	useEffect(() => {
		hcContext.setDocumentTitle(GetStrLocalized(language, "glossary") + " - Holy Chatter");
	}, [hcContext, language]);

	const location = useLocation()
	const navigate = useNavigate()
	const [lastPath, setLastPath] = useState("")
	const [lastSearch, setLastSearch] = useState("")
	const [words, setWords] = useState<GlossaryWordInfoProps[]>([])
	const [addMode, setAddMode] = useState(false)

	if (location.pathname !== lastPath || location.search !== lastSearch) {
		setLastPath(location.pathname)
		setLastSearch(location.search)
		const urlParams = new URLSearchParams(location.search);
		const isAddMode = urlParams.has('add')
		setAddMode(isAddMode)

		fetch(backendUrl + "/glossary_page_json?l=" + language)
			.then(res => res.json())
			.then(jsonData => {
				if (jsonData.errorMessage) {
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
					return
				}
				setWords(jsonData.words)
			})
			.catch(rejected => {
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	function DisplayTitle() {
		return (
			<div>
				<table width="100%" style={{ marginTop: '20px', marginBottom: '20px' }}>
					<tbody>
						<tr>
							<td className='hc-content-text hc-content-text-paddings' width="20%">
								<b><GetHtmlStrLocalized language={language} textId="word" /></b>
							</td>
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' width="40%">
									<b><GetHtmlStrLocalized language={language} textId="shortDefinition" /></b>
								</td>
							}
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' width="20%">
									<b><GetHtmlStrLocalized language={language} textId="source" /></b>
								</td>
							}
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' width="20%">
									<b><GetHtmlStrLocalized language={language} textId="url" /></b>
								</td>
							}
						</tr>
					</tbody>
				</table>
				<hr style={{ height: 2, backgroundColor: '#333333', border: 'none' }} />
			</div>
		)
	}



	interface DisplayWordLineProps {
		word: GlossaryWordInfoProps;
	}

	function DisplayWordLine({ word }: DisplayWordLineProps) {

		return (
			<div style={{ fontWeight: 'bold' }}>
				<table width="100%" style={{ marginTop: '20px', marginBottom: '20px' }}>
					<tbody>
						<tr>
							<td className='hc-content-text hc-content-text-paddings' width="20%">
								<Link to={word.word}>{word.word}</Link>
							</td>
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' width="40%">
									{word.shortDefinition}
								</td>
							}
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' width="20%">
									{word.source}
								</td>
							}
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' width="20%">
									<a href={word.url} target="_blank" rel="noreferrer">{word.url}</a>
								</td>
							}
						</tr>
					</tbody>
				</table>
				{
					hcContext.sizeOfWindow === SizeOfWindow.WIDE &&
					<hr style={{ height: 1, backgroundColor: 'grey', border: 'none' }} />
				}
			</div>
		)
	}

	return (
		<PageContent>
			{
				addMode &&
				!hcContext.userAsRightsToModifyTheContent &&
				<Navigate to={lastPath} />
			}

			{
				addMode ?
					<GlossaryWordModifier language={language} hcContext={hcContext} inputWord={createEmptyGlossaryWordInfoProps()} lastPath={lastPath} />
					:
					<>
						<table width='100%'>
							<tbody>
								<tr>
									<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
										<HorizontalSpace width={45} />
									</td>
									<td>
										<H1Title><GetHtmlStrLocalized language={language} textId="glossary" /></H1Title>
									</td>
									{
										hcContext.userAsRightsToModifyTheContent ?
											<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
												<Button btnData={{ type: ButtonType.ADD, onClick: () => navigate("?add") }} language={language} />
												<HorizontalSpace />
											</td>
											:
											<td width={(30 + 15) * 3 + 45} className="hc-not-very-short-screen"></td>
									}
								</tr>
							</tbody>
						</table>

						<br /><br />

						<div className='hc-content-text-paddings'>
							<DisplayTitle />
							{words.map((word, index) => { return <DisplayWordLine key={"word-" + index} word={word} /> })}
						</div>
					</>
			}

		</PageContent>
	)
}

export default Glossary;