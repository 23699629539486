import React, { useState } from 'react';
import VerticalDisplay from '../util/VerticalDisplay';
import EditionLabel from '../title/EditionLabel';
import SourcesSelect from './SourcesSelect';
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized';
import SubEditionLabel from '../title/SubEditionLabel';
import { HcInput } from '../input/HcInput';
import AddSourceModal from '../modal/AddSourceModal';
import { HcContext, SelectEltProps } from '../../types/hccontext';
import InputWithStyle from '../banner/InputWithStyle';


interface SourceSelectWithUrlProps {
  language: string;
  hcContext: HcContext;
  source: SelectEltProps | null;
  setSource: React.Dispatch<React.SetStateAction<SelectEltProps | null>>;
  sourceUrl: string;
  setSourceUrl: React.Dispatch<React.SetStateAction<string>>;
  sourcesPossibilities: SelectEltProps[];
  setSourcesPossibilities: (s: SelectEltProps[]) => any;
}

function SourceSelectWithUrl({ language, hcContext, source, setSource, sourceUrl, setSourceUrl,
  sourcesPossibilities, setSourcesPossibilities }: SourceSelectWithUrlProps) {
  const [showAddSourceModal, setShowAddSourceModal] = useState(false)

  return (
    <>
      <VerticalDisplay
        elts={[
          {
            component: source !== null ? (
              <EditionLabel language={language} textId="source" />
            ) : (
              <EditionLabel language={language} textId="source" color="red" />
            ),
            width: '140px',
            paddingRight: '20px'
          },
          {
            component: <>
              <SourcesSelect elts={sourcesPossibilities} selectedElt={source} setSelectedElt={setSource} styles={hcContext.getStylesForSelect<SelectEltProps>()} />
              <button onClick={() => setShowAddSourceModal(true)}>
                <GetHtmlStrLocalized language={language} textId="addASourceInTheListOfPossibleSources" />
              </button>
            </>
          }
        ]}
      />
      <br />

      <VerticalDisplay
        elts={[
          {
            component: <SubEditionLabel language={language} textId="urlInTheSource" />,
            width: '140px',
            paddingRight: '20px'
          },
          {
            component: <InputWithStyle value={sourceUrl}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setSourceUrl(event.target.value)
                        }} isDarkTheme={hcContext.isDarkTheme} height={hcContext.defaultLineEditHeight()} />
          }
        ]}
      />

      <AddSourceModal language={language} show={showAddSourceModal} setShow={setShowAddSourceModal} hcContext={hcContext}
        onNewPossibleSource={(name, id) => {
          setSourcesPossibilities([{ value: id, label: name }, ...sourcesPossibilities])
        }} />

    </>
  );
}

export default SourceSelectWithUrl