import '../assets/resources/custom/style/sources-0.css'
import React, { useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import H1Title from '../components/title/H1Title'
import PageContent from '../components/util/PageContent'
import BigButtonWithTextAtBottom from "../components/bigButton/BigButtonWithTextAtBottom"
import GetStrLocalized from '../datas/GetStrLocalized'
import { HcContext } from '../types/hccontext'
import HorizontalSpace from '../components/util/HorizontalSpace'
import Button from '../components/button/Button'
import { ButtonType } from '../props/buttondataprops'
import SourceModifier from '../components/modifier/SourceModifier'
import H2TitleId from '../components/title/H2TitleId'
import GetHtmlStrLocalized from '../datas/GetHtmlStrLocalized'
import { SourceCharacteristicsProps, getNbOfArticles, getNbOfReferences } from '../props/SourceCharacteristicsProps'
import { createErrorModalContent } from '../props/ModalContentProps'

interface SourceRequestProps {
	nameForUrl: string;
	icon: string;
	name: string;
	characteristics: SourceCharacteristicsProps;
}

interface RequestProps {
	sources: SourceRequestProps[];
	sources_hidden: SourceRequestProps[];
}

interface SourcesProps {
	language: string;
	setDocumentTitle: React.Dispatch<React.SetStateAction<string>>;
	backendUrl: string;
	hcContext: HcContext;
}


function Sources({ language, setDocumentTitle, backendUrl, hcContext }: SourcesProps) {

	useEffect(() => {
		setDocumentTitle(GetStrLocalized(language, "sources") + " - Holy Chatter");
	}, [setDocumentTitle, language]);

	const location = useLocation()
	const navigate = useNavigate()
	const [lastPath, setLastPath] = useState("")
	const [lastSearch, setLastSearch] = useState("")
	const [request, setRequest] = useState<RequestProps>({ sources: [], sources_hidden: [] })
	const [addMode, setAddMode] = useState(false)

	if (location.pathname !== lastPath || location.search !== lastSearch) {
		setLastPath(location.pathname)
		setLastSearch(location.search)
		const urlParams = new URLSearchParams(location.search);
		const isAddMode = urlParams.has('add')
		setAddMode(isAddMode)

		fetch(backendUrl + "/sources_page_json?l=" + language)
			.then(res => res.json())
			.then(jsonData => {
				if (jsonData.errorMessage) {
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
					return
				}
				setRequest(jsonData)
			})
			.catch(rejected => {
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	function printSourceInfos(source: SourceRequestProps): string[] {
		if (!hcContext.userAsRightsToModifyTheContent)
			return []
		return [GetStrLocalized(language, "publicMsgs") + GetStrLocalized(language, "twoDots") + getNbOfArticles(source.characteristics.publicMessages),
		GetStrLocalized(language, "hiddenMsgs") + GetStrLocalized(language, "twoDots") + getNbOfArticles(source.characteristics.hiddenMessages),
		GetStrLocalized(language, "references") + GetStrLocalized(language, "twoDots") + getNbOfReferences(source.characteristics)]
	}


	return (
		<PageContent>
			{
				addMode &&
				!hcContext.userAsRightsToModifyTheContent &&
				<Navigate to={lastPath} />
			}

			{
				addMode ?
					<SourceModifier language={language} hcContext={hcContext} sourceId="" lastPath={lastPath} displayValidationButtonsOnTop={true}
						onModified={(_, id) => navigate('/' + language + '/' + GetStrLocalized(language, "sourcesFolderName") + '/' + id)}
					/>
					:
					<>

						<table width='100%'>
							<tbody>
								<tr>
									<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
										<HorizontalSpace width={45} />
									</td>
									<td>
										<H1Title><GetHtmlStrLocalized language={language} textId="sources" /></H1Title>
									</td>
									{
										hcContext.userAsRightsToModifyTheContent ?
											<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
												<Button btnData={{ type: ButtonType.ADD, onClick: () => navigate("?add") }} language={language} />
												<HorizontalSpace />
											</td>
											:
											<td width={(30 + 15) * 3 + 45} className="hc-not-very-short-screen"></td>
									}
								</tr>
							</tbody>
						</table>

						<br /><br />

						<div className='hc-sources-left-margin'>
							{
								request.sources &&
								request.sources.map((item, index) => {
									return <BigButtonWithTextAtBottom key={"a-source-" + index} link={item.nameForUrl} image={item.icon} duration="" title={item.name} tags="" description={printSourceInfos(item)} beginOfClassName="hc-big-button-source" hcContext={hcContext} imageBorderRadius={100} />
								})
							}
						</div>

						{
							hcContext.userAsRightsToModifyTheContent &&
							request.sources_hidden &&
							<>
								<br />
								<H2TitleId language={language} titleId="privateSection" />
								<br />

								<div className='hc-sources-left-margin'>
									{
										request.sources_hidden.map((item, index) => {
											return <BigButtonWithTextAtBottom key={"a-hidden-source-" + index} link={item.nameForUrl} image={item.icon} duration="" title={item.name} tags="" description={printSourceInfos(item)} beginOfClassName="hc-big-button-source" hcContext={hcContext} imageBorderRadius={100} />
										})
									}
								</div>
							</>
						}
					</>
			}

		</PageContent>
	)
}

export default Sources;