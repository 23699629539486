interface HcInputProps extends React.ButtonHTMLAttributes<HTMLInputElement> {
    ref?: React.RefObject<HTMLInputElement>;
    width?: string;
}


export const HcInput: React.FC<HcInputProps> = ({ref, width = '', ...props }) => {
    return (
        <input ref={ref} className='form-control' style={{fontSize:'17px', padding:'5px', width:width}} {...props}>
        </input>
    );
};