
export interface PlannerFactOptional {
	isFactNegated: string;
	fact: PlannerFact;
}

export interface PlannerFact {
	name: string;
	arguments: PlannerFactOptional[];
	value: string;
	isValueNegated: string;
}

export interface FactOrText {
	fact: PlannerFact;
	text: string;
}

export interface ContinuationTextDescriptionProps {
	facts: string;
	text: string;
}

export interface FactDescAndFollowingFacts {
	fact: PlannerFact;
	triggers: string[];
	preconditionFacts: FactOrText[][];
	continuationTexts: ContinuationTextDescriptionProps[];
	commentaries: string;
	followingFacts: PlannerFact[];
}


export const emptyPlannerFact: PlannerFact = {
	name: "",
	arguments: [],
	value: "",
	isValueNegated: ""
}

export const emptyFactDescAndFollowingFacts: FactDescAndFollowingFacts = {
	fact: emptyPlannerFact,
	triggers: [],
	preconditionFacts: [],
	continuationTexts: [],
	commentaries: "",
	followingFacts: []
}



export function pannerFactToStr(fact: PlannerFact) {
	var res = fact.name

	if (fact.arguments.length > 0) {
		res += "("
		fact.arguments.forEach((argument, argumentIndex) => {
			if (argumentIndex > 0)
				res += ", "
			if (argument.isFactNegated === "true")
				res += "!"
			res += pannerFactToStr(argument.fact)
		})
		res += ")"
	}
	if (fact.value.length > 0)
		res += "=" + fact.value
	return res
}


export function factOrTextToStr(preconditionFacts: FactOrText[][]): string {
	var res = ""
	preconditionFacts.forEach((factOrTextLine, factOrTextLineIndex) => {
		if (factOrTextLineIndex > 0)
			res += "\n";
		factOrTextLine.forEach((factOrText) => {
			if (factOrText.text !== "")
				res += factOrText.text;
			else
				res += pannerFactToStr(factOrText.fact);
		})
	})
	return res
}

export function continuationTextDescriptionPropsToStr(continuations: ContinuationTextDescriptionProps[]): string {
	var res = ""
	continuations.forEach((continuation, continuationIndex) => {
		if (continuationIndex > 0)
			res += "\n";
		res += continuation.facts + "#" + continuation.text;
	})
	return res
}

export function strListToStr(strList: string[]): string {
	var res = ""
	strList.forEach((str, strIndex) => {
		if (strIndex > 0)
			res += "\n";
		res += str;
	})
	return res
}