import React from 'react'
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal'


interface InformationModalProps {
    language: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    isDarkTheme: boolean;
    title: string;
    text: string;
    texts?: string[];
}


function InformationModal({ language, show, setShow, isDarkTheme, title, text, texts }: InformationModalProps) {
    return (
        <Modal
            language={language}
            show={show}
            setShow={setShow}
            isDarkTheme={isDarkTheme}
        >
            <ModalHeader>
                <h3>{title}</h3>
            </ModalHeader>
            <ModalBody>
                {text}
                {
                    texts !== undefined &&
                    <ul>
                        {
                            texts.map((currText, index) => (
                                <li key={`info-modal-text-${index}`}>{currText}</li>
                            ))
                        }
                    </ul>
                }
            </ModalBody>
            <br />
            <ModalFooter>
                <button onClick={() => {
                    setShow(false)
                }}>
                    <GetHtmlStrLocalized language={language} textId="ok" />
                </button>
            </ModalFooter>
        </Modal>
    );
}

export default InformationModal;