import { NavigateFunction } from "react-router-dom";
import { HcContext } from "../types/hccontext";
import { createErrorModalContent } from "../props/ModalContentProps";

class ArticleRemovalPostContentType {
    language: string;
    userKey: string;
    type: string;
    id: string;

    constructor(
        language: string,
        userKey: string,
        type: string,
        id: string) {
        this.language = language;
        this.userKey = userKey;
        this.type = type;
        this.id = id;
    }
}

export function removeItem(itemId: string, itemType: string, hcContext: HcContext, language: string,
    setProcessingModifications: (value: React.SetStateAction<boolean>) => void,
    navigate: NavigateFunction) {
    if (!hcContext.userToken)
        return
    setProcessingModifications(true)
    hcContext.setBlackCoverOfContent(true)
    const postContent = new ArticleRemovalPostContentType(language, hcContext.userToken, itemType, itemId)
    fetch(hcContext.backendUrl + "/remove_item", {
        method: 'POST',
        body: JSON.stringify(postContent),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(jsonData => {
            setProcessingModifications(false)
            hcContext.setBlackCoverOfContent(false)
            if (jsonData.errorMessage) {
                hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
            } else {
                navigate(jsonData.link)
            }
        })
        .catch(rejected => {
            setProcessingModifications(false)
            hcContext.setBlackCoverOfContent(false)
            hcContext.setInfo(createErrorModalContent(language, rejected.message))
        });
}