import * as React from "react";
import EditionLabel from "../title/EditionLabel";
import Button from "../button/Button";
import { ButtonType } from "../../props/buttondataprops";
import GetStrLocalized from "../../datas/GetStrLocalized";
import { SiteOfASourceProps } from "../../types/SourceEdition";
import SiteEditionModal from "../modal/SiteEditionModal";
import { HcContext } from "../../types/hccontext";



interface SitesListViewerProps {
  language: string;
  sites: SiteOfASourceProps[];
  setSites: (r: SiteOfASourceProps[]) => any;
  isDarkTheme: boolean;
}

export default function SitesListViewer(
  { language, sites, setSites, isDarkTheme }: SitesListViewerProps
) {
  const [selectedElt, setSelectedElt] = React.useState<number | undefined>()
  const [showModal, setShowModal] = React.useState(false)
  const [addOrEdit, setAddOrEdit] = React.useState<boolean>(true)
  const [siteEdition, setSiteEdition] = React.useState<SiteOfASourceProps>({ url: "", title: "" })

  function swapElts(firstIndex: number, secondIndex: number) {
    var refCopied = sites;
    const temp = refCopied[firstIndex];
    refCopied[firstIndex] = refCopied[secondIndex];
    refCopied[secondIndex] = temp;
    setSites(refCopied)
  }

  return (
    <>
      <table>
        <tbody>
          <tr>
            <td><EditionLabel language={"fr"} textId="sites" /></td>
            <td style={{ textAlign: 'center', width: '100%' }}></td>
            {
              selectedElt !== undefined &&
              <>
                {
                  sites.length > 1 &&
                  <>
                    <td style={{ paddingRight: '15px' }}><Button btnData={{
                      type: ButtonType.TOP, onClick: () => {
                        if (selectedElt > 0) {
                          swapElts(selectedElt - 1, selectedElt)
                          setSelectedElt(selectedElt - 1)
                        }
                      }
                    }} language={language} alt={GetStrLocalized(language, "move_up")} /></td>
                    <td style={{ paddingRight: '15px' }}><Button btnData={{
                      type: ButtonType.DOWN, onClick: () => {
                        if (selectedElt < sites.length - 1) {
                          swapElts(selectedElt, selectedElt + 1)
                          setSelectedElt(selectedElt + 1)
                        }
                      }
                    }} language={language} alt={GetStrLocalized(language, "move_down")} /></td>
                  </>
                }
                <td style={{ paddingRight: '15px' }}><Button btnData={{
                  type: ButtonType.EDIT, onClick: () => {
                    setAddOrEdit(false)
                    setSiteEdition(sites[selectedElt])
                    setShowModal(true)
                  }
                }} language={language} /></td>
                <td style={{ paddingRight: '15px' }}><Button btnData={{
                  type: ButtonType.DELETE, onClick: () => {
                    var refCopied = sites;
                    refCopied.splice(selectedElt, 1)
                    setSites(refCopied)
                    setSelectedElt(undefined)
                  }
                }} language={language} /></td>
              </>
            }
            <td style={{ paddingRight: '15px' }}><Button btnData={{
              type: ButtonType.ADD, onClick: () => {
                setAddOrEdit(true)
                setSiteEdition({ url: "", title: "" })
                setShowModal(true)
              }
            }} language={language} /></td>
          </tr>
        </tbody>
      </table>

      {
        sites.length > 0 &&
        <div style={{ listStyleType: "none", cursor: 'pointer' }}>
          {sites.map((item, index) => {
            return <div key={"site-list-elt-" + index} onClick={() => setSelectedElt(index)} >
              {
                selectedElt == index ?
                  <b>{item.title} ({item.url})</b>
                  :
                  <>{item.title} ({item.url})</>
              }
            </div>
          })}
        </div>
      }

      <SiteEditionModal
        language={language}
        addOrEdit={addOrEdit}
        show={showModal}
        setShow={setShowModal}
        isDarkTheme={isDarkTheme}
        site={siteEdition}
        onSiteValidated={(elt) => {
          if (addOrEdit) {
            setSites([...sites, elt])
            setSelectedElt(undefined)
          }
          else {
            var cpSites = sites
            cpSites[selectedElt!!] = elt
            setSites(cpSites)
          }
        }}
      />
    </>

  );
}