import React, { useEffect, useState } from 'react'
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal'
import { HcContext } from '../../types/hccontext';
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized';
import { createErrorModalContent } from '../../props/ModalContentProps';
import InputWithStyle from '../banner/InputWithStyle';


interface YesNoModalProps {
    language: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    hcContext: HcContext;
    setProcessingModifications: (v: boolean) => any;
    onModification?: () => any;
    tag?: string;
}

export class TagModificationPostContentType {
    language: string;
    userKey: string;
    id: string;
    newId: string;

    constructor(
        language: string,
        userKey: string,
        id: string,
        newId: string) {
        this.language = language;
        this.userKey = userKey;
        this.id = id;
        this.newId = newId;
    }
}

function AddOrModifyTagModal({ language, show, setShow, hcContext, setProcessingModifications, onModification, tag }: YesNoModalProps) {

    const [newId, setNewId] = useState<string>("")

    useEffect(() => {
        if (show)
            setNewId(tag !== undefined ? tag : "")
    }, [show]);

    function sendTagModification() {
        if (!hcContext.userToken)
            return
        setProcessingModifications(true)
        hcContext.setBlackCoverOfContent(true)
        const postContent = new TagModificationPostContentType(language, hcContext.userToken,
            tag !== undefined ? tag : "", newId)
        fetch(hcContext.backendUrl + "/modify_tag", {
            method: 'POST',
            body: JSON.stringify(postContent),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
            .then(res => res.json())
            .then(jsonData => {
                setProcessingModifications(false)
                hcContext.setBlackCoverOfContent(false)
                if (jsonData.errorMessage) {
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
                } else if (jsonData.id === newId) {
                    if (onModification !== undefined)
                        onModification()
                }
                setShow(false)
            })
            .catch(rejected => {
                setProcessingModifications(false)
                hcContext.setBlackCoverOfContent(false)
                setShow(false)
                hcContext.setInfo(createErrorModalContent(language, rejected.message))
            });
    }

    return (
        <>
            <Modal
                language={language}
                show={show}
                setShow={setShow}
                isDarkTheme={hcContext.isDarkTheme}
            >
                <ModalHeader>
                    <h3><GetHtmlStrLocalized language={language} textId={tag === undefined ? "addATag" : "modifyATag"} /></h3>
                </ModalHeader>
                <ModalBody>
                    <InputWithStyle value={newId} onValidation={sendTagModification}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setNewId(event.target.value)
                        }} isDarkTheme={hcContext.isDarkTheme} height={hcContext.defaultLineEditHeight()} autoFocus={true} />
                </ModalBody>
                <br />
                <ModalFooter>
                    {
                        newId !== "" &&
                        <button onClick={() => {
                            sendTagModification()
                        }}>
                            <GetHtmlStrLocalized language={language} textId="validate" />
                        </button>
                    }
                </ModalFooter>
            </Modal>
        </>
    );
}

export default AddOrModifyTagModal;