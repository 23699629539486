import { PlannerFact, pannerFactToStr } from "../../props/FactsProps";


interface DisplayPannerFactProps {
	fact: PlannerFact;
}

export function DisplayPannerFact({ fact }: DisplayPannerFactProps) {
	return (<>{pannerFactToStr(fact)}</>)
}
