import React from 'react'
import { HcContext } from '../../types/hccontext'
import HorizontalSpace from '../util/HorizontalSpace'
import NavigationArrows from '../navigation/NavigationArrows'
import H1Title from '../title/H1Title'
import H1TitleBreadcrumb from '../title/H1TitleBreadcrumb'
import Button from '../button/Button'
import Centered from '../util/Centered'
import { ButtonDataProps, ButtonType } from '../../props/buttondataprops'
import { FolderProps } from '../../props/folderprops'
import { useNavigate } from 'react-router-dom'


interface SubBannerCategoryProps {
	language: string;
	hcContext: HcContext;
	arrows: ButtonDataProps[];
	parentFolders: FolderProps[];
	nameToPrint: string;
	lastPath: string;
	setShowMoveModal: React.Dispatch<React.SetStateAction<boolean>>;
	searchMode: boolean;
}



const SubBannerCategory: React.FC<SubBannerCategoryProps> = ({ language, hcContext, arrows,
	parentFolders, nameToPrint, lastPath, setShowMoveModal, searchMode }) => {
	const navigate = useNavigate()

	return (
		<>
			<table width='100%'>
				<tbody>
					<tr>
						<td width={(30 + 15) * 4 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
							<HorizontalSpace width={45} />
							<NavigationArrows btnDatas={arrows} language={language} />
						</td>
						<td>
							{
								lastPath === "/" + language + "/categories" ?
									<H1Title>{nameToPrint}</H1Title>
									:
									<H1TitleBreadcrumb language={language} parentFolders={parentFolders}>{nameToPrint}</H1TitleBreadcrumb>
							}
						</td>
						{
							hcContext.userAsRightsToModifyTheContent ?
								<td width={(30 + 15) * 4 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
									{
										searchMode ?
											<>
												<Button btnData={{ type: ButtonType.NO, onClick: () => navigate(lastPath) }} language={language} />
												<HorizontalSpace />
												<HorizontalSpace width={30} />
												<HorizontalSpace />
												<HorizontalSpace width={30} />
												<HorizontalSpace />
												<HorizontalSpace width={30} />
												<HorizontalSpace />
											</>
											:
											<>
												{
													parentFolders.length > 0 ?
														<>
															<Button btnData={{ type: ButtonType.SEARCH, onClick: () => navigate("?search") }} language={language} />
															<HorizontalSpace />
															<Button btnData={{ type: ButtonType.EDIT, onClick: () => navigate("?edit") }} language={language} />
															<HorizontalSpace />
															<Button btnData={{ type: ButtonType.MOVE, onClick: () => setShowMoveModal(true) }} language={language} />
															<HorizontalSpace />
														</>
														:
														<>
															<HorizontalSpace width={30} />
															<HorizontalSpace />
															<HorizontalSpace width={30} />
															<HorizontalSpace />
															<HorizontalSpace width={30} />
															<HorizontalSpace />
														</>
												}
												<Button btnData={{ type: ButtonType.ADD, onClick: () => navigate("?add") }} language={language} />
												<HorizontalSpace />
											</>
									}
								</td>
								:
								<td width={(30 + 15) * 4 + 45} className="hc-not-very-short-screen"></td>
						}
					</tr>
				</tbody>
			</table>

			<br />

			<div className="hc-very-short-screen">
				<Centered>
					<HorizontalSpace width={45} />
					<NavigationArrows btnDatas={arrows} language={language} />
				</Centered>
			</div>
		</>
	)
}

export default SubBannerCategory
