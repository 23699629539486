import '../assets/resources/custom/style/main-6.css'
import '../assets/resources/custom/style/bootstrap_hc.css'
import '../assets/resources/custom/style/dialog_max_height.css'
import '../assets/resources/custom/style/categories-1.css'
import image_wait from '../assets/resources/custom/icons/wait.gif'
import image_ok from '../assets/resources/custom/icons/ok.png'
import React, { useState } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import PageContent from '../components/util/PageContent'
import CategoriesBigButtons from '../components/bigButton/CategoriesBigButtons'
import BubblesFromUser from '../components/chatbot/BubblesFromUser'
import { HcContext, SizeOfWindow } from '../types/hccontext'
import Centered from '../components/util/Centered'
import TypeOfItemModal from '../components/modal/TypeOfItemModal'
import ArticleModifier from '../components/modifier/ArticleModifier'
import FolderModifier from '../components/modifier/FolderModifier'
import H2TitleId from '../components/title/H2TitleId'
import { moveItem } from '../util/moveItem'
import { getWaitPopupStyle } from '../components/util/GetWaitPopupStyle'
import SearchInput from '../components/banner/SearchInput'
import GetHtmlStrLocalized from '../datas/GetHtmlStrLocalized'
import { HCFileTypeIcon } from '../components/icon/HCFileTypeIcon'
import { imgToFirebaseUrl } from '../components/util/imagefallbacks'
import GetStrLocalized from '../datas/GetStrLocalized'
import SubBannerCategory from '../components/banner/SubBannerCategory'
import ChatbotModifier from '../components/modifier/ChatbotModifier'
import QuestionForQuizAdder from '../components/modifier/QuestionForQuizAdder'
import { createErrorModalContent } from '../props/ModalContentProps'
import MoveDestinationPathSelectorModal from '../components/modal/MoveDestinationPathSelectorModal'

interface CategoriesProps {
	language: string;
	hcContext: HcContext;
};


interface InternalMessage {
	id: string;
	idType: string;
	fileType: string;
	sourceName: string;
	sourceImage: string;
	tested: string;
	path: string[];
}



const Categories: React.FC<CategoriesProps> = ({ language, hcContext }) => {

	const location = useLocation()
	const navigate = useNavigate()
	const [lastPath, setLastPath] = useState("")
	const [lastSearch, setLastSearch] = useState("")
	const [request, setRequest] = useState({
		name: "", nameToPrint: "", parentFolders: [], bigButtontDatas: [], chatMsgs: [], arrows: [],
		bigButtontDatas_hidden: [], chatMsgs_hidden: []
	})
	const [internalSearchResult, setInternalSearchResult] = useState<InternalMessage[]>([])
	const [addMode, setAddMode] = useState(false)
	const [editMode, setEditMode] = useState(false)
	const [searchMode, setSearchMode] = useState(false)
	const [searchValue, setSearchValue] = useState("")
	const [showTypeOfItemModal, setShowTypeOfItemModal] = useState(false)
	const [typeOfItemToAdd, setTypeOfItemToAdd] = useState("")
	const [folderId, setFolderId] = useState("")
	let categoryName = "";
	const [showMoveModal, setShowMoveModal] = useState(false)
	const [processingModifications, setProcessingModifications] = useState(false)

	if (location.pathname !== lastPath || location.search !== lastSearch) {
		setLastPath(location.pathname);
		setLastSearch(location.search);
		const foldersArray = location.pathname.split('/');
		if (foldersArray.length > 3) {
			categoryName = foldersArray[3]
			setFolderId(categoryName)
		}
		const urlParams = new URLSearchParams(location.search);
		const isAddMode = urlParams.has('add')
		setAddMode(isAddMode)
		setShowTypeOfItemModal(isAddMode)
		const isEditMode = urlParams.has('edit')
		setEditMode(isEditMode)
		const isSearchMode = urlParams.has('search')
		setSearchMode(isSearchMode)
		if (isSearchMode) {
			const searchvalueOrNull = urlParams.get('search')
			if (searchvalueOrNull !== null) {
				setSearchValue(searchvalueOrNull)

				if (searchvalueOrNull !== "") {
					fetch(hcContext.backendUrl + "/internal_search", {
						method: 'POST',
						body: JSON.stringify({
							userToken: hcContext.userToken,
							language: language,
							searchInput: searchvalueOrNull,
							rootCategoryId: categoryName
						}),
						headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
					})
						.then(res => res.json())
						.then(jsonData => {
							if (jsonData.errorMessage) {
								hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
								return
							}
							setInternalSearchResult(jsonData.messages)
						})
						.catch(rejected => {
							hcContext.setInfo(createErrorModalContent(language, rejected.message))
						});
				} else {
					setInternalSearchResult([])
				}
			}
		}

		if (!isAddMode && !isEditMode) {
			setTypeOfItemToAdd("")
			fetch(hcContext.backendUrl + "/categories_page_json?l=" + language + "&id=" + categoryName)
				.then(res => res.json())
				.then(jsonData => {
					if (jsonData.errorMessage) {
						hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
						return
					}
					setRequest(jsonData)
					hcContext.setDocumentTitle(jsonData.name + " - Holy Chatter");
				})
				.catch(rejected => {
					hcContext.setInfo(createErrorModalContent(language, rejected.message))
				});
		}
	}

	function DisplayInternalSearchTitle() {
		return (
			<div>
				<table width="100%" style={{ marginTop: '20px', marginBottom: '20px' }}>
					<tbody>
						<tr>
							<td className='hc-content-text hc-content-text-paddings' width="70%">
								<b><GetHtmlStrLocalized language={language} textId="name" /></b>
							</td>
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' width="20%">
									<b><GetHtmlStrLocalized language={language} textId="source" /></b>
								</td>
							}
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' width="10%">
									<Centered>
										<b><GetHtmlStrLocalized language={language} textId="tested" /></b>
									</Centered>
								</td>
							}
						</tr>
					</tbody>
				</table>
				<hr style={{ height: 2, backgroundColor: '#333333', border: 'none' }} />
			</div>
		)
	}

	interface DisplayInternalSearchLineProps {
		message: InternalMessage;
		msgIndex: number;
	}

	function DisplayInternalSearchLine({ message, msgIndex }: DisplayInternalSearchLineProps) {
		return (
			<div>
				<table width="100%" style={{ marginTop: '20px', marginBottom: '20px', cursor: 'pointer' }}
					onClick={() => {
						navigate('/' + language + '/' + message.id)
					}}>
					<tbody>
						<tr>
							<td className='hc-content-text hc-content-text-paddings' width="70%">
								<HCFileTypeIcon fileType={message.fileType} />
								&nbsp; &nbsp; {message.path[message.path.length - 1]}
								{
									message.path.length > 1 &&
									<>
										<br />
										({
											message.path.map((item, index) => {
												if (index === message.path.length - 1)
													return <span key={"msg-path-" + msgIndex + "-" + index}></span>
												if (index === 0)
													return <span key={"msg-path-" + msgIndex + "-" + index}>{item}</span>
												return <span key={"msg-path-" + msgIndex + "-" + index}> <span style={{ color: '#ccc' }}>&#62;</span> {item}</span>
											})
										})
									</>
								}
							</td>
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' width="20%">
									{message.sourceImage !== "" && <img src={imgToFirebaseUrl(message.sourceImage)} alt='Source logo' width="23" height="23" style={{ marginRight: 13 }} />}
									<span style={{ lineHeight: '25px', verticalAlign: 'top' }}>{message.sourceName}</span>
								</td>
							}
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' width="10%">
									{
										message.tested === "true" &&
										<Centered>
											<img src={image_ok} alt={"tested: " + message.tested} />
										</Centered>
									}
								</td>
							}
						</tr>
					</tbody>
				</table>
				{
					hcContext.sizeOfWindow === SizeOfWindow.WIDE &&
					<hr style={{ height: 1, backgroundColor: 'grey', border: 'none' }} />
				}
			</div>
		)
	}


	return (
		<PageContent>

			{
				(addMode || editMode || searchMode) &&
				!hcContext.userAsRightsToModifyTheContent &&
				<Navigate to={lastPath} />
			}

			{
				processingModifications &&
				<Centered>
					<div style={getWaitPopupStyle(true, 50)}>
						<img src={image_wait} alt="wait" height="100%" width="100%" />
					</div>
				</Centered>
			}

			{
				editMode ?
					<FolderModifier language={language} hcContext={hcContext} parentFolders={request.parentFolders} currentFolderName={request.name} folderId={folderId} lastPath={lastPath} />
					:
					<>
						{
							typeOfItemToAdd === "article" &&
							<ArticleModifier language={language} hcContext={hcContext} parentFolders={request.parentFolders} currentFolderName={request.name} articleId="" lastPath={lastPath} />
						}

						{
							typeOfItemToAdd === "category" &&
							<FolderModifier language={language} hcContext={hcContext} parentFolders={request.parentFolders} currentFolderName={request.name} folderId="" lastPath={lastPath} />
						}

						{
							typeOfItemToAdd === "chatbot" &&
							<ChatbotModifier language={language} hcContext={hcContext} parentFolders={request.parentFolders} currentFolderName={request.name} chatbotId="" lastPath={lastPath} />
						}

						{
							typeOfItemToAdd === "questionForTheQuiz" &&
							<QuestionForQuizAdder language={language} hcContext={hcContext} parentFolders={request.parentFolders} currentFolderName={request.name} lastPath={lastPath} />
						}

						{
							typeOfItemToAdd === "" &&
							<>
								<SubBannerCategory language={language} hcContext={hcContext} arrows={request.arrows}
									parentFolders={request.parentFolders} nameToPrint={request.nameToPrint}
									lastPath={lastPath} setShowMoveModal={setShowMoveModal} searchMode={searchMode} />
								<br />

								{
									searchMode ?
										<>
											<SearchInput placeholder={GetStrLocalized(language, "internalSearchFromThisDirectory")} searchValue={searchValue} searchPath={"?search="}
												isDarkTheme={hcContext.isDarkTheme} autoFocus={true} />

											{
												internalSearchResult.length > 0 &&
												<>
													<div className='hc-content-text-paddings'>
														<br />
														<DisplayInternalSearchTitle />
														{
															internalSearchResult.map((item, index) => {
																return <DisplayInternalSearchLine key={"search-res-" + index} message={item} msgIndex={index} />
															})
														}
													</div>
												</>
											}

										</>
										:
										<>
											<CategoriesBigButtons categories={request.bigButtontDatas} printNumber={hcContext.userAsRightsToModifyTheContent} hcContext={hcContext} />
											<BubblesFromUser language={language} titleId="relatedQuestions" chatMsgs={request.chatMsgs} />

											{
												hcContext.userAsRightsToModifyTheContent &&
												(request.bigButtontDatas_hidden.length > 0 || request.chatMsgs_hidden.length > 0) &&
												<>
													<br />
													<H2TitleId language={language} titleId="privateSection" />
													<br />
													<CategoriesBigButtons categories={request.bigButtontDatas_hidden} printNumber={true} hcContext={hcContext} />
													<BubblesFromUser language={language} titleId="relatedQuestions" chatMsgs={request.chatMsgs_hidden} />
												</>
											}
										</>
								}

							</>
						}

					</>
			}


			<TypeOfItemModal language={language} show={showTypeOfItemModal} setShow={setShowTypeOfItemModal}
				onTypeOfItem={(toi) => setTypeOfItemToAdd(toi)} hcContext={hcContext} />

			<MoveDestinationPathSelectorModal language={language} show={showMoveModal} setShow={setShowMoveModal}
				backendUrl={hcContext.backendUrl} itemName={request.name}
				onPathChoosen={(path) => moveItem(request.parentFolders, path, request.name, hcContext, language, setProcessingModifications, navigate)}
				hcContext={hcContext}
			/>
		</PageContent>
	)
}

export default Categories
