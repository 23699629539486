import image_star_full from '../../assets/resources/custom/icons/star_full.png'
import image_star_half from '../../assets/resources/custom/icons/star_half.png'
import image_star_empty from '../../assets/resources/custom/icons/star_empty.png'

export interface StarsStats {
	totalScore: number;
	numberOfVotes: number;
}

export function valueToStarImage(begin: number, val: number) {
	if (val < begin + 0.3)
		return image_star_empty
	if (val < begin + 0.6)
		return image_star_half
	return image_star_full
}


export function starsStatsToValue(starsStats: StarsStats) {
	const numberOfVotes = Number(starsStats.numberOfVotes)
	if (numberOfVotes === 0)
		return 0
	return Number(starsStats.totalScore) / numberOfVotes
}