

export interface GlossaryWordInfoProps {
	word: string;
	shortDefinition: string;
	definition: string;
	source: string;
	url: string;
}

export function createEmptyGlossaryWordInfoProps(): GlossaryWordInfoProps {
	return { word: "", shortDefinition: "", definition: "", source: "", url: "" }
}


export class GlossaryWordEdition {
	value: GlossaryWordInfoProps;
	setWord: React.Dispatch<React.SetStateAction<string>>;
	setShortDefinition: React.Dispatch<React.SetStateAction<string>>;
	setDefinition: React.Dispatch<React.SetStateAction<string>>;
	setSource: React.Dispatch<React.SetStateAction<string>>;
	setUrl: React.Dispatch<React.SetStateAction<string>>;

	constructor(
	  value: GlossaryWordInfoProps,
	  setWord: React.Dispatch<React.SetStateAction<string>>,
	  setShortDefinition: React.Dispatch<React.SetStateAction<string>>,
	  setDefinition: React.Dispatch<React.SetStateAction<string>>,
	  setSource: React.Dispatch<React.SetStateAction<string>>,
	  setUrl: React.Dispatch<React.SetStateAction<string>>,
	) {
	  this.value = value
	  this.setWord = setWord
	  this.setShortDefinition = setShortDefinition
	  this.setDefinition = setDefinition
	  this.setSource = setSource
	  this.setUrl = setUrl
	}

	set(value: GlossaryWordInfoProps) {
	  this.setWord(value.word)
	  this.setShortDefinition(value.shortDefinition)
	  this.setDefinition(value.definition)
	  this.setSource(value.source)
	  this.setUrl(value.url)
	}
  }