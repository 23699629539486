import image_wait from '../../assets/resources/custom/icons/wait.gif'
import React, { ChangeEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import Button from '../button/Button'
import { ButtonType } from '../../props/buttondataprops'
import HorizontalSpace from '../util/HorizontalSpace'
import { HcContext } from '../../types/hccontext'
import Centered from '../util/Centered'
import EditionLabel from '../title/EditionLabel'
import { HcInput } from '../input/HcInput'
import { getWaitPopupStyle } from '../util/GetWaitPopupStyle'
import GetStrLocalized from '../../datas/GetStrLocalized'
import BigButtonWithTextAtBottom from '../bigButton/BigButtonWithTextAtBottom'
import { imgToFirebaseUrl2 } from '../util/imagefallbacks'
import YesNoModal from '../modal/YesNoModal'
import { SiteOfASourceProps, SourceEdition, SourceEditionProps } from '../../types/SourceEdition'
import SelectSourceImageModal from '../modal/SelectSourceImageModal'
import SitesListViewer from '../listview/SitesListViewer'
import { createErrorModalContent } from '../../props/ModalContentProps'


interface SourceModifierProps {
	language: string;
	hcContext: HcContext;
	sourceId: string;
	displayValidationButtonsOnTop: boolean;
	onModified: (name: string, id: string) => any;
	lastPath?: string;
};

export class SourceModificationPostContentType {
	language: string;
	userKey: string;
	id: string;
	value: SourceEditionProps;

	constructor(
		language: string,
		userKey: string,
		id: string,
		value: SourceEditionProps) {
		this.language = language;
		this.userKey = userKey;
		this.id = id;
		this.value = value;
	}
}

class SourceRemovalPostContentType {
	language: string;
	userKey: string;
	id: string;

	constructor(
		language: string,
		userKey: string,
		id: string) {
		this.language = language;
		this.userKey = userKey;
		this.id = id;
	}
}


class EditNameProps {
	language: string;
	name: string;
	setName: React.Dispatch<React.SetStateAction<string>>;

	constructor(
		language: string,
		name: string,
		setName: React.Dispatch<React.SetStateAction<string>>) {
		this.language = language;
		this.name = name;
		this.setName = setName;
	}
}

function EditName({ language, name, setName }: EditNameProps) {
	return (
		<>
			{
				name.length > 0 ?
					<EditionLabel paddingTop={0} language={language} textId="name" />
					:
					<EditionLabel paddingTop={0} language={language} textId="name" color="red" />
			}
			&nbsp;
			<HcInput width='500px' value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => { setName(e.target.value); }} />
		</>
	)
}


function SourceModifier({ language, hcContext, sourceId, displayValidationButtonsOnTop, onModified, lastPath }: SourceModifierProps) {

	const navigate = useNavigate()
	const [modificationDone, setModificationDone] = useState(false)
	const [canBeValidated, setCanBeValidated] = useState(false)
	const [processingModifications, setProcessingModifications] = useState(false)
	const [pageFound, setPageFound] = useState(false)
	const [showCanCancelModifictionsModal, setShowCanCancelModifictionsModal] = useState(false)
	const [sourceEditionGetOld, setSourceEditionGetOld] = useState<SourceEditionProps>({
		name: "", icon: "", description: "", sites: [], isHidden: false
	})
	const [name, setName] = useState<string>("")
	const [icon, setIcon] = useState<string>("")
	const [description, setDescription] = useState<string>("")
	const [sites, setSites] = useState<SiteOfASourceProps[]>([])
	const [sitesStr, setSitesStr] = useState<string>("")
	const [isHidden, setIsHidden] = useState<boolean>(false)
	const sourceEdition = new SourceEdition({ name, icon, description, sites, isHidden },
		setName, setIcon, setDescription, setSites, setSitesStr, setIsHidden);
	const [showImageAdditionModal, setShowImageAdditionModal] = React.useState(false)
	const [showCanDeleteModifictionsModal, setShowCanDeleteModifictionsModal] = useState(false)
	const sizeForValidatioButtons = (30 + 15) * 2 + 45

	useEffect(() => {
		if (pageFound) {
			if (sourceId === "" ||
				JSON.stringify(sourceEditionGetOld) !== JSON.stringify(sourceEdition.value)) {
				setModificationDone(true)
				setCanBeValidated(
					sourceEdition.value.name.length > 0 &&
					sourceEdition.value.icon.length > 0)
			} else {
				setModificationDone(false)
				setCanBeValidated(false)
			}
		}
	}, [name, icon, description, sitesStr, isHidden]);

	useEffect(() => {
		if (sourceId != "") {
			fetch(hcContext.backendUrl + "/source_edit_json?l=" + language + "&id=" + sourceId)
				.then(res => res.json())
				.then(jsonData => {
					if (jsonData.errorMessage) {
						setPageFound(false)
						hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
					} else {
						if (sourceId !== "") {
							if (jsonData.sites === "")
								jsonData.sites = []
							jsonData.isHidden = jsonData.isHidden === "true"
							setSourceEditionGetOld(jsonData)
							sourceEdition.set(jsonData)
							hcContext.setDocumentTitle(jsonData.name + " - Edition")
						}
						else {
							hcContext.setDocumentTitle("Ajout d'une source")
						}
						setPageFound(true)
					}
				})
				.catch(rejected => {
					setPageFound(false)
					hcContext.setInfo(createErrorModalContent(language, rejected.message))
				});
		} else {
			setPageFound(true)
		}
	}, [])

	function sendSourceModification() {
		if (!hcContext.userToken)
			return
		setProcessingModifications(true)
		hcContext.setBlackCoverOfContent(true)
		const postContent = new SourceModificationPostContentType(language, hcContext.userToken,
			sourceId, sourceEdition.value)
		var postContentStr = JSON.stringify(postContent)
		fetch(hcContext.backendUrl + "/modify_source", {
			method: 'POST',
			body: postContentStr,
			headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
		})
			.then(res => res.json())
			.then(jsonData => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				if (jsonData.errorMessage)
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
				else
					onModified(sourceEdition.value.name, jsonData.id)
			})
			.catch(rejected => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	function removeSource() {
		if (!hcContext.userToken)
			return
		setProcessingModifications(true)
		hcContext.setBlackCoverOfContent(true)
		const postContent = new SourceRemovalPostContentType(language, hcContext.userToken, sourceId)
		fetch(hcContext.backendUrl + "/remove_source", {
			method: 'POST',
			body: JSON.stringify(postContent),
			headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
		})
			.then(res => res.json())
			.then(jsonData => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				if (jsonData.errorMessage) {
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
				} else {
					navigate('/' + language + '/' + GetStrLocalized(language, "sourcesFolderName"));
				}
			})
			.catch(rejected => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	function ModifyButton() {
		return (
			<>
				{
					canBeValidated &&
					!processingModifications &&
					<button onClick={sendSourceModification}>
						<GetHtmlStrLocalized language={language} textId="modify" />
					</button>
				}
			</>
		)
	}

	return (
		<>
			{pageFound ?
				<>
					{
						processingModifications &&
						<Centered>
							<div style={getWaitPopupStyle(true, 50)}>
								<img src={image_wait} alt="wait" height="100%" width="100%" />
							</div>
						</Centered>
					}
					{
						displayValidationButtonsOnTop ?
							<table width='100%'>
								<tbody>
									<tr>
										<td width={sizeForValidatioButtons} style={{ paddingTop: 20 }}>
											<ModifyButton />
										</td>
										<td>
											<div style={{ marginTop: '30px' }} ></div>
											<Centered><EditName language={language} name={sourceEdition.value.name} setName={sourceEdition.setName} /></Centered>
										</td>
										{
											(hcContext.user?.asRightsToModifyTheContent && !processingModifications) ?
												<td width={sizeForValidatioButtons} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
													{
														(modificationDone || sourceId === "") ?
															<>
																{
																	canBeValidated ?
																		<>
																			<Button btnData={{ type: ButtonType.YES, onClick: () => sendSourceModification() }} language={language} altId="modify" />
																			<HorizontalSpace />
																		</>
																		:
																		<>
																			<HorizontalSpace width={30} />
																			<HorizontalSpace />
																		</>
																}
															</>
															:
															<>
																<Button btnData={{ type: ButtonType.DELETE, onClick: () => setShowCanDeleteModifictionsModal(true) }} language={language} />
																<HorizontalSpace />
															</>
													}
													{
														lastPath !== undefined &&
														<Button btnData={{ type: ButtonType.NO, onClick: () => modificationDone ? setShowCanCancelModifictionsModal(true) : navigate(lastPath) }} language={language} altId="cancel" />
													}
													<HorizontalSpace />
												</td>
												:
												<td width={sizeForValidatioButtons} className="hc-not-very-short-screen"></td>
										}
									</tr>
								</tbody>
							</table>
							:
							<div style={{ marginLeft: 20, marginRight: 20 }}>
								<EditName language={language} name={sourceEdition.value.name} setName={sourceEdition.setName} />
							</div>
					}
					<br />
					<br />
					<br />


					<div style={{ marginLeft: 20, marginRight: 20 }}>

						<table style={{ width: "100%" }}>
							<tbody>
								<tr>
									<td style={{ width: "50%", paddingRight: 20 }}>
										<table>
											<tbody>
												<tr>
													<td>
														{
															sourceEdition.value.icon.length > 0 ?
																<EditionLabel paddingTop={0} language={language} textId="image" />
																:
																<EditionLabel paddingTop={0} language={language} textId="image" color="red" />
														}
													</td>
													<td style={{ textAlign: 'center', width: '100%' }}></td>
													<td style={{ paddingRight: '15px' }}>
														{
															sourceEdition.value.icon.length === 0 ?
																<Button btnData={{
																	type: ButtonType.ADD, onClick: () => {
																		setShowImageAdditionModal(true)
																	}
																}} language={language} />
																:
																<Button btnData={{
																	type: ButtonType.EDIT, onClick: () => {
																		setShowImageAdditionModal(true)
																	}
																}} language={language} />
														}
													</td>
												</tr>
											</tbody>
										</table>

										&nbsp; &nbsp; &nbsp;
										{
											sourceEdition.value.icon.length > 0 &&
											<BigButtonWithTextAtBottom link="" image={imgToFirebaseUrl2("sources/" + sourceEdition.value.icon)} duration="" title={sourceEdition.value.icon} tags="" beginOfClassName="hc-big-button-source" hcContext={hcContext} />
										}
										<br />
										<br />



									</td>
									<td style={{ width: "50%", paddingLeft: 20 }} />
								</tr>
							</tbody>
						</table>

						<EditionLabel language={language} textId="description" />
						<br />
						<textarea className='form-control' style={{ width: "100%", height: "250px" }} value={sourceEdition.value.description} onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => { sourceEdition.setDescription(event.target.value); }} />
						<br />
						<br />
						<br />

						<SitesListViewer language={language} sites={sourceEdition.value.sites} setSites={(s) => sourceEdition.setSitesFn(s)} isDarkTheme={hcContext.isDarkTheme} />
						<br />
						<br />

						<div style={{ display: 'flex', alignItems: 'flex-end' }}>
							<EditionLabel language={language} textId="isHidden" />
							&nbsp; &nbsp; &nbsp;
							<input type="checkbox" checked={sourceEdition.value.isHidden} onChange={(event: ChangeEvent<HTMLInputElement>) => {
								sourceEdition.setIsHidden(event.target.checked)
							}
							} />
							<br />
						</div>
					</div>
				</>
				:
				<>
					<br />
					<br />
					<div className='hc-content-text' style={{ textAlign: 'center', margin: '0 auto' }}>
						<GetHtmlStrLocalized language={language} textId="sorryThisPageIsNotAvailable" />
					</div>
				</>
			}

			<br />
			<br />
			<ModifyButton />

			<YesNoModal language={language} show={showCanCancelModifictionsModal} setShow={setShowCanCancelModifictionsModal}
			    isDarkTheme={hcContext.isDarkTheme}
				title={GetStrLocalized(language, "cancellationConfirmation")}
				text={GetStrLocalized(language, "areYouSureToUndotheChanges")}
				onYes={() => navigate(lastPath !== undefined ? lastPath : "")} />

			<YesNoModal language={language} show={showCanDeleteModifictionsModal} setShow={setShowCanDeleteModifictionsModal}
			    isDarkTheme={hcContext.isDarkTheme}
				title={GetStrLocalized(language, "removalConfirmation")}
				text={GetStrLocalized(language, "areYouSureToPermanentlyDeletethisSource")}
				onYes={() => removeSource()} />

			<SelectSourceImageModal show={showImageAdditionModal} setShow={setShowImageAdditionModal}
				language={language}
				onImageSelected={(s: string) => {
					sourceEdition.setIcon(s)
				}} hcContext={hcContext} />
		</>
	)
}

export default SourceModifier
