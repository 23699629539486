import GetStrLocalized from "../datas/GetStrLocalized";


export interface ModalContentProps {
    title: string;
    content: string;
}

export function createErrorModalContent(language: string, errorMessage: string) {
    return {title: GetStrLocalized(language, "error"), content: errorMessage}
}


export function emptyModalContent() {
    return {title: "", content: ""}
}