import GetHtmlStrLocalized from "../../datas/GetHtmlStrLocalized"
import H2Title from "./H2Title"

interface H1TitleIdProps {
    language: string;
    titleId: string;
}


const H2TitleId: React.FC<H1TitleIdProps> = ({ language, titleId }) => {

    return <H2Title><GetHtmlStrLocalized language={language} textId={titleId} /></H2Title>
}

export default H2TitleId