
export enum ButtonType {
    ADD= "add",
    BOLD= "bold",
    ITALIC= "italic",
    LEFT = "left",
    TOP = "up",
    DOWN = "down",
    RIGHT = "right",
    EDIT = "edit",
    EMOJI = "emoji",
    GIF = "gif",
    HTML= "html",
    DELETE = "delete",
    TEST = "test",
    SEE="see",
    EXPORT="export",
    YES="yes",
    NO="no",
    MOVE="move",
    OK="ok",
    SEARCH="search",
    UNDERLINE="underline",
    UPDATE="update",
    EMPTY="empty"
  }

export interface ButtonDataProps {
    type: ButtonType;
    url?: string;
    onClick: () => any;
}