import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import InputWithStyle from "./InputWithStyle";


interface SearchInputProps {
	searchValue: string;
	searchPath: string;
	placeholder: string;
	isDarkTheme: boolean;
	autoFocus?: boolean;
}

function SearchInput({ searchValue, searchPath, placeholder, isDarkTheme, autoFocus = false }: SearchInputProps) {

	const navigate = useNavigate();
	const [editionValue, setEditionValue] = useState("");

	function goOnSearchPageIfInputNotEmpty() {
		if (editionValue !== "") {
			var urlizedValue = editionValue.replaceAll("'", " ").replaceAll("#", "%23")
			navigate(searchPath + urlizedValue)
		}
	}

	useEffect(() => {
		setEditionValue(searchValue)
	}, [searchValue]);


	return (
		<table className="hc-top-bar-center-css" style={{ width: "100.0%" }}>
			<tbody>
				<tr>
					<td style={{ width: "20.0%" }}></td>
					<td style={{ width: "60.0%" }}>
						<InputWithStyle onValidation={goOnSearchPageIfInputNotEmpty} value={editionValue}
							placeholder={placeholder}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setEditionValue(event.target.value)
							}} isDarkTheme={isDarkTheme} hasSearchButton={true} autoFocus={autoFocus} />
					</td>
					<td style={{ width: "20.0%" }}></td>
				</tr>
			</tbody>
		</table>
	)

}

export default SearchInput
