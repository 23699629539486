
export interface SiteOfASourceProps {
  url: string;
  title: string;
}

export interface SourceEditionProps {
  name: string;
  icon: string;
  description: string;
  sites: SiteOfASourceProps[];
  isHidden: boolean;
}

export class SourceEdition {
  value: SourceEditionProps;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setIcon: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  private setSites: React.Dispatch<React.SetStateAction<SiteOfASourceProps[]>>;
  private setSitesStr: React.Dispatch<React.SetStateAction<string>>;
  setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;

  constructor(
    value: SourceEditionProps,
    setName: React.Dispatch<React.SetStateAction<string>>,
    setIcon: React.Dispatch<React.SetStateAction<string>>,
    setDescription: React.Dispatch<React.SetStateAction<string>>,
    setSites: React.Dispatch<React.SetStateAction<SiteOfASourceProps[]>>,
    setSitesStr: React.Dispatch<React.SetStateAction<string>>,
    setIsHidden: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    this.value = value
    this.setName = setName
    this.setIcon = setIcon
    this.setDescription = setDescription
    this.setSites = setSites
    this.setSitesStr = setSitesStr
    this.setIsHidden = setIsHidden
  }

  set(value: SourceEditionProps) {
    this.setName(value.name)
    this.setIcon(value.icon)
    this.setDescription(value.description)
    this.setSitesFn([...value.sites])
    this.setIsHidden(value.isHidden)
  }

  setSitesFn(sites: SiteOfASourceProps[]) {
    this.setSites(sites);
    this.setSitesStr(JSON.stringify(sites))
  }
}
