import React from 'react'
import { useState } from 'react'
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal'
import Select from 'react-select';
import GetStrLocalized from '../../datas/GetStrLocalized';
import { HcContext, SelectEltProps } from '../../types/hccontext';


interface TypeOfItemModalProps {
    language: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    hcContext: HcContext;
    onTypeOfItem: (s: string) => any;
}


function TypeOfItemModal({ language, show, setShow, hcContext, onTypeOfItem }: TypeOfItemModalProps) {
    const typesOfItem = [{ value: "article", label: "message" }, { value: "category", label: "folder" },
    { value: "chatbot", label: "chatbot" }, { value: "questionForTheQuiz", label: "questionForTheQuiz" }]
    const [itemSelected, setItemSelected] = useState<SelectEltProps | null>(typesOfItem[0]);

    return (
        <Modal
            language={language}
            show={show}
            setShow={setShow}
            isDarkTheme={hcContext.isDarkTheme}
        >
            <ModalHeader>
                <h3><GetHtmlStrLocalized language={language} textId="addAnResource" /></h3>
            </ModalHeader>
            <ModalBody>

                <Select
                    value={itemSelected}
                    onChange={(elt: SelectEltProps | null) => {
                        setItemSelected(elt);
                    }}
                    getOptionValue={(elt: SelectEltProps) => elt.value}
                    getOptionLabel={(elt: SelectEltProps) => GetStrLocalized(language, elt.label)}
                    options={typesOfItem}
                    styles={hcContext.getStylesForSelect<SelectEltProps>()}
                />
                <br />

            </ModalBody>
            <br />
            <ModalFooter>
                <button onClick={() => {
                    if (itemSelected !== null) {
                        onTypeOfItem(itemSelected!!.value)
                    }
                    setShow(false)
                }}>
                    <GetHtmlStrLocalized language={language} textId="validate" />
                </button>
            </ModalFooter>
        </Modal>
    );
}

export default TypeOfItemModal;