import * as React from "react";
import EditionLabel from "../title/EditionLabel";
import Button from "../button/Button";
import { ButtonType } from "../../props/buttondataprops";
import GetStrLocalized from "../../datas/GetStrLocalized";
import TextEditionModal from "../modal/TextEditionModal";
import BubbleFromUser from "../chatbot/BubbleFromUser";



interface TriggersListViewerProps {
  language: string;
  triggers: string[];
  setTriggers: (r: string[]) => any;
  isDarkTheme: boolean;
}

export default function TriggersListViewer(
  { language, triggers, setTriggers, isDarkTheme }: TriggersListViewerProps
) {
  const [selectedElt, setSelectedElt] = React.useState<number | undefined>()
  const [showModal, setShowModal] = React.useState(false)
  const [addOrEditReference, setAddOrEditReference] = React.useState<boolean>(true)
  const [triggerEdition, setTriggerEdition] = React.useState<string>("")

  function swapElts(firstIndex: number, secondIndex: number) {
    var refCopied = triggers;
    const temp = refCopied[firstIndex];
    refCopied[firstIndex] = refCopied[secondIndex];
    refCopied[secondIndex] = temp;
    setTriggers(refCopied)
  }

  return (
    <>
      <table>
        <tbody>
          <tr>
            <td><EditionLabel language={"fr"} textId="triggers" /></td>
            <td style={{ textAlign: 'center', width: '100%' }}></td>
            {
              selectedElt !== undefined &&
              <>
                {
                  triggers.length > 1 &&
                  <>
                    <td style={{ paddingRight: '15px' }}><Button btnData={{
                      type: ButtonType.TOP, onClick: () => {
                        if (selectedElt > 0) {
                          swapElts(selectedElt - 1, selectedElt)
                          setSelectedElt(selectedElt - 1)
                        }
                      }
                    }} language={language} alt={GetStrLocalized(language, "move_up")} /></td>
                    <td style={{ paddingRight: '15px' }}><Button btnData={{
                      type: ButtonType.DOWN, onClick: () => {
                        if (selectedElt < triggers.length - 1) {
                          swapElts(selectedElt, selectedElt + 1)
                          setSelectedElt(selectedElt + 1)
                        }
                      }
                    }} language={language} alt={GetStrLocalized(language, "move_down")} /></td>
                  </>
                }
                <td style={{ paddingRight: '15px' }}><Button btnData={{
                  type: ButtonType.EDIT, onClick: () => {
                    setAddOrEditReference(false)
                    setTriggerEdition(triggers[selectedElt])
                    setShowModal(true)
                  }
                }} language={language} /></td>
                <td style={{ paddingRight: '15px' }}><Button btnData={{
                  type: ButtonType.DELETE, onClick: () => {
                    var refCopied = triggers;
                    refCopied.splice(selectedElt, 1)
                    setTriggers(refCopied)
                    setSelectedElt(undefined)
                  }
                }} language={language} /></td>
              </>
            }
            <td style={{ paddingRight: '15px' }}><Button btnData={{
              type: ButtonType.ADD, onClick: () => {
                setAddOrEditReference(true)
                setTriggerEdition("")
                setShowModal(true)
              }
            }} language={language} /></td>
          </tr>
        </tbody>
      </table>

      {
        triggers.length > 0 &&
        <BubbleFromUser>
          <div style={{ listStyleType: "none", cursor: 'pointer' }}>
            {triggers.map((item, index) => {
              return <div key={"trigger-list-elt-" + index} onClick={() => setSelectedElt(index)} >
                {
                  selectedElt == index ?
                    <b>{item}</b>
                    :
                    item
                }
              </div>
            })}
          </div>
        </BubbleFromUser>
      }

      <TextEditionModal
        language={language}
        titleId={addOrEditReference ? "addATrigger" : "modifyATest"}
        show={showModal}
        setShow={setShowModal}
        isDarkTheme={isDarkTheme}
        text={triggerEdition}
        onTextValidated={(elt) => {
          if (addOrEditReference) {
            setTriggers([...triggers, elt])
            setSelectedElt(undefined)
          }
          else {
            var cpTriggers = triggers
            cpTriggers[selectedElt!!] = elt
            setTriggers(cpTriggers)
          }
        }}
      />
    </>

  );
}