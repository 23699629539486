import image_wait from '../../assets/resources/custom/icons/wait.gif'
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import Button from '../button/Button'
import { ButtonType } from '../../props/buttondataprops'
import HorizontalSpace from '../util/HorizontalSpace'
import { HcContext } from '../../types/hccontext'
import Centered from '../util/Centered'
import { getWaitPopupStyle } from '../util/GetWaitPopupStyle'
import H1TitleBreadcrumb from '../title/H1TitleBreadcrumb'
import { ArticleTestProps, ArticleTests, ArticleTestsProps } from '../../types/ArticleTests'
import TestsListViewer from '../listview/TestsListViewer'
import { createErrorModalContent } from '../../props/ModalContentProps'


interface TestArticleModifierProps {
	language: string;
	hcContext: HcContext;
	title: string;
	articleId: string;
	lastPath: string;
};

class TestsModificationPostContentType {
	language: string;
	userKey: string;
	id: string;
	tests: ArticleTestProps[];

	constructor(
		language: string,
		userKey: string,
		id: string,
		tests: ArticleTestProps[]) {
		this.language = language
		this.userKey = userKey
		this.id = id
		this.tests = tests
	}
}


function TestArticleModifier({ language, hcContext, title, articleId, lastPath }: TestArticleModifierProps) {

	const navigate = useNavigate()
	const [modificationDone, setModificationDone] = useState(false)
	const [processingModifications, setProcessingModifications] = useState(false)
	const [pageFound, setPageFound] = useState(true)

	const [tests, setTests] = useState<ArticleTestProps[]>([])
	const [testsStr, setTestsStr] = useState("")

	const [articleTestsOld, setArticleTestsOld] = useState<ArticleTestsProps>({ tests: [] })
	const articleTests = useMemo(() => new ArticleTests({ tests }, setTests, setTestsStr), [tests]);

	useEffect(() => {
		setModificationDone(JSON.stringify(articleTestsOld) !== JSON.stringify(articleTests.value))
	}, [testsStr, articleTestsOld, articleTests.value]);

	const [fetched, setFetched] = useState(false)
	useEffect(() => {
		if (!fetched) {
			setFetched(true)
			fetch(hcContext.backendUrl + "/article_tests_json?l=" + language + "&id=" + articleId)
				.then(res => res.json())
				.then(jsonData => {
					if (jsonData.errorMessage) {
						setPageFound(false)
						hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
						return
					}
					if (articleId !== "") {
						if (jsonData.tests === "")
							jsonData.tests = []
						setArticleTestsOld(jsonData)
						articleTests.set(jsonData)
						hcContext.setDocumentTitle("Tests")
					}
					else {
						hcContext.setDocumentTitle("Tests d'un article")
					}
					setPageFound(true)
				})
				.catch(rejected => {
					setPageFound(false)
					hcContext.setInfo(createErrorModalContent(language, rejected.message))
				});
		}
	}, [fetched, hcContext, language, articleId, articleTests]);

	function sendTestsModification() {
		if (!hcContext.userToken)
			return
		setProcessingModifications(true)
		hcContext.setBlackCoverOfContent(true)
		const postContent = new TestsModificationPostContentType(language, hcContext.userToken, articleId, tests)
		var postContentStr = JSON.stringify(postContent)
		fetch(hcContext.backendUrl + "/modify_tests_of_an_article", {
			method: 'POST',
			body: postContentStr,
			headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
		})
			.then(res => res.json())
			.then(jsonData => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				if (jsonData.errorMessage) {
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
					return
				}
				navigate('/' + language + '/' + articleId);
			})
			.catch(rejected => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	return (
		<>
			{pageFound ?
				<>
					{
						processingModifications &&
						<Centered>
							<div style={getWaitPopupStyle(true, 50)}>
								<img src={image_wait} alt="wait" height="100%" width="100%" />
							</div>
						</Centered>
					}
					<table width='100%'>
						<tbody>
							<tr>
								<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }}>
									{
										modificationDone &&
										!processingModifications &&
										<button onClick={sendTestsModification}>
											<GetHtmlStrLocalized language={language} textId="modify" />
										</button>
									}
								</td>
								<td>
									<H1TitleBreadcrumb language={language} parentFolders={[]}>
										{title}
									</H1TitleBreadcrumb>
								</td>
								{
									(hcContext.userAsRightsToModifyTheContent && !processingModifications) ?
										<td width={(30 + 15) * 2 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
											{
												modificationDone ?
													<>
														<Button btnData={{ type: ButtonType.YES, onClick: sendTestsModification }} language={language} altId="modify" />
														<HorizontalSpace />
													</>
													:
													<>
														<HorizontalSpace width={30} />
														<HorizontalSpace />
													</>
											}
											<Button btnData={{ type: ButtonType.NO, onClick: () => navigate(lastPath) }} language={language} altId="cancel" />
											<HorizontalSpace />
										</td>
										:
										<td width={(30 + 15) * 3 + 45} className="hc-not-very-short-screen"></td>
								}
							</tr>
						</tbody>
					</table>
					<br />

					<TestsListViewer language={language} tests={articleTests.value.tests} setTests={(t) => { articleTests.setTestsFn(t) }}
						articleId={articleId} hcContext={hcContext} maxNbTestsToDisplay={1000} setProcessingModifications={setProcessingModifications} />

				</>
				:
				<>
					<br />
					<br />
					<div className='hc-content-text' style={{ textAlign: 'center', margin: '0 auto' }}>
						<GetHtmlStrLocalized language={language} textId="sorryThisPageIsNotAvailable" />
					</div>
				</>
			}
		</>
	)
}

export default TestArticleModifier
