import React from 'react'
import { Link } from "react-router-dom"
import { onErrorImageHandler } from '../util/imagefallbacks';
import Stars from '../star/Stars';
import { StarsStats } from '../star/starsUtil';
import { HcContext } from '../../types/hccontext';

interface BigButtonWithTextAtBottomProps {
	link: string;
	image: string;
	duration: string;
	nb?: string | undefined;
	title: string;
	tags: string;
	descriptionImage?: string;
	description?: string[];
	beginOfClassName: string;
	stars?: StarsStats | undefined;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
	hcContext: HcContext;
	imageBorderRadius?: number;
}

const BigButtonWithTextAtBottom: React.FC<BigButtonWithTextAtBottomProps> = ({ link, image, duration, nb = undefined, title, tags, descriptionImage = "", description = [], beginOfClassName, stars = undefined, onClick, hcContext, imageBorderRadius = 10 }) => {

	if (image === "") {
		image = descriptionImage;
	}
	if (image === "") {
		image = "/buttons/folder.webp";
	}
	var rootClassName = 'hc-inline-flex hc-small-margin'
	if (link !== "")
		rootClassName += ' hc-move-up-animation'

	function bigButtonImgLoad(onLoadResult: any) {
		const imgloaded = onLoadResult.target as HTMLImageElement;
		if (imgloaded.width == 120 && imgloaded.height == 90 && imgloaded.src.startsWith("https://img.youtube.com/vi/"))
			console.error("Youtube thumbnail not found: " + imgloaded.src)
	}

	const mainImageStyle: React.CSSProperties = { objectFit: 'cover', borderRadius: imageBorderRadius, borderWidth: 0 }
	const durationStyle: React.CSSProperties = { position: 'absolute', backgroundColor: 'black', padding: 3, right: 8 }
	const titleStyle: React.CSSProperties = { textAlign: 'left', color: hcContext.getTextColor(), paddingTop: '10px' }

	return (
		<>
			<div className={rootClassName} style={{ position: 'relative' }}>
				<span className={beginOfClassName + "-width"} style={{ textAlign: 'left' }}>
					{
						link !== "" ?
							<Link to={link} style={{ color: 'white', textDecoration: 'none', outline: 'none' }} onClick={onClick}>
								<img onLoad={bigButtonImgLoad} className={beginOfClassName + "-width " + beginOfClassName + "-height hc-not-selectable"} style={mainImageStyle} src={image}
									onError={(e) => onErrorImageHandler(e, image)} />
								{duration !== undefined && duration !== "" && <span className={beginOfClassName + "-durationtop"} style={durationStyle}>{duration}</span>}
								<div style={titleStyle}><b className='hc-button-title-style'>
									<span style={{ color: 'red' }}>{nb}</span> {title}<br /></b>
								</div>
							</Link>
							:
							<span style={{ color: 'white', textDecoration: 'none', outline: 'none', cursor: 'pointer' }} onClick={onClick}>
								<img className={beginOfClassName + "-width " + beginOfClassName + "-height hc-not-selectable"} style={mainImageStyle} src={image}
									onError={(e) => onErrorImageHandler(e, image)} />
								{duration !== undefined && duration !== "" && <span className={beginOfClassName + "-durationtop"} style={durationStyle}>{duration}</span>}
								<div style={titleStyle}><b className='hc-button-title-style'>
									<span style={{ color: 'red' }}>{nb}</span> {title}</b>
								</div>
							</span>
					}
					{
						(tags !== "" || (stars !== undefined && stars.numberOfVotes > 0)) &&
						<div style={{ textAlign: 'left', color: hcContext.getSubTextColor(), paddingTop: '10px' }}>
							{tags}
							{
								stars !== undefined &&
								stars.numberOfVotes > 0 &&
								<span style={{ marginLeft: '10px' }} >
									<Stars starsStats={stars} size="small" />
								</span>
							}
							<br />
						</div>

					}
					{
						(description.length > 0 || (descriptionImage !== undefined && descriptionImage !== "")) &&
						<div style={{ textAlign: 'left', color: hcContext.getSubTextColor(), paddingTop: '10px' }}>
							{descriptionImage !== undefined && descriptionImage !== "" && <img src={descriptionImage} alt='Source logo' width="23" height="23" style={{ marginRight: 13, borderRadius: 100 }} onError={(e) => onErrorImageHandler(e, descriptionImage)} />}
							{
								description.map((desc, descIndex) => {
									return (<span key={"desc-" + descIndex} style={{ lineHeight: '25px', verticalAlign: 'top' }}>{descIndex > 0 && <br />}{desc}</span>)
								})
							}
						</div>
					}
				</span>
			</div>
		</>
	)
}

export default BigButtonWithTextAtBottom