

export interface SourceMessageCharacteristicsProps {
	nbOfMessages: number;
	nbOfTextualAnswers: number;
	nbOfBibleReferences: number;
	nbOfCccReferences: number;
	nbOfExternalWebsiteReferences: number;
}

export interface SourceCharacteristicsProps {
	publicMessages: SourceMessageCharacteristicsProps;
	hiddenMessages: SourceMessageCharacteristicsProps;
}

export const emptySourceMessageCharacteristics:SourceMessageCharacteristicsProps = { nbOfMessages: 0, nbOfTextualAnswers: 0, nbOfBibleReferences: 0, nbOfCccReferences: 0, nbOfExternalWebsiteReferences: 0 }
export const emptySourceCharacteristics:SourceCharacteristicsProps = { publicMessages: emptySourceMessageCharacteristics, hiddenMessages: emptySourceMessageCharacteristics }

export function getNbOfArticles(sourceMessageCharacteristics: SourceMessageCharacteristicsProps): number {
	return Number(sourceMessageCharacteristics.nbOfMessages) + Number(sourceMessageCharacteristics.nbOfTextualAnswers);
}

export function isSourceMessageCharacteristicsEmpty(sourceMessageCharacteristics: SourceMessageCharacteristicsProps): boolean {
	return Number(sourceMessageCharacteristics.nbOfMessages) === 0 &&
	Number(sourceMessageCharacteristics.nbOfTextualAnswers) === 0 &&
	Number(sourceMessageCharacteristics.nbOfBibleReferences) === 0 &&
	Number(sourceMessageCharacteristics.nbOfCccReferences) === 0 &&
	Number(sourceMessageCharacteristics.nbOfExternalWebsiteReferences) === 0;
}

function getNbOfReferencesFromSourceMessageCharacteristics(sourceMessageCharacteristics: SourceMessageCharacteristicsProps): number {
	return Number(sourceMessageCharacteristics.nbOfBibleReferences) + Number(sourceMessageCharacteristics.nbOfCccReferences) + Number(sourceMessageCharacteristics.nbOfExternalWebsiteReferences);
}

export function getNbOfReferences(sourceCharacteristics: SourceCharacteristicsProps): number {
	return getNbOfReferencesFromSourceMessageCharacteristics(sourceCharacteristics.publicMessages) +
		getNbOfReferencesFromSourceMessageCharacteristics(sourceCharacteristics.hiddenMessages);
}