import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import BigButtonWithTextAtBottom from '../components/bigButton/BigButtonWithTextAtBottom';
import ScrollToTop from '../components/util/ScrollToTop';
import GetStrLocalized from '../datas/GetStrLocalized';
import ChatbotContentWidget from '../components/chatbot/ChatbotContentWidget';
import { SuggestionProps } from '../props/suggestionprops';
import Centered from '../components/util/Centered';
import { HcContext, SizeOfWindow } from '../types/hccontext';
import { ChatbotContext } from '../types/chatbotcontext';
import InputWithStyle from '../components/banner/InputWithStyle';



interface ChatbotProps {
    language: string;
    hcContext: HcContext;
    chatbotContext: ChatbotContext;
    sessionId: string;
}

function Chatbot({ language, hcContext, chatbotContext, sessionId }: ChatbotProps) {

    const location = useLocation();
    const [lastPath, setLastPath] = useState("")
    const [chatbotId, setChatbotId] = useState("")
    const [writtenText, setWrittenText] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [menuSuggestions, setMenuSuggestions] = useState<SuggestionProps[]>([])
    const videoWidthClassName = "hc-width-chat-inside-bubble"

    useEffect(() => {
        hcContext.setDocumentTitle(GetStrLocalized(language, "christianChatbot") + " - Holy Chatter");
    }, [hcContext, language]);



    function onTextValidated() {
        if (inputValue != "")
            onNewText(inputValue);
    }

    function onNewText(newtext: string) {
        if (newtext === "")
            return;

        setWrittenText(newtext)
        setInputValue("")
    }


    if (location.pathname !== lastPath) {
        setLastPath(location.pathname);

        var chatbotIdFromUrl = "";
        const foldersArray = location.pathname.split('/');
        if (foldersArray.length > 3) {
            chatbotIdFromUrl = foldersArray[3];
        }
        setChatbotId(chatbotIdFromUrl);
    }

    return (
        <>
            <ScrollToTop />
            <div className="hc-chat-left-container hc-long-screen" style={{ marginTop: '80px' }}>

                {
                    menuSuggestions.map((menuSuggestion, menuSuggestionsIndex) => (
                        <Centered key={`menu-suggestion-${menuSuggestionsIndex}`}>
                            <BigButtonWithTextAtBottom link={chatbotId} image={menuSuggestion.image} duration={menuSuggestion.duration} title={menuSuggestion.title} tags={menuSuggestion.subTitle} descriptionImage={menuSuggestion.sourceImage} description={[menuSuggestion.sourceName]} beginOfClassName="hc-big-button-chat" onClick={() => onNewText(menuSuggestion.message)} hcContext={hcContext} />
                        </Centered>
                    ))
                }

            </div>
            <div className="hc-chat-right-container hc-heart-background">
                <ChatbotContentWidget language={language} hcContext={hcContext} sessionId={sessionId} chatbotContext={chatbotContext} writtenText={writtenText} setWrittenText={setWrittenText}
                    setMenuSuggestions={setMenuSuggestions} chatbotId={chatbotId} videoWidthClassName={videoWidthClassName} debugMode={hcContext.userAsRightsToModifyTheContent} />
            </div>
            <div className="hc-right-footer">

                {
                    hcContext.sizeOfWindow === SizeOfWindow.VERY_SHORT &&
                    <>
                        <div style={{ paddingTop: "7px" }}>
                            <Link to={"/" + language}><img src="/logo_holy_chatter.png" style={{ height: "37px", width: "37px", marginLeft: "13px", marginRight: "5px" }} className="hc-short-screen hc-object-fit-contain" alt="Holy Chatter" /></Link>
                        </div>
                    </>
                }

                <div style={{ position: 'absolute', top: '0px', left: hcContext.sizeOfWindow === SizeOfWindow.WIDE ? '0px' : '65px', right: "0px", bottom: '0px' }}>
                    <InputWithStyle onValidation={onTextValidated} value={inputValue}
                        placeholder={GetStrLocalized(language, "writeASentence")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setInputValue(event.target.value)
                        }} isDarkTheme={hcContext.isDarkTheme} height={'50px'} hasSearchButton={true}   />
                </div>
            </div>
        </>
    )
}

export default Chatbot